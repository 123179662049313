import { useState, useEffect } from 'react';

import StrikeTeamTransfersModel from '../../models/StrikeTeamTransfersModel';
import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import aggregateActivities from '../../models/SummariesOfActivites';

export function useTeamRecentActivities(teamId, memberClientId, displayPermissions, dateRange) {    
    const [recentActivies, setRecentActivities] = useState(null);
    const [recentSummary, setRecentSummary] = useState(null);
    let fetchTeamTaps = false; 
    let fetchMemberTaps = true;
    let fetchTransfers = false;

    useEffect(() => {
        const onUpdateRecentActivities = async (activities) => {
            const summary = activities && activities.length > 0 ? aggregateActivities(activities) : null;
            setRecentSummary(summary);
            setRecentActivities(activities);
        };

        const model = new StrikeTeamDetailsModel({
            onUpdateActivities: onUpdateRecentActivities,
            memberClientId,
            teamId,
            fetchTeamTaps, 
            fetchMemberTaps,
            fetchTransfers, 
            limitedToMember: memberClientId,
            dateRange,
            permissions: displayPermissions,
        });
        
        if (dateRange) {
            model.start();
        } else {
            onUpdateRecentActivities(null);
        }

        return function cleanup() {
            model.stop();
            setRecentActivities(null);
        };

    },
        [dateRange, displayPermissions]
    );

    return { activites: recentActivies, summary: recentSummary };
}
