import React, { Component } from 'react';
import { Typography, PageHeader, Tag, Upload , Button, Modal, Space, Divider } from 'antd';
import qs from 'qs';

import StrikeClientModel from '../../../models/StrikeClientModel';
import { ProfileImage, VerifiedClientTag } from '../../../components/client/AccountProfileComponents';
import { EditClientProfileForm, AdminCheckBoxFormItems } from '../../../components/client/EditClientProfileForm';
import { getCategoryNameWithCompany } from '../../../components/client/ClientCategoryFormItem';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { defaultCurrencyByCountry } from '../../../utils/CurrencyUtils';
import 'antd/dist/antd.css';
import '../../../App.scss';
import { ClientSpecificPricingView } from '../../../components/admin/ClientSpecificPricingView';
import { ClientImageEditor } from '../../../components/client/UploadableImageEditor';

const { Paragraph, Text } = Typography;

// /client/manage/profile
export default class EditClientProfileScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    const parsed = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const mode = (parsed.mode) ? 'editing' : 'viewing';

    this.model = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.isAdmin = isAdmin;

    this.state = {
      user: null,
      client: null,
      clientId: clientId,
      percent: 0,
      loading: true,
      mode,
    };
  }

  componentDidMount() {
    this.model.waitForClientData();
  }

  componentWillUnmount() {
    if (this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  foundClient = async (client) => {
    this.setState(
      (state) => {
        return {
          client,
          loading: false,
        }
      }
    );
  }

  changeMode = (mode) => {
    if (mode === 'viewing') {
      return 'editing'
    } else if (mode === 'editing') {
      return 'saving'
    } else {
      return 'viewing';
    }
  }

  onClickEdit = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );
  }

  doneSaving = () => {
    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode)
      }
    }
    );

    this.model.waitForClientData();
  }

  internalSave = async (data) => {
    await this.model.commit(data);
    this.doneSaving();
  }


  onClickSave = (data) => {
    // console.log(`onClickSave`, data);

    this.setState((state) => {
      return {
        mode: this.changeMode(state.mode),
      }
    }
    );

    this.internalSave(data);
  }

  getButtons = () => {
    if (this.state.mode === 'viewing') {
      return [
        <Button key="1" type="primary" onClick={this.onClickEdit}>
          Edit
        </Button>,
      ]
    } else if (this.state.mode === 'editing') {
      return [];
    } else {
      return [];
    }
  }

  formattedDob = () => {
    return this.state.client.dob ? this.state.client.dob.format("DD/MM/YYYY") : ""
  };

  formattedAgreedTermsAt = () => {
    return this.state.client.agreedTermsAt ? this.state.client.agreedTermsAt.format("DD/MM/YYYY") : ""
  };

  formattedMarketingOptInAt = () => {
    return this.state.client.marketingOptInAt ? this.state.client.marketingOptInAt.format("DD/MM/YYYY") : ""
  };

  render() {
    if (this.state.mode === 'viewing' && !this.state.loading && this.state.client) {
      let agreeTermsString = (this.state.client.agreeTerms) ? "Agreed Terms" : "Need to agree terms";
      agreeTermsString = (this.state.client.agreedTermsAt) ? agreeTermsString + ` on ${this.formattedAgreedTermsAt()}` : agreeTermsString;

      let marketingOptInString = (this.state.client.marketingOptIn) ? "Allowed Strikepay to send important communications" : "Strikepay may not send other communications";
      marketingOptInString = (this.state.client.marketingOptIn && this.state.client.marketingOptInAt) ? marketingOptInString + ` on ${this.formattedMarketingOptInAt()}` : marketingOptInString;

      const businessOrIndividual = (this.state.client.isCompany) ? "Business" : null;
      const defaultCurrency = defaultCurrencyByCountry[this.state.client.country] || 'EUR';

      return (
        <div style={{ height: '100%', textAlign: 'center' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
          <CenteredSpinner loading={this.isLoading()} noLayout={false} />
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <ProfileImage
              condition={() => { return !this.isLoading() && this.state.client }}
              locStrings={this.props.locStrings}
              client={this.state.client}
            />
            <VerifiedClientTag client={this.state.client} style={{ fontSize: '15px' }} />
            <Text>
              {getCategoryNameWithCompany({ categoryCode: this.state.client.categoryCode, isCompany: this.state.client.isCompany })}
            </Text>
            {businessOrIndividual && <Text type='success'>{businessOrIndividual}</Text>}
          </Space>
          <Divider />
          <Paragraph>{this.state.client.email && ("Email: ")}{this.state.client.email || ""}</Paragraph>
          <Paragraph>{this.state.client.phoneNumber && ("Phone: ")}{this.state.client.phoneNumber || ""}</Paragraph>
          <Paragraph>{this.state.client.dob && (this.state.client.isCompany ? "Founded: " : "Date of Birth: ")}{this.formattedDob()}</Paragraph>
          <Paragraph>{this.state.client.employerName && ("Work: ")}{this.state.client.employerName || ""}</Paragraph>
          <Paragraph>{agreeTermsString}</Paragraph>
          <Paragraph>{marketingOptInString}</Paragraph>
          <AdminCheckBoxFormItems isAdmin={this.isAdmin} title="Admin Only Settings" isViewOnly={true} client={this.state.client} />
          { this.isAdmin &&
            <ClientSpecificPricingView pricing={this.state.client.clientSpecificPricing} currency={defaultCurrency} />
          }
        </div>
      );
    } else if (!this.state.loading) {
      const { imageUrl } = this.state;
      const avatarUrl = imageUrl ? imageUrl : this.state.client.avatar;

      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />

          <ClientImageEditor
            clientId={this.state.clientId}
            client={this.state.client}
            currentUrl={avatarUrl}
          />

          <EditClientProfileForm
            client={this.state.client}
            callback={this.onClickSave}
            isAdmin={this.isAdmin}
          />
        </div>
      );
    } else {
      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Your Profile`}
            tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ? <Tag color="blue">Editing</Tag> : <Tag color="green">Saving...</Tag>)}
            onBack={(() => window.history.back())}
            extra={this.getButtons()}
          />
        </div>
      );
    }
  }
}

