import firebase from 'firebase/app';

export default class FirebaseSetup {

  static instance() {
    return new FirebaseSetup();
  }

  constructor(props) {
    this.anonymousUser = null;
    this.anonymousUserId = null;
  }

  getDB = () => {
    return firebase.firestore();
  }

  getFirebase = () => {
    return firebase;
  }

  getFunctions = () => {
    return firebase.functions();
  }

  getLoginProviders = () => {
    return {
      google: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      email: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    }
  }

  getUserDataFromUser = (user) => {
    if (user) {
      const {
        uid,
        email,
        displayName,
        phoneNumber,
        emailVerified,
        photoURL,
        disabled,
        isAnonymous,
        deleted,
      } = user;

      return {
        uid,
        userId: uid, // TODO: remove the use of userId as this is from the Auth.user object and is always `uid`
        email,
        displayName,
        phoneNumber,
        emailVerified,
        photoURL,
        deleted: (!!deleted || false),
        disabled: (!!disabled || false),
        isAnonymous: !!isAnonymous,
      };
    } else {
      return null;
    }
  }

  getCurrentUserDataInApp = () => {
    const userData = this.getUserDataFromUser(firebase.auth().currentUser);

    if (userData) {
      return userData;
    } else {
      return {};
    }
  }

  signInWithCredential = async (cred) => {
    return await firebase.auth().signInWithCredential(cred); // fires `firebase.auth().onAuthStateChanged()`
  }

  linkWithCredential = async (cred) => {
    return await firebase.auth().linkWithCredential(cred); // fires `firebase.auth().onAuthStateChanged()`
  }

  anonymousSignIn = async () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser == null) {
      // console.log(`anonymousSignIn ...`);
      await firebase.auth().signInAnonymously();
      this.onSuccessfulAnonymousSignIn();
      // console.log(`anonymousSignIn done!`);
    }
  }

  onSuccessfulAnonymousSignIn = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser != null && !!currentUser.isAnonymous) {
      // console.log(`onSuccessfulAnonymousSignIn ...`);
      this.anonymousUser = currentUser;
      this.anonymousUserId = currentUser.uid;
    }
  }

  clearAnonymousSignInInfo = () => {
    this.anonymousUser = null;
    this.anonymousUserId = null;
  }

  // on successful up, return new user object or null.
  firestoreUsersUpdateWithUser = async (user, params) => {
    const userData = this.getUserDataFromUser(user);

    if (userData) {
      // const db = firebase.firestore();
      const db = this.getDB();
      const { uid, isAnonymous } = userData;

      await db.collection('users').doc(uid).set({ ...userData, ...params }, { merge: true });

      return userData;

    } else {
      return null;
    }
  }

}

export const firebaseSetup = FirebaseSetup.instance();
