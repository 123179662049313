import React, { useState } from 'react';
import { Input, Button, Form, Table, Popconfirm, Space, Tag } from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import MoneyTitle from '../customer/MoneyTitle';
import StrikeTeamModel from '../../models/StrikeTeamModel';

const limits = {
  min: 1,
  max: 1000,
  countMin: 2,
  countMax: 5,
};

const TeamEditorValues = ({ teamId, team, defaultTag, onLoading }) => {
  const { values: teamValues, percents, defaultCurrency, rgbColor } = team || {};
  const { updatedValuesAt, updatedPercentsAt } = team || {};

  const [form] = Form.useForm();
  const [values, setValues] = useState(teamValues || []);
  const [newValue, setNewValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const model = new StrikeTeamModel({ teamId });
  const tagColor = '#555';
  
  // Sort values in ascending order
  const sortedValues = [...values].sort((a, b) => a - b);
  
  const handleSave = async ({ values }) => {
    setLoading(true);
    onLoading && onLoading({ message: `Saving values ...` });
    const sortedValues = values.sort((a, b) => a - b);
    const savedValues = sortedValues.length > 0 ? sortedValues : null;
    await model.saveTeamValues({ values: savedValues });
    onLoading && onLoading({ message: null });
    setLoading(false);
    setIsChange(false);
  };

  const handleClear = () => {
    const { values } = defaultTag || {};
    setValues(values || []);
    setIsChange(true);
  }

  const handleAddValue = (amount) => {
    setValues([...values, amount * 100]); // Store as cents
    setIsChange(true);
  };

  const handleDeleteValue = (amount) => {
    setValues(values.filter((v) => v !== amount));
    setIsChange(true);
  };

  const handleFinish = (formData) => {
    const { amount } = formData;
    if (amount) {
      handleAddValue(parseFloat(amount));
    }
    form.resetFields();
  };

  const isAddDisabled = () => {
    const newValueCent = parseFloat(newValue) * 100;
    const isInValues = values.includes(newValueCent);
    return values.length >= limits.countMax || newValue < limits.min || newValue > limits.max || isInValues;
  }

  const onChangeNewValue = (v) => {
    setNewValue(v);
  }

  const columns = [
    {
      title: `Pre-set Amounts (min: ${limits.countMin}, max: ${limits.countMax})`,
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) =>
        record.type === 'value' ?
          <div className='row'>
            <div className='col d-flex justify-content-end align-items-center'>
              <Button color={tagColor} style={{ minWidth: '100px', fontWeight: 'bolder', fontSize: '16px' }}>
                <div style={{ height: '24px', textAlign: 'center' }}>
                  <MoneyTitle amount={record.amount} currency={defaultCurrency} digits={0} />
                </div>
              </Button>
            </div>
            <div className='col d-flex justify-content-start align-items-center'>
              <Popconfirm
                title={`Are you sure you want to delete?`}
                onConfirm={() =>
                  handleDeleteValue(record.amount)
                }
              >
                <Button type="danger" icon={<MinusCircleOutlined />} >Delete</Button>
              </Popconfirm>
            </div>
            <div className='col-auto'>
            </div>
          </div>
          :
            <div className='row'>
              <div className='col d-flex justify-content-center align-items-center'>
                <Form layout="inline" form={form} onFinish={handleFinish}>
                  <div className='col d-flex justify-content-end align-items-center'>
                    <Form.Item name="amount">
                      <Input
                        type="number" placeholder="Add amount" 
                        min={limits.min} step={1.00} max={limits.max} 
                        onChange={ e => onChangeNewValue(e.target.value) }
                        style={{ minWidth: '120px' }}
                      />
                    </Form.Item>
                  </div>
                  <div className='col d-flex justify-content-start align-items-center mx-0'>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={isAddDisabled()} icon={<PlusCircleOutlined />}>Add</Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
      ,
    },
  ];

  const data = [
    ...sortedValues.map((v) => ({ key: `value-${v}`, type: 'value', amount: v })),
    { key: 'value-add-new', type: 'add', amount: 0 },
  ];

  const isSaveDisabled = () => {
    if (!isChange) {
      return true;
    }
    if (values.length === 0) {
      return false;
    }
    return values.length < limits.countMin || values.length > limits.countMax;
  }

  return (
    <div className="container mt-4">
      <h3>Set up Pre-set Amounts</h3>

      {percents.length > 0 && (
        <div className="mt-4 text-danger">
          Percentages overide values. Please clear all percentages to use values.
        </div>
      )}

      <Table
        className="mt-4"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />

      <Button
        type="primary"
        onClick={() => handleSave({ values })}
        style={{ marginTop: '12px', marginLeft: '-24px' }}
        loading={loading}
        disabled={isSaveDisabled()}
      >
        {'Save Changes'}
      </Button>

      <Button
        onClick={() => handleClear()}
        style={{ marginTop: '12px', marginLeft: '16px' }}
        loading={loading}
        icon={<UndoOutlined />}
      >
        {'Use Defaults'}
      </Button>

      {updatedValuesAt && (
        <div className="mt-0">
          <small>Last updated: {updatedValuesAt.toDate().toLocaleString()}</small>
        </div>
      )}
      { updatedPercentsAt && percents.length > 0 && (
        <div className="mt-0">
          {percents.length > 0 && <small>Percentages overide values.</small>}
          {percents.length > 0 && <small> </small>}
          <small>Last updated: {updatedPercentsAt.toDate().toLocaleString()}</small>
        </div>
      )}

    </div>
  );
};

export default TeamEditorValues;
