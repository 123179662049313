import path from 'path';

import React, { Component } from 'react';
import { message, PageHeader } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import {
    DollarOutlined,
    EuroOutlined,
    EuroCircleOutlined,
    PoundOutlined,
} from '@ant-design/icons';

import Spinner from '../../../components/Spinner';
import StrikeTeamDetailsModel from '../../../models/StrikeTeamDetailsModel';
import { EditTeamForm } from '../../../components/client/EditTeamForm';
import { TeamImageEditor } from '../../../components/client/UploadableImageEditor';

import '../../../App.scss';
import 'antd/dist/antd.css';

export default class TeamSettingsScreen extends Component {
    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;
        const isAdmin = !!props.isAdmin;

        this.isAdmin = isAdmin;

        this.baseURLs = props.baseURLs || {
            teamDetails: `/client/manage/${clientId}/teams/${teamId}`
        };

        this.newSettingsURL = `/admin/clients/${clientId}/teams/${teamId}/newsettings`;
        this.newSettingsFrom = `/admin/clients/${clientId}/teams/${teamId}`;

        this.model = new StrikeTeamDetailsModel({viewModel: this, teamId});

        this.state = {
            teamId: teamId,
            teamDetails: null,
            loading: true,
            route: null,
            imageUrl: null,
        };
    }

    internalSave = async (data) => {
        try {
            await this.model.updateExistingTeam(data);
            message.success('Changes saved successfully');
        } catch (error) {
            message.error('Changes could not be saved' + JSON.stringify(error));
        }

    }

    onClickSave = (data) => {
        this.internalSave(data);
    }

    componentDidMount() {
        this.model.waitForTeamDetails({ fetchTeamClient: false });
    }

    componentWillUnmount() {
        if (this.model) this.model.stop();
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    setTeamDetailsOnPage = (teamDetails, teamMembersList) => {
        let filteredTeamMember = teamMembersList.filter(member => member.clientId === this.state.memberClientId);
        this.setState({
            teamDetails,
            teamMembersList,
            teamMemberLoggedIn: filteredTeamMember[0],
            isLoggedInMemberAdmin: filteredTeamMember[0] ? (filteredTeamMember[0].roles[0] === 'admin' || filteredTeamMember[0].permissions.isTeamAdmin) : [],
            loading: false
        });
    }

    renderRedirect = () => {
        const {route} = this.state;
        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;
        if (route && clientId && teamId && this.baseURLs.manage) {
            const toRoute = `${this.baseURLs.manage}/${clientId}/teams/${teamId}/${route}`;
            return <Redirect push to={toRoute}/>
        } else if (route && clientId && teamId && this.baseURLs.teamDetails) {
            const toRoute = `${this.baseURLs.teamDetails}/${route}`;
            return <Redirect push to={toRoute}/>
        } else {
            return null;
        }
    }

    getCurrencyIcon = () => {
        if (this.state.teamDetails.defaultCurrency) {
            switch (this.state.teamDetails.defaultCurrency) {
                case 'EUR':
                    return <EuroOutlined style={{fontSize: '17px', marginTop: '2px'}}/>;
                case 'USD':
                    return (<DollarOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
                case 'GBP':
                    return (<PoundOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
                default:
                    return <EuroOutlined style={{fontSize: '17px', marginTop: '2px'}}/>;
            }
        } else {
            return (<EuroCircleOutlined style={{fontSize: '17px', marginTop: '2px'}}/>);
        }
    }

    getImageDisplayed = () => {
        if (this.state.imageUrl) {
            return this.state.imageUrl;
        } else if (this.state.teamDetails && this.state.teamDetails.topLogo) {
            return this.state.teamDetails.topLogo;
        } else {
            return null;
        }
    }

    render() {
        const redirected = this.renderRedirect();
        if (redirected) {
            return redirected;
        }

        if (!this.state.loading && this.state.teamDetails) {

            return (
                <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`${this.state.teamDetails.teamName}`}
                        onBack={(() => window.history.back())}
                    />

                    { this.isAdmin &&
                        <div className='container border'  style={{textAlign: 'center', marginBottom: '20px', width: '50%', maxWidth: '768px', minWidth: '400px'}}>
                            <div className='container-title'>
                                <h4>Admin Only</h4>
                            </div>
                            <Link
                                to={this.newSettingsURL}
                                from={this.newSettingsFrom}
                            >
                                <button className="btn btn-primary">New Settings</button>
                            </Link>
                            <div style={{marginTop: '10px'}} />
                        </div>
                    }

                    <TeamImageEditor
                        teamId={this.state.teamId}
                        currentUrl={this.state.teamDetails.topLogo}
                        isLoggedInMemberAdmin={this.state.isLoggedInMemberAdmin}
                        onUpdateImage={(imageUrl) => this.setState({imageUrl})}
                    />

                    <EditTeamForm
                        teamId={this.state.teamId}
                        team={this.state.teamDetails}
                        callback={this.onClickSave}
                        edit={true}
                        teamMemberLoggedIn={this.state.teamMemberLoggedIn}
                        isLoggedInMemberAdmin={this.state.isLoggedInMemberAdmin}
                    />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', width: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Details`}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}

