import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DatePicker, Spin, Space, Typography, Card, Button } from 'antd';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

export const DownloadDataButton = ({ model, items, exportButtonText }) => {
  const [loading, setLoading] = useState(false);
  const [requestedItems, setRequestedItems] = useState(false)

  const filename = model.getExportFilename();
  const headers = model.getFieldHeaders();

  const csvInstance = useRef();
  useEffect(() => {
    if (requestedItems && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setRequestedItems(false);
      });
    }
  }, [requestedItems]);

  async function onClickDownload() {
    const contentItems = [ ...items ]; // copy content items
    console.log('onClickDownload contentItems', contentItems);
    const processedItems = await model.processTransactions(contentItems);
    console.log('onClickDownload processedItems', processedItems);
    setRequestedItems(processedItems);
  }

  return (
    <>
        <Button
          onClick={async () => {
            setLoading(true)
            onClickDownload()
            setLoading(false)
          }}
            disabled={loading}>{loading ? <Spin indicator={SpinnerIcon} /> : (exportButtonText || 'Export')}
        </Button>
      {requestedItems ?
        <CSVLink
          data={requestedItems}
          headers={headers}
          filename={filename}
          ref={csvInstance}
        />
      : undefined}
    </>
  );
};


export const DownloadDataReport = ({ model, client, clientId, updatedDateRange, exportButtonText, subText, ...rest }) => {
  const [datesSelected, setDatesSelected] = useState(false)
  const [requestedItems, setRequestedItems] = useState(false)
  const [recentItems, setRecentItems] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const csvInstance = useRef();
  useEffect(() => {
    if (requestedItems && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setRequestedItems(false);
      });
    }
  }, [requestedItems]);

  async function selectDate(dates) {
    const startDateRaw = new Date(dates[0])
    const endDateRaw = new Date(dates[1])
    const startOfStartDate =  moment(startDateRaw).startOf('day').toDate()
    const endOfEndDate = moment(endDateRaw).endOf('day').toDate()
    setStartDate(startOfStartDate)
    setEndDate(endOfEndDate)
    updatedDateRange && updatedDateRange({ startDate: startOfStartDate, endDate: endOfEndDate });
    setDatesSelected(true)
  }

  function getCSVHeaders () {
    return model.getFieldHeaders();
  }

  function getFileName () {
    return model.getExportFilename();
  }

  const filename = getFileName()
  const headers = getCSVHeaders()

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  async function onClickExportIncome() {
    const contentItems = await model.fetchAllWithDates({clientId, startDate, endDate})
    setRequestedItems(contentItems);
    setRecentItems([ ...contentItems ]);
  }

  return (
    <Card align='center' style={{ marginTop: 10 }}>
      <Paragraph>Select Date Range</Paragraph>
      <Space direction='vertical'>
        <RangePicker
          defaultValue={moment()}
          format={"DD/MM/YYYY"}
          onChange={selectDate}
          disabledDate={disabledDate}
          size='small'
        />
        <Button
          onClick={async () => {
            setLoading(true)
            onClickExportIncome()
            setLoading(false)
          }}
            disabled={!datesSelected}>{loading ? <Spin indicator={SpinnerIcon} /> : (exportButtonText || 'Export')}
        </Button>
        <Text type={subText ? 'danger' : 'warning'}>{subText || 'Export as transactions'}</Text>
      {requestedItems ?
        <CSVLink
          data={requestedItems}
          headers={headers}
          filename={filename}
          ref={csvInstance}
        />
      : undefined}
      </Space>
    </Card>
  );
};
