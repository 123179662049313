import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader } from 'antd';


// /admin/balances
export default class AdminSiteScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    const { isAdmin } = props;
    const siteId = this.props.match.params.siteId;

    this.isAdmin = isAdmin;
    this.siteId = siteId;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.clientId = null;
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return false;
  }

  render() {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Admin Manage Site`}
            onBack={(() => window.history.back())}
          />

          {`Site Admin ${this.siteId}`}

        </div>
      );
  }
}