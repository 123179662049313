import path from 'path';

import React, { useState } from 'react';
import { Button, message, Modal, Upload, Space } from 'antd';
import {
    LoadingOutlined,
    PlusOutlined,
    EditOutlined,
} from '@ant-design/icons';
import ReactCrop from 'react-image-crop';
import firebase from 'firebase';

import getBase64 from '../../utils/GetBase64';
import 'react-image-crop/dist/ReactCrop.css';
import Spinner from '../Spinner';

function getTeamCollection() {
    const db = firebase.firestore();
    const ref = db.collection('teams');
    return ref;
}

function getClientCollection() {
    const db = firebase.firestore();
    const ref = db.collection('clients');
    return ref;
}

function getUserCollection() {
    const db = firebase.firestore();
    const ref = db.collection('users');
    return ref;
}

function getTemplateCollection() {
    const db = firebase.firestore();
    const ref = db
        .collection('issuers').doc('strike')
        .collection('template_tags');
    return ref;
}

function getTagCollection() {
    const db = firebase.firestore();
    const ref = db.collection('tags');
    return ref;
}

function getSiteCollection() {
    const db = firebase.firestore();
    const ref = db.collection('sites');
    return ref;
}


const saveImageToStorage = async ({ id, fieldName, imageName, base64Image, fileExtn }) => {
    if (!base64Image) {
        console.log('No image to save', id, fieldName, imageName, fileExtn);
        return null;
    }

    const storageRef = firebase.storage().ref();
    const uniqueId = id || `d-${Date.now()}`;
    const imagePrefix = imageName || 'image';
    const fieldPrefix = fieldName || 'default';
    const fileExtnDefault = fileExtn || '.png';
    let contentType = 'image/png';

    switch (fileExtnDefault) {
        case '.jpg':
        case '.jpeg':
            contentType = 'image/jpeg';
            break;
        case '.png':
            contentType = 'image/png';
            break;
        default:
            contentType = 'image/png';
            break;
    }

    const fileName = uniqueId + '-' + `${fieldPrefix}` + (fileExtnDefault);
    console.log('saveImageToStorage', id, fieldPrefix, imagePrefix, fileExtnDefault, fileName);

    const teamImagesRef = storageRef.child(`public-images/${imagePrefix}-images/${fileName}`);
    
    const resultSnapshot = await teamImagesRef.putString(base64Image, 'data_url', { contentType });
    const url = await resultSnapshot.ref.getDownloadURL();

    return url;
}

const saveUrlToId = async (ref, { id, fieldName, url }) => {
    console.log('saveUrlToId', id, fieldName, url);
    if (!id) {
        return;
    }
    await ref.doc(id).set({
        [fieldName]: url,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedImageAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });
}

const uploadImageAndSave = async (ref, { id, fieldName, imageName, base64Image, fileExtn }) => {
    const url = await saveImageToStorage({ id, fieldName, imageName, base64Image, fileExtn });
    if (id && ref && url) {
        await saveUrlToId(ref, { id, fieldName, url });
    }
    return url;
}


export const ClientImageEditor = (props) => {
    const { clientId, currentUrl, imageName: providedName } = props;
    const { client } = props;
    const { ownerId } = client;
    const fieldName = 'avatar';
    const ref = getClientCollection();
    const imageName = providedName || 'client';
    const id = clientId;

    const userRef = getUserCollection();
    const userUserImageName = 'user';

    // MARK: Make generic for each object type (can be in call back)
    const onSave = async (base64Image, fileExtn) => {
        await uploadImageAndSave(ref, { id, fieldName, imageName, base64Image, fileExtn });
        if (ownerId) {
            await uploadImageAndSave(userRef, { id: ownerId, fieldName, imageName: userUserImageName, base64Image, fileExtn });
        }
    }

    return <>
        <UploadImageEditor
            currentUrl={currentUrl}
            circle
            onSave={onSave}
            isLoggedInMemberAdmin={true}
        />
    </>;

}

export const TeamImageEditor = (props) => {
    const { teamId, currentUrl, isLoggedInMemberAdmin, imageName: providedName } = props;
    const { onUpdateImage, onLoading } = props;
    const fieldName = 'topLogo';
    const ref = getTeamCollection();
    const imageName = providedName || 'team';
    const id = teamId;

    // MARK: Make generic for each object type (can be in call back)
    const onSave = async (base64Image, fileExtn) => {
        onLoading && onLoading({ message: 'Saving new image...' });
        const url = await uploadImageAndSave(ref, { id, fieldName, imageName, base64Image, fileExtn });
        if (onUpdateImage) {
            onUpdateImage(url);
        }
        onLoading && onLoading({ message: null });
    }

    return <>
        <UploadImageEditor
            currentUrl={currentUrl}
            square
            onSave={onSave}
            isLoggedInMemberAdmin={isLoggedInMemberAdmin}
        />
    </>;
}

export const TemplateTagImageEditor = (props) => {
    const { templateId, currentUrl, isLoggedInMemberAdmin, imageName: providedName } = props;
    const { onUpdateImage } = props;
    const fieldName = 'actionText.signupImage';
    const ref = getTemplateCollection();
    const imageName = providedName || 'template';
    const id = templateId;

    // MARK: Make generic for each object type (can be in call back)
    const onSave = async (base64Image, fileExtn) => {
        const url = await saveImageToStorage({ id, fieldName, imageName, base64Image, fileExtn });
        if (onUpdateImage) {
            onUpdateImage(url);
        }
    }

    return <>
        <UploadImageEditor
            currentUrl={currentUrl}
            rectangle
            onSave={onSave}
            isLoggedInMemberAdmin={isLoggedInMemberAdmin}
        />
    </>;
}

export const TagImageEditor = (props) => {
    const { tagId, currentUrl, imageName: providedName } = props;
    const { onUpdateImage } = props;
    const fieldName = 'avatar';
    const ref = getTagCollection();
    const imageName = providedName || 'tag';
    const id = tagId;

    // MARK: Make generic for each object type (can be in call back)
    const onSave = async (base64Image, fileExtn) => {
        const url = await uploadImageAndSave(ref, { id, fieldName, imageName, base64Image, fileExtn });
        if (onUpdateImage) {
            onUpdateImage(url);
        }
    }

    return <>
        <UploadImageEditor
            currentUrl={currentUrl}
            circle
            onSave={onSave}
            isLoggedInMemberAdmin={true}
        />
    </>;
}

export const SiteImageEditor = (props) => {
    const { siteId, currentUrl, imageName: providedName } = props;
    const { onUpdateImage } = props;
    const {  rectangle, square } = props;
    const fieldName = 'imageUrl';
    const ref = getSiteCollection();
    const imageName = providedName || 'site';
    const id = siteId;

    // MARK: Make generic for each object type (can be in call back)
    const onSave = async (base64Image, fileExtn) => {
        const url = await uploadImageAndSave(ref, { id, fieldName, imageName, base64Image, fileExtn });
        if (onUpdateImage) {
            onUpdateImage(url);
        }
    }

    return <>
        <UploadImageEditor
            currentUrl={currentUrl}
            square={!!square}
            rectangle={!!rectangle}
            onSave={onSave}
            isLoggedInMemberAdmin={true}
        />
    </>;
}

const UploadImageEditor = (props) => {
    const { isLoggedInMemberAdmin } = props;
    const { currentUrl } = props;
    const { square, circle, rectangle } = props;
    const { onSave, onUpdate } = props;

    let radius = '100%'; // circle
    radius = circle ? '100%' : radius;
    radius = square ? '10%' : radius;
    radius = rectangle ? '5%' : radius;
    let aspect = rectangle ? (8 / 5) : (1 / 1);
    const h = (100 / aspect).toFixed(0);
    const height = `${h}%`;

    const cropDefault = {
        unit: '%',
        width: 30,
        aspect: aspect,
    };

    const imageStyle = {
        width: 'auto',
        height: height,
        borderRadius: radius,
        objectFit: 'cover',
    };

    // const imageStyle = { width: '104px', height: '104px', borderRadius: '100%' };

    const [isDragging, setIsDragging] = useState(false);
    const [base64Image, setBase64Image] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [imageRef, setImageRef] = useState(null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [crop, setCrop] = useState(cropDefault);
    const [fileName, setFileName] = useState(null);
    const [fileExtn, setFileExtn] = useState(null);
    const [src, setSrc] = useState(null);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true);

    const isCommitButtonDisabled = isDragging || loading || !imageRef;

    const internalSave = async () => {
        try {
            if (base64Image && onSave) {
                setLoading(true);
                await onSave(base64Image, fileExtn);
                setLoading(false);
                setIsUploadDisabled(true);
            }
            message.success('Changes saved successfully');
        } catch (error) {
            message.error('Changes could not be saved' + JSON.stringify(error));
            setLoading(false);
        }
    }

    const onClickSave = () => {
        internalSave();
    }

    const saveImageLocally = async (file) => {
        getBase64(file, base64Image => {
            setBase64Image(base64Image);
            setVisible(false);
            setIsUploadDisabled(false); 
        });
    }

    const handleOk = async () => {
        await saveImageLocally(croppedImage);
        // setVisible(false);
        // setIsUploadDisabled(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImg(
                imageRef,
                crop,
            );
            setCroppedImage(croppedImage);
        }
    }

    const onImageLoaded = image => {
        setImageRef(image);
    };

    const onUploadingStarted = async () => {
        setUploading(true);
        if (onUpdate) {
            onUpdate({ uploading: true });
        }
    };

    const onUploadingEnded = async () => {
        setUploading(false);
        if (onUpdate) {
            onUpdate({ uploading: false });
        }
    };

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";
        ctx.save();

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        // Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);

        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        );


        const extension = fileExtn || '.jpg';
        const format = extension === '.png' ? 'image/png' : 'image/jpeg';
        const timestamp = Date.now();
        const fileName = `${timestamp}.${extension}`;
        setFileName(fileName);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, format);
        });
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setUploading(true);
            return;
        }

        let fileExtn = path.extname(info.file.name).toLowerCase();
        setFileExtn(fileExtn);
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, base64Image => {
                setBase64Image(base64Image);
                setUploading(false);
            });
        }
    };

    const beforeUpload = (file) => {
        setVisible(true);
        setSrc(URL.createObjectURL(file));
        // return false to not have it trigger upload, we want the file but no the actual uploading
        return false;
    }

    const getImageDisplayed = () => {
        if (base64Image) {
            return base64Image
        }

        if (currentUrl) {
            return currentUrl;
        }

        return null;
    }

    const UploadButton = () => {
        return <div>
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Add Image</div>
            <Button icon={<EditOutlined />} style={{ borderRadius: '50%', marginTop: '-25px' }}/>
        </div>
    };

    const ModifyButton = ({ src }) => {
        return <div className='col m-0 p-0' style={{ ...imageStyle }}>
            <img
                src={getImageDisplayed()} alt="Image Uploaded"
                style={{ ...imageStyle }}
            />
            <Button icon={<EditOutlined />} style={{ borderRadius: '50%', marginTop: '-25px' }}/>
        </div>;
    }

    return (
        <>
            {true &&
                <div className='container mb-3'>
                    <div className='col text-center'>
                        <div className='row text-center justify-content-center'>
                            <Upload
                                name="topLogo"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={saveImageLocally}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                disabled={!isLoggedInMemberAdmin}
                            >
                                {getImageDisplayed() ?
                                    <ModifyButton />:
                                    <UploadButton />
                                }
                            </Upload>

                            <Modal
                                visible={visible}
                                title="Crop your image"
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" onClick={handleCancel}>
                                        Cancel
                                    </Button>,
                                    <Button key="submit" type="primary" disabled={isCommitButtonDisabled} onClick={handleOk}>
                                        Confirm
                                    </Button>,
                                ]}
                            >
                                <div className="cropper">
                                    {src && (
                                        <ReactCrop
                                            src={src}
                                            crop={crop}
                                            ruleOfThirds
                                            onImageLoaded={onImageLoaded}
                                            onComplete={onCropComplete}
                                            onChange={onCropChange}
                                            onDragStart={() => setIsDragging(true)}
                                            onDragEnd={() => setIsDragging(false)}
                                            circularCrop={circle}
                                        />
                                    )}
                                </div>

                            </Modal>
                        </div>


                        <div className='row text-center justify-content-center mt-5'>
                            <Button
                                type="primary"
                                onClick={onClickSave}
                                disabled={isUploadDisabled}
                                icon={loading ? <LoadingOutlined /> : <PlusOutlined />}
                            >
                                Use Image
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {false &&
                <div style={{ height: '100%', textAlign: 'center' }}>
                    <Spinner condition={() => { return loading }} />
                </div>
            }
        </>);
}


export default UploadImageEditor;
