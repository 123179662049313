import React, { Component } from 'react';
import '../../../App.scss';
import 'antd/dist/antd.css';
import { PageHeader } from 'antd';

import AdminAllSitesBody from '../../../components/admin/AdminAllSitesBody';

// /admin/balances
export default class AdminAllSitesScreen extends Component {

  // Properties used by this component:

  constructor(props) {
    super(props);
    const { isAdmin } = props;
    this.isAdmin = isAdmin;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.clientId = null;
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return false;
  }

  render() {
      return (
        <div style={{ height: '100%', textAlign: 'center'}}>
          <PageHeader
            ghost={false}
            title={`Sites Admin`}
            onBack={(() => window.history.back())}
          />

          <AdminAllSitesBody isAdmin={this.isAdmin} />

        </div>
      );
  }
}