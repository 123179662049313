import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useTagDetails(tagId) {
    const [tag, setTag] = useState(null);

    useEffect(() => {
        // Subscribe to the progress collection in Firestore
        if (!tagId) {
            setTag(null);
            return;
        }

        const db = firebase.firestore();
        const ref = db.collection('tags').doc(tagId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const data = snapshot.data();
            data.id = snapshot.id;
            setTag(data);
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe && unsubscribe();
        };
    },
        [tagId]
    );

    return tag;
}
