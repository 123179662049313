import React from 'react';
import { ExclamationCircleTwoTone } from '@ant-design/icons'; // Antd icon

const AnnouncementBanner = ({ headline, message, count, isAdmin, onClose }) => {
  return (
    <div className="container-fluid">
      <div
        className="alert d-flex align-items-center position-relative"
        role="alert"
        style={{
          backgroundColor: '#e3f2fd', // Lighter background
          border: '2px solid #ff009811', // Border color matching your theme
          borderRadius: '5px', // Optional: rounded corners
          padding: '15px 20px',
        }}
      >
        <ExclamationCircleTwoTone
          style={{ fontSize: '2rem', marginRight: '15px' }}
          twoToneColor="#ff0098" // Primary color
        />

        <div className="w-100">
          <h4 className="mb-1" style={{ fontWeight: 'bold' }}>{headline}</h4>
          <p className="mb-0">{message}</p>
        </div>

        {isAdmin && (
          <p
            className="mb-0 mt-2 text-right w-100 text-muted"
            style={{ fontSize: '0.8rem' }}
          >
            <span style={{ fontWeight: 'bold' }}>Seen {count} times</span>
          </p>
        )}

        {onClose && (
          <button
            type="button"
            className="btn-close position-absolute"
            aria-label="Close"
            onClick={onClose}
            style={{
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              fontSize: '1.2rem',
              color: '#ff0098',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
        )}
      </div>
    </div>
  );
};

export default AnnouncementBanner;
