import React, { useEffect, useState } from 'react';
import { Radio, Space, Tabs, List, Button, Tag, Modal, Typography,
  Badge, Avatar,
 } from 'antd';
import {
  EditFilled,
  EditOutlined,
  EnableOutlined,
  AppstoreAddOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  LinkOutlined
} from '@ant-design/icons';

import '../../App.scss';
import 'antd/dist/antd.css';
import { colorForBackgroundColor, getContrastWithRGBColor } from '../../utils/RGBColorToHex';
import ProductSwitcher, { capitalize } from '../../utils/ProductSwitcher';
import { useTagDetails } from '../../models/useTagDetails';
import { useSitesByType } from '../../models/useSites';
import { useTeamSitesByType } from '../../models/useTeamSites';
import ContrastColor from '../customer/ContrastColor';
import { useSessionStorage } from '../../models/useSessionStorage';

import { TeamTapBehaviourUpdate } from './TeamTapBehaviourControl';
import { TeamEditColorUpdate } from './ColorPickerControl';
import { TeamImageEditor } from './UploadableImageEditor';
import { TeamDefaultMemberSettingsUpdate } from './TeamMembershipSettings';
import { EditTeamNameSettingsUpdate } from './TeamNameSettings';
import { EditTeamReviewSettingsUpdate, EditTeamReviewForm } from './TeamReviewsSettings';
import TeamEditorValues from './TeamEditorValues';
import TeamEditorPercents from './TeamEditorPercents';
import { useTeamDetails } from './useTeamDetails';

const { TabPane } = Tabs;
const { Text } = Typography;

const ExternalLink = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="link-primary text-decoration-underline">
      {url}
    </a>
  );
}

const TabTeamSettings = ({ teamId, team, onLoading }) => {
  const { topLogo, rgbColor } = team || {};

  const handleLoading = ({ message }) => {
    onLoading && onLoading({ message });
  }

  return (
    <div>
      <div className='h3 mt-4'>Banner settings</div>
      <div className='container mt-4'>
        <TeamImageEditor
          teamId={teamId}
          currentUrl={topLogo}
          isLoggedInMemberAdmin={true}
          onUpdateImage={(imageUrl) => console.log('updated image', imageUrl)}
          onLoading={handleLoading}
        />
        <div className='text'>
          Click on image to change
        </div>
      </div>

      <div className='container mt-4'>
        <TeamEditColorUpdate
          teamId={teamId}
          team={team}
          rgbColor={rgbColor}
          onLoading={handleLoading}
        />
      </div>

      <div className='container mt-4'>
        <EditTeamNameSettingsUpdate
          teamId={teamId} team={team}
          onLoading={handleLoading}
        />
      </div>

    </div>
  ) 
}

const TabTeamSettingsImage = ({ teamId, team, onLoading }) => {
  const { topLogo, rgbColor } = team || {};

  const handleLoading = ({ message }) => {
    onLoading && onLoading({ message });
  }

  return (
    <div>
      <div className='h3 mt-4'>Banner image</div>
      <div className='container mt-4'>
        <TeamImageEditor
          teamId={teamId}
          currentUrl={topLogo}
          isLoggedInMemberAdmin={true}
          onUpdateImage={(imageUrl) => console.log('updated image', imageUrl)}
          onLoading={handleLoading}
        />
        <div className='text'>
          Click on image to edit
        </div>
      </div>

      <div className='h3 mt-4'>Banner color settings</div>
      <div className='container mt-4'>
        <TeamEditColorUpdate
          teamId={teamId}
          team={team}
          rgbColor={rgbColor}
          onLoading={handleLoading}
        />
      </div>
      <div className='text'>
          Click on a color to change
        </div>
    </div>
  ) 
}

const TabTeamSettingsColor = ({ teamId, team, onLoading }) => {
  const { topLogo, rgbColor } = team || {};

  const handleLoading = ({ message }) => {
    onLoading && onLoading({ message });
  }

  return (
    <div>
      <div className='h3 mt-4'>Banner color settings</div>
      <div className='container mt-4'>
        <TeamEditColorUpdate
          teamId={teamId}
          team={team}
          rgbColor={rgbColor}
          onLoading={handleLoading}
        />
      </div>
    </div>
  ) 
}

const TabTeamSettingsName = ({ teamId, team, onLoading }) => {
  const { topLogo, rgbColor } = team || {};

  const handleLoading = ({ message }) => {
    onLoading && onLoading({ message });
  }

  return (
    <div>
      <div className='h3 mt-4'>Team name settings</div>
      <div className='container mt-4'>
        <EditTeamNameSettingsUpdate
          teamId={teamId} team={team}
          onLoading={handleLoading}
        />
      </div>
    </div>
  ) 
}



const TabMemberSettings = ({ teamId, team, onLoading }) => {
  const { teamTapBehaviour, displayPermissions } = team || {};

  return (
    <div>
      <div className='row justify-content-center mt-4'>
        <div className='col-auto'>
          {team && teamTapBehaviour &&
            <TeamTapBehaviourUpdate
              teamId={teamId}
              team={team}
              teamTapBehaviour={teamTapBehaviour}
              displayPermissions={displayPermissions}
              onLoading={onLoading}
            />
          }
        </div>
      </div>

      <div className='container mt-4'>
        <TeamDefaultMemberSettingsUpdate
          teamId={teamId}
          team={team}
          onLoading={onLoading}
        />
      </div>
    </div>
  )
}

const AvatarWithCheckbox = ({ imageUrl, showCheckbox = true }) => {
  const BadgeIcon = () => {
    return <CheckCircleFilled style={{ backgroundColor: 'white', color: 'green', borderRadius: '50%' }} />
  };
  return (
    <Badge count={showCheckbox ? '✔︎' : null} status={showCheckbox ? 'success' : null} offset={[-36, 0]}>
      <Avatar src={imageUrl} size='large' shape='square'/>
    </Badge>
  );
};


const TeamSitesSelectionItem = ({ site, onLoading, onSelected }) => {
  const { brand, displayName, description, imageUrl, link, rgbColor } = site || {};
  const { url, button, title, status } = site || {}
  const { id, teamId: tid, siteId, isRecent } = site || {}
  const isActive = !!tid;

  const onClickEdit = () => {
    onSelected && onSelected(site);
  }

  const titleName = title || displayName || 'Custom Review Link';
  const linkUrl = url || link || null;
  const descriptionText = url ? null : description;
  const buttonLabel = button || null;
  const targetUrl = url;

  const bgItem = isRecent ? '#77777711' : null;

  const actions = [];

  if (isActive) {
    actions.push(<Button key={`li-btn-actv`} icon={isRecent ? <EditFilled /> : <EditOutlined />} onClick={() => onClickEdit()}>{`Edit`}</Button>);
  }

  if (!isActive) {
    actions.push(<Button key={`li-btn-actv`} icon={<AppstoreAddOutlined />} onClick={() => onClickEdit()}>{`Activate`}</Button>);
  }

  const RowFooterLineItem = ({ imageUrl, displayName }) => {
    return (
      <div className="d-flex align-items-center pt-1 px-0">
        <div className="me-2">
          <Avatar src={imageUrl} size={16} shape="square" />
        </div>
        <div className='pt-1 px-1'>
          <Text type="secondary" style={{ lineHeight: "24px", display: "inline-flex", alignItems: "center" }}>
            {displayName}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <List.Item
      key={`li-site-${id}`}
      actions={actions}
      style={{ backgroundColor: bgItem }}
    >
      <List.Item.Meta
        title={<div className='h3' style={{ marginTop: '-12px'}}>{titleName}</div>}
        description={
          <ul className='mx-1 align-items-center'>
            {descriptionText && <li>{descriptionText}</li>}
            {buttonLabel && <li className='px-2'>
              <Button
                style={{ backgroundColor: rgbColor, borderColor: rgbColor }}
                href={linkUrl}
              >
                <ContrastColor backgroundColor={rgbColor}>
                  {buttonLabel}
                </ContrastColor>
              </Button>
            </li>}
            {!url && link && <li><ExternalLink url={link} /></li>}
            {displayName && url && <li><RowFooterLineItem imageUrl={imageUrl} displayName={displayName}/></li>}
          </ul>
        }
        avatar={<AvatarWithCheckbox imageUrl={imageUrl} showCheckbox={isActive && url} />}
      />
    </List.Item>
  );
}

const TeamSitesSelection = ({ teamId, team, onLoading, type }) => {
  const filterByType = type || 'review';
  const checkForExistingBrand = filterByType === 'review' ? 'success' : null;
  const { reviewDetails } = team || {};
  const { success } = reviewDetails || {};
  const { url, title, button } = success || {};
  const [allSitesByType] = useSitesByType({ status: 'live', type: filterByType });
  const [teamSitesByType, createTeamSite, updateTeamSite] = useTeamSitesByType({ teamId, type: filterByType });
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedTeamSite, setSelectedTeamSite] = useState(null);
  const [recentTeamSite, setRecentTeamSite] = useState(null);
  const [isSuccessReviewCreated, setIsSuccessReviewCreated] = useState(false);
  let reviews = null;

  const migrateTeamReview = async (defaultSite) => {
    const { id: siteId, brand, type } = defaultSite || {};
    if (success && brand && siteId && type) {
      const successReviewDetails = {      
        brand,
        siteId,
        teamId,
        type,
        status: url ? 'live' : 'created',
        url: url || null,
        title: title || null,
        button: button || null,
      };
      setIsSuccessReviewCreated(true);
      onLoading({ message: `Migrating team review` });
      await createTeamSite(successReviewDetails);
      onLoading({});
    }
  }

  useEffect(() => {
    if (isSuccessReviewCreated) {
      return;
    }

    if (!checkForExistingBrand) {
      setIsSuccessReviewCreated(true);
      return;
    }

    if (team && !success) {
      setIsSuccessReviewCreated(true);
      return;
    }

    if (checkForExistingBrand && team && teamSitesByType && allSitesByType) {
      const teamSuccessReview = teamSitesByType.filter(({ brand }) => brand === checkForExistingBrand)[0];
      const defaultReviewSite = allSitesByType.filter(({ brand }) => brand === checkForExistingBrand)[0];

      if (defaultReviewSite && !teamSuccessReview) {
        migrateTeamReview(defaultReviewSite);
      }
    }
    
  }, [teamId, allSitesByType, teamSitesByType]);

  if (teamSitesByType && allSitesByType) {
    reviews = allSitesByType.map((site) => {
      const { id, brand } = site;
      let teamReview = teamSitesByType.filter(({ siteId }) => id === siteId)[0];
      const { teamId: tid } = teamReview || {};
      const { teamId: recentTid } = recentTeamSite || {};
      const isRecent = tid && recentTid === tid;
      return { ...site, ...teamReview, teamId: tid, isRecent, siteId: id }; 
    });
  }

  const onUpdated = async (teamSite) => {
    const { id, brand, url, title, button, rgbColor, campaign } = teamSite;
    const status = url ? 'live' : 'created';
    await updateTeamSite(id, { brand, url, title, button, status, rgbColor, teamId, campaign });
    setIsEditModal(false);
    setSelectedTeamSite(null);
    setRecentTeamSite({ ...teamSite, status });
  }

  const onSelected = async (site) => {
    const { teamId: tid, brand, url, button, title, rgbColor, siteId } = site || {};

    if (!site || !brand) {
      return;
    }

    if (tid) {
      setSelectedTeamSite({ ...site });
    } else {
      const status = 'created';
      const aNewTeamSite = { 
        brand,
        url: url || null,
        button: button || null,
        title: title || null,
        status,
        rgbColor,
        siteId,
        teamId,
      };
      const ref = await createTeamSite(aNewTeamSite);
      const id = ref.id;
      setSelectedTeamSite({ ...site, ...aNewTeamSite, id });
    }
    setIsEditModal(true);
  }

  const onClickModalCancel = () => {
    setIsEditModal(false);
    setSelectedTeamSite(null);
  }

  return (
    <>
      {reviews &&
        <List
          size="large"
          itemLayout="horizontal"
          pagination={{
            pageSize: 8,
          }}
          style={{ textAlign: 'left' }}
          dataSource={reviews}
          renderItem={site => (
            <TeamSitesSelectionItem
              key={`site-${site.id}`}
              site={site}
              onLoading={onLoading}
              onSelected={onSelected}
            />
          )}
        />
      }
      {
        <Modal
          title={(selectedTeamSite && selectedTeamSite.displayName) ? selectedTeamSite.displayName : 'Edit Review'}
          visible={isEditModal}
          onCancel={() => onClickModalCancel()}
          footer={null}
          bodyStyle={{ minHeight: '200px', minWidth: '280px' }}
          destroyOnClose={true}
        >
          <EditTeamReviewForm
            teamId={teamId}
            review={selectedTeamSite}
            onUpdated={onUpdated}
          />
        </Modal>
      }
    </>
  );

}

const TabTeamSettingsReviews = ({ teamId, team, onLoading }) => {
  return (
    <>
      <div className='h3 py-2'>List of Review Sites</div>
      <TeamSitesSelection
        teamId={teamId}
        team={team}
        onLoading={onLoading}
        type={'review'}
      />
    </>
  );
}

const TabTeamSettingsAffiliates = ({ teamId, team, onLoading }) => {
  return (
    <>
      <div className='h3 py-2'>List of Affiliate Sites</div>
      <TeamSitesSelection
        teamId={teamId}
        team={team}
        onLoading={onLoading}
        type={'affiliate'}
      />
    </>
  );
}

const TabTeamSettingsBookings = ({ teamId, team, onLoading }) => {
  return (
    <>
      <div className='h3 py-2'>List of Booking Sites</div>
      <TeamSitesSelection
        teamId={teamId}
        team={team}
        onLoading={onLoading}
        type={'book'}
      />
    </>
  );
}

const allTabs = [
  { id: 'settings', tab: 'Settings', component: TabMemberSettings, color: null, },
  { id: 'image', tab: 'Banner', component: TabTeamSettingsImage, color: null, },
  { id: 'name', tab: 'Name', component: TabTeamSettingsName, color: null, },
  { id: 'values', tab: 'Values', component: TeamEditorValues, color: null, },
  { id: 'percents', tab: 'Percentages', component: TeamEditorPercents, color: null, },
  { id: 'review', tab: 'Reviews', component: TabTeamSettingsReviews, color: null, },
  { id: 'shop', tab: 'Affilates', component: TabTeamSettingsAffiliates, color: null, },
  { id: 'book', tab: 'Booking Apps', component: TabTeamSettingsBookings, color: null, },
];

const TeamSettingsBody = ({ teamId, locStrings }) => {
  const sessionId = `team-settings-tab-${teamId}`;
  const team = useTeamDetails(teamId);
  const defaultTag = useTagDetails(team ? team.defaultTagId : null);
  const [fgColor, setFgColor] = useState('text-white');
  const [fgMutedColor, setFgMutedColor] = useState('lightgrey');
  const [title, setTitle] = useState('...');
  const [tabPosition, setTabPosition] = useState('top');
  const [message, setMessage] = useState(null);
  const [activeTab, setActiveTab] = useSessionStorage(sessionId, 'settings');

  const {
    teamName, teamId: id,
    topLogo,
    defaultTagId,
    values: teamValues,
    percents: teamPercents,
  } = team || {};

  // Use the Default Tag settings if the team settings are not set
  if (team && defaultTag) {
    const { values, percents } = defaultTag || {};    
    team.values = teamValues || values || [];
    team.percents = teamPercents || percents || [];
  }

  let { rgbColor } = team || {};
  rgbColor = rgbColor || '#FF0098';

  const { product } = defaultTag || {};

  useEffect(() => {
    const mode = getContrastWithRGBColor(rgbColor);
    const fgMutedColor = mode === 'light' ? 'grey' : 'lightgrey';
    setFgMutedColor(fgMutedColor);
    const textColor = colorForBackgroundColor(rgbColor);
    setFgColor(`text-${textColor}`);
    if (product) {
      const ps = new ProductSwitcher({ locStrings });
      const pm = ps.getProductMap(product);
      const lower = pm.lower;
      const title = `Leave a ${lower} for`
      setTitle(title);
    }
  }, [rgbColor, product]);

  const handleOnLoading = ({ message }) => {
    setMessage(message);
  }

  return (
    <div className="team-screen container" style={{ background: null }} >
      {team && defaultTag &&
        <>
          <div className='container rounded-top' style={{ background: rgbColor }}>
            <div className={fgColor ? fgColor : 'text-black'}>
            <div className="container pt-5 pb-0">
                {topLogo &&
                  <img src={topLogo} className='rounded-img' alt="logo" style={{ width: '80px', minHeight: '80px' }} />
                }
              </div>
              <div className="container p-3">
                <div className='container'>
                  <div className={fgColor ? fgColor : 'text-black'}>
                    <div style={{ color: fgMutedColor }} >{title}</div>
                    <div className='text-truncate text-capitalize' style={{ fontWeight: 'bold', fontSize: '1.825rem' }}>
                      {teamName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container py-0 m-0' style={{ background: 'black', height: '44px' }}>
            <div className='h3 text-white pt-3'>
              {message || 'Team settings'}
            </div>
          </div>

          <div className='container p-0 m-0' style={{ background: 'white' }}>
            <>
              <Tabs
                tabPosition={tabPosition}
                style={{ height: '100%', color: 'black', margin: '0px', padding: '0px', backgroundColor: null }}
                tabBarStyle={{ height: '100%', padding: '0px', backgroundColor: null }}
                animated={false}
                defaultActiveKey={activeTab}
                onChange={(key) => setActiveTab(key)}
              >
                {
                  allTabs.map(({ id, tab, component: TabComponent, color }) => (
                    <TabPane tab={tab} key={`${id}`} style={{ padding: '0px', height: '100%', minHeight: '428px' }}>
                      <div className='p-0 m-0 h-100 w-100' style={{ backgroundColor: color }}>
                        <TabComponent teamId={teamId} team={team} defaultTag={defaultTag} onLoading={handleOnLoading} />
                      </div>
                    </TabPane>
                  ))
                }
              </Tabs>
            </>

          </div>

        </>
      }
    </div>
  )
}

export default TeamSettingsBody;