import React from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Switch, Typography } from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';


const { Text } = Typography;

const noTippingBehavioursForCurrencies = ['USD'];
function shouldShowTippingBehaviour(currency) {
  return !noTippingBehavioursForCurrencies.includes(currency);
}

export const TeamTapBehaviourControl = ({ team, teamId, clientId, onChange, teamTapBehaviour, ...rest }) => {
    const teamTapBehaviours = {
        members: 'Tip Members only',
        team: 'Tip Team only',
        both: 'Tip Both Team & Members'
    };

    const { defaultCurrency } = team;

    const isMembersTapChecked = (teamTapBehaviour === 'members' || teamTapBehaviour === 'both');
    const isTeamTapChecked = (teamTapBehaviour === 'team' || teamTapBehaviour === 'both');

    const updateNewTeamBehavour = ({ newIsMembersTapChecked, newIsTeamTapChecked }) => {
        const newTeamTapBehavour = newIsMembersTapChecked && newIsTeamTapChecked ?
            'both'
            :
            (
                newIsMembersTapChecked ? 'members' : 'team'
            );
        onChange && onChange(newTeamTapBehavour);

    };

    const onClickMember = async () => {
        const newIsMembersTapChecked = !isMembersTapChecked;
        const newIsTeamTapChecked = newIsMembersTapChecked ? isTeamTapChecked : true;
        updateNewTeamBehavour({
            newIsMembersTapChecked,
            newIsTeamTapChecked,
        });
    };


    const onClickTeam = async () => {
        const newIsTeamTapChecked = !isTeamTapChecked;
        const newIsMembersTapChecked = newIsTeamTapChecked ? isMembersTapChecked : true;
        updateNewTeamBehavour({
            newIsMembersTapChecked,
            newIsTeamTapChecked,
        });
    };

    return shouldShowTippingBehaviour(defaultCurrency) && (
        <div className='row justify-content-start my-2'>
            <div className='col' style={{ maxWidth: '200px' }}>
            <Switch
                style={{ width: '100%', minWidth: '120px', maxWidth: '140px', backgroundColor: isTeamTapChecked ? '#ff0098' : 'lightgrey'}}
                checked={isTeamTapChecked}
                checkedChildren={'Pooled'}
                unCheckedChildren={'Pooled'}
                onChange={onClickTeam} />
            </div>
            <div className='col' style={{ maxWidth: '200px' }}>
            <Switch
                style={{ width: '100%', minWidth: '120px', maxWidth: '140px', backgroundColor: isMembersTapChecked ? '#ff0098' : 'lightgrey'}}
                checked={isMembersTapChecked}
                checkedChildren={'Members'}
                unCheckedChildren={'Members'}
                onChange={onClickMember}
                disabled={noTippingBehavioursForCurrencies.includes(defaultCurrency)} // MARK: USD needs to be adjusted
            />
            </div>
        </div>
    );

};

export const TeamTapBehaviourUpdate = ({ team, teamId, teamTapBehaviour, onChange, onLoading, ...rest }) => {
    const teamDetailsModel = new StrikeTeamDetailsModel({
        teamId,
    });

    const messages = {
        members: 'Members only',
        team: 'Pooled',
        both: 'Pooled & Members'
    };

    const updateTeamTapBehavour = async (v) => {
        onLoading && onLoading({ message: `Saving as ${messages[v]}...` });
        onChange && onChange();
        await teamDetailsModel.updateTeamTapBehaviour(v);
        onLoading && onLoading({ message: null });
    };

    return (
        <>
            {team && teamTapBehaviour && 
                <TeamTapBehaviourControl
                    team={team}
                    teamId={teamId}
                    teamTapBehaviour={teamTapBehaviour}
                    onChange={updateTeamTapBehavour}
                />
            }
        </>
    );

}
