import React, { Component } from 'react';
import firebase from 'firebase/app';
import { PageHeader, Tag , Button } from 'antd';
import qs from 'qs';

import StrikeClientModel from '../../../models/StrikeClientModel';
import { EditClientProfileForm } from '../../../components/client/EditClientProfileForm';
import TrackerService from '../../../utils/TrackerService';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { ClientImageEditor } from '../../../components/client/UploadableImageEditor';

import '../../../App.scss';
import 'antd/dist/antd.css';

export default class CompleteClientSignup extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;

    this.model = new StrikeClientModel({ viewModel: this, clientId, isAdmin });
    this.isAdmin = isAdmin;

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      user: null,
      client: null,
      clientId: clientId,
      percent: 0,
      loading: true,
      redirect: queryParams.redirect,
    };
  }

  componentDidMount = async () => {
    this.model.waitForClientData();

    const user = await this.getCurrentUser(firebase.auth());
    this.setState({ user });
  }

  componentWillUnmount() {
    if(this.model) this.model.stop();
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return this.state.loading || false;
  }

  async getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
       }, reject);
    });
  }

  clientNotFound = () => {
    console.log('No user or client here...');
  }

  foundClient = async (client) => {

    this.setState(
      (state) => {
        return {
          client,
          loading: false,
        }
      }
    );
  }

  doneSaving = () => {
    this.model.waitForClientData();
  }

  // MARK: Apply IP Address when terms are accepted
  applyIPAddressWhenTermsAccepted = async (isLocalHost) => {
    const clientId = this.state.clientId;
    if (!clientId) return;
    const baseUrl =  isLocalHost ? 'http://localhost:8088' : `https://pay.strikepay.co`;
    const apiPath = 'api';
    const url = `${baseUrl}/${apiPath}/clients/${clientId}/acceptedterms`;
    const data = { };
    // Define the request options
    const requestOptions = {
      method: "PUT", // Specify the request method
      headers: { "Content-Type": "application/json" }, // Specify the content type
      body: JSON.stringify(data) // Send the data in JSON format
    };
    try {
      await fetch(url, requestOptions);  
    } catch (error) {
      // Ignore the error -- we don't want to block the user from signing up
      console.error('Error:', error);
    }
  }

  internalSave = async (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    await this.model.commit(data);
    await this.applyIPAddressWhenTermsAccepted(isLocalHost);
    this.doneSaving();
  }

  onClickSave = (data) => {
    const isLocalHost = window.location.hostname === 'localhost';
    const isTest = isLocalHost;

    this.internalSave(data);
    
    let successUrl = `/client/manage/${this.state.clientId}`;
    let redirected = false;

    if (this.state.redirect) {
      redirected = true;
      successUrl = this.state.redirect;
    }

    const tracker = new TrackerService({
      isTest,
    });

    const analyticsPayload = {
      isAnonymous: this.state.user.isAnonymous,
      clientId: this.state.clientId,
      redirected,
      successUrl,
    }

    const userId = this.state.user.uid;
    tracker.mixPanelIdentify(userId, this.state.clientId, data.displayName, data.email);
    tracker.mixpanelTrack("client_signup_complete", {
      distinct_id: userId,
      clientName: this.state.client.displayName,
      ...analyticsPayload,
    });  
    
    if (!isTest) {
      tracker.googleTrack(
        "client_signup_complete", 
        {
          userId,
          ...analyticsPayload,
        }
      );
    }

    this.props.history.push(successUrl);
  }

  render() {
    if (!this.state.loading ) {
      const { imageUrl } = this.state;
      const avatarUrl = imageUrl ? imageUrl : this.state.client.avatar;

      return (
        <div style={{ height: '100%' }}>
          <PageHeader
            ghost={false}
            title={`Complete Signup`}
          />

          <ClientImageEditor
            clientId={this.state.clientId}
            client={this.state.client}
            currentUrl={avatarUrl}
          />

          <EditClientProfileForm
            client={this.state.client}
            callback={this.onClickSave}
            isSignUp={true}
            isAdmin={this.isAdmin}
          />
        </div>
      );
    } else {
      return (
        <CenteredSpinner loading={this.isLoading()} noLayout={false} />
      );
    }
  }
}

