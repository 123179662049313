import React, { useEffect, useState } from 'react';

import usePublicAnnouncements from '../../models/usePublicAnnouncements';

import AnnouncementBanner from './AnnouncementBanner';

const AnnouncementList = ({ announcements, isAdmin }) => {
  return (
    <div>
      {announcements && announcements.length > 0 &&
        announcements.map((announcement, index) => (
          <AnnouncementBanner key={`announcement-${index}`} {...announcement} isAdmin={isAdmin} />
        ))
      }
    </div>
  );
};

export const AnnouncementsCard = ({ isAdmin }) => {
  const [announcements, recordView] = usePublicAnnouncements();
  const [recordedIds, setRecordedIds] = useState([]);

  useEffect(() => {
    if (announcements && !isAdmin) {
      const ids = announcements.map((announcement) => announcement.id);
      const recordIds = ids.filter((id) => !recordedIds.includes(id));
      const newRecordedIds = [...recordedIds, ...recordIds];
      setRecordedIds(newRecordedIds);

      for (const id of recordIds) {
          recordView(id);
      }
    } 
  }, [announcements, isAdmin]);

  return (
    <div>
      <AnnouncementList announcements={announcements} isAdmin={isAdmin} />
    </div>
  );
};

export default AnnouncementList;
