import React, { useState } from 'react';
import {
    TeamOutlined,
    ArrowRightOutlined,
    UserAddOutlined,
    BankOutlined,
    EuroCircleOutlined,
    AreaChartOutlined,
    UnorderedListOutlined,
    DeleteRowOutlined,
    EditOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
    Button,
    Space,
    Card,
    Typography,
    Tooltip,
    Avatar,
    Divider,
    Skeleton,
    Tag,
    Image,
    Checkbox,
    Col, Row,
 List } from 'antd';

import { RestrictedTeamPermissions } from '../../utils/Constants';
import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import MoneyTitle from '../customer/MoneyTitle';

import { TeamMemberAvatarForRole, IconForCCY, TeamMemberTitleForItem } from './TeamDashboardComponents';
import { useClientBalances } from './useClientBalances';
import { TeamTapBehaviourControl, TeamTapBehaviourUpdate } from './TeamTapBehaviourControl';
import { TeamMemberDeleteButton } from './TeamMemberDeleteButton';
import { TeamMemberLeaveButton } from './TeamMemberLeaveButton';
import '../../App.scss';
import 'antd/dist/antd.css';
import { useClientTeams } from './useClientTeams';

const { Text } = Typography;

const restrictedPermissions = {
    ...RestrictedTeamPermissions
};

const safelyMapPermission = (permissions) => {
    const {
        canAddMembers,
        canPayMembers,
        canSeeAllTxns,
        canSeeMembers,
        canSeePayouts,
        canSeeTeamBalance,
        isTeamAdmin,
    } = permissions || restrictedPermissions;

    return {
        canAddMembers,
        canPayMembers,
        canSeeAllTxns,
        canSeeMembers,
        canSeePayouts,
        canSeeTeamBalance,
        isTeamAdmin,
    };
};

export const TeamProfileBalancesCard = ({ locStrings, condition, teamClient, teamClientId, teamId, permissions, ...rest }) => {
    const teamClientBalances = useClientBalances(teamClientId);

    const {
        canAddMembers,
        canPayMembers,
        canSeeAllTxns,
        canSeeMembers,
        canSeePayouts,
        canSeeTeamBalance,
        isTeamAdmin,
    } = safelyMapPermission(permissions);

    return teamClientBalances ? (
        <>
            {(isTeamAdmin || canSeeTeamBalance || canSeePayouts) &&
                teamClientBalances.map((account, i) => {
                    return (
                        <Row key={`bal-row-${teamId}-${account.availableAccountId}`} gutter={[{ xs: 4, sm: 6, md: 10, lg: 32 }, { xs: 2, sm: 3, md: 6, lg: 8 }]}> 
                            <ActionButtonCol key={`abc-${teamId}-${account.availableAccountId}-bal`}>
                                <TeamProfileTeamMembersAction
                                    route={canSeePayouts ? `./${teamId}/payout/${account.availableAccountId}` : '#'}
                                    text={<Space direction='horizontal'>
                                        {'Balance'}
                                        <MoneyTitle
                                            amount={account.available}
                                            currency={account.currency}
                                            digits={2}
                                        />
                                    </Space>}
                                    icon={<IconForCCY ccy={account.currency} />}
                                    style={{ width: '100%' }}
                                    arrow={canSeePayouts}
                                />
                            </ActionButtonCol>
                            <ActionButtonCol key={`abc-${teamId}-${account.availableAccountId}-payout`}>
                                <TeamProfileTeamMembersAction
                                    route={canSeePayouts ? `./${teamId}/payout/${account.availableAccountId}` : '#'}
                                    text={'Team Member Payouts'}
                                    icon={<IconForCCY ccy={account.currency} />}
                                    style={{ width: '100%' }}
                                    arrow={canSeePayouts}
                                />
                            </ActionButtonCol>
                        </Row>
                    )
                })
            }
        </>
    ) : <Skeleton button size='large' />;
};


export const TeamProfileTeamMembersAction = ({ route, text, children, icon: ActionIcon, arrow, border, style, ...rest }) => {
    const hasArrow = arrow == null ? true : arrow;
    const bordered = border == null ? true : !!border;

    return (
        <Card type='inner' size='small' bodyStyle={{ paddingLeft: '0px' }} bordered={bordered}>
            <Link to={route} style={{ color: '#000' }}>
                <Row align='middle'>
                    <Col span={2}></Col>
                    <Col span={2}><div style={{ color: '#ff0098', ...style }} {...rest}>{ActionIcon}</div></Col>
                    <Col span={1}></Col>
                    <Col span={17}><Text ellipsis >{text}</Text></Col>
                    <Col span={2}>{hasArrow && <ArrowRightOutlined />}</Col>
                </Row>
            </Link>
        </Card>
    );
};


const ShowOnTapCheckBox = ({ show, member, onChange, ...rest }) => {

    const { memberClientId, teamId } = member;

    const isTippable = show;

    const onCheckTapped = async (e) => {
        const newIsTippableState = e.target.checked;
        // setIsTippable(newIsTippableState);
        onChange && onChange(newIsTippableState);
    };

    return <Checkbox
        checked={isTippable}
        onChange={onCheckTapped}
        {...rest}
        style={{ margin: '14px' }}
    >Show member</Checkbox>
};


const TeamMemberDescription = ({ item, ...rest }) => {
    return (
        <Space direction='horizontal' size='small' key={item.key}>
            { item.permissionIcons.length > 0 &&
                item.permissionIcons.map(
                    (icon, i) => {
                        return <Space key={i}>{icon}</Space>;
                    }
                )
            }
            { item.permissionIcons.length < 1 &&
                <>{item.showOnTap ? 'Can be tipped' : 'Not available to tip'}</>
            }
        </Space>
    );
};


export const ActionButtonCol = ({ children, ...rest }) => {
    return <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>{children}</Col>

};

export const TeamMemberPermissionIcon = ({ allowed, labels, icons, ...rest }) => {
    const isAllowed = !!allowed;

    const style = { fontSize: '18px', color: isAllowed ? 'green' : 'red' };

    const AllowedIcon = ({ icon: Icon, ...rest }) => {
        return <Icon {...rest} />;
    };

    return (
        isAllowed ?
            <Tooltip title={labels[0]} key={labels[0]}>
                <AllowedIcon icon={icons[0]} style={style} />
            </Tooltip>
            :
            <Tooltip title={labels[1]} key={labels[1]}>
                <AllowedIcon icon={icons[1]} style={style} />
            </Tooltip>
    );
};


function getRoleForMember({ team, member }) {
    const role = (team.teamOwnerClientId === member.clientId)
        ?
        'owner'
        :
        (member.permissions && member.permissions.isTeamAdmin ? 'manager' : member.roles[0]);

    return role;
}


export const TeamMembersGrid = ({ condition, locStrings, team, teamId, members, clientId, limit, displayPermissions, isInviteVisible, teamTapBehaviour, onChange, isAdmin, ...rest }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isCheckboxWaiting, setIsCheckboxWaiting] = useState(false);

    const maxItems = limit || 6;
    let tableData = [];
    let adminTableData = [];
    let key = 1;
    let isMoreVisble = false;
    let actionsByMemberClientId = {};
    const backgroundColorByRole = {
        admin: 'magenta',
        member: 'green',
        manager: 'blue',
        owner: 'navy',
        default: 'black'
    };
    const limitDelete = ['owner', 'manager'];

    const updateShowOnTap = async (v, memberRow) => {
        const model = new StrikeTeamDetailsModel({
            teamId,
            memberClientId: memberRow.memberClientId,
        });
        await model.setShowMemberOnTapScreenFlag(v, memberRow);
    };

    const teamName = team && team.teamName ? team.teamName : '';

    const adminMembers = members;

    adminMembers.forEach(member => {
        const isYourself = member.clientId === clientId;

        const role = getRoleForMember({ team, member });

        const teamMemberPermissions = safelyMapPermission(member.permissions);
        let permissionIcons = getPermissionIcons(displayPermissions, teamMemberPermissions);

        const displayName = isYourself ? `${member.displayName} (You)` : member.displayName;

        const memberRow = {
            rowType: 'member',
            name: displayName,
            ...member,
            teamId: member.teamId,
            memberClientId: member.clientId,
            role,
            permissions: teamMemberPermissions,
            avatar: member.avatar,
            key: key,
            showOnTap: member.showOnTap,
            permissionIcons,
            memberColor: backgroundColorByRole[role],
            isYourself,
            routeMember: displayPermissions.isTeamAdmin ? `./${member.teamId}/members/${member.clientId}` : null,
        }

        key++;

        if (isYourself) {
          adminTableData = [memberRow, ...adminTableData];
        } else if (isExpanded || adminTableData.length < maxItems) {
            if (displayPermissions.canSeeMembers) {
              adminTableData.push(memberRow);
            }
        } else if (!isMoreVisble) {
            isMoreVisble = true;
        }

        actionsByMemberClientId[memberRow.memberClientId] = [];
        const behavoursForEnabledShowCheckbox = ['both', 'members'];
        const disabled = !behavoursForEnabledShowCheckbox.includes(teamTapBehaviour);
        if (displayPermissions.isTeamAdmin) {
            actionsByMemberClientId[memberRow.memberClientId].push(
                <ShowOnTapCheckBox
                    disabled={disabled || isCheckboxWaiting}
                    key={`check-show-tip-${memberRow.memberClientId}`}
                    show={memberRow.showOnTap}
                    member={memberRow}
                    onChange={(v) => updateShowOnTap(v, memberRow)}
                />
            );

            if (!isYourself && !limitDelete.includes(memberRow.role)) {
                actionsByMemberClientId[memberRow.memberClientId].push(
                    <>
                        <TeamMemberDeleteButton 
                          key={`member-del-button-${memberRow.memberClientId}`} 
                          member={memberRow} 
                          isAdmin={isAdmin}
                          teamName={teamName}
                        /> 
                    </>
                );
            }
        }
    });

    const notAdminMembers = adminMembers.filter(m => m.isDeleted === false);

    notAdminMembers.forEach(member => {
        const isYourself = member.clientId === clientId;

        const role = getRoleForMember({ team, member });

        const teamMemberPermissions = safelyMapPermission(member.permissions);
        let permissionIcons = getPermissionIcons(displayPermissions, teamMemberPermissions);

        const displayName = isYourself ? `${member.displayName} (You)` : member.displayName;

        const memberRow = {
            rowType: 'member',
            name: displayName,
            ...member,
            teamId: member.teamId,
            memberClientId: member.clientId,
            role,
            permissions: teamMemberPermissions,
            avatar: member.avatar,
            key: key,
            showOnTap: member.showOnTap,
            permissionIcons,
            memberColor: backgroundColorByRole[role],
            isYourself,
            routeMember: displayPermissions.isTeamAdmin ? `./${member.teamId}/members/${member.clientId}` : null,
        }

        key++;

        if (isYourself) {
            tableData = [memberRow, ...tableData];
        } else if (isExpanded || tableData.length < maxItems) {
            if (displayPermissions.canSeeMembers) {
                tableData.push(memberRow);
            }
        } else if (!isMoreVisble) {
            isMoreVisble = true;
        }

        actionsByMemberClientId[memberRow.memberClientId] = [];
        const behavoursForEnabledShowCheckbox = ['both', 'members'];
        const disabled = !behavoursForEnabledShowCheckbox.includes(teamTapBehaviour);
        if (displayPermissions.isTeamAdmin) {
            actionsByMemberClientId[memberRow.memberClientId].push(
                <ShowOnTapCheckBox
                    disabled={disabled || isCheckboxWaiting}
                    key={`check-show-tip-${memberRow.memberClientId}`}
                    show={memberRow.showOnTap}
                    member={memberRow}
                    onChange={(v) => updateShowOnTap(v, memberRow)}
                />
            );

            if (!isYourself && !limitDelete.includes(memberRow.role)) {
                actionsByMemberClientId[memberRow.memberClientId].push(
                    <>
                        <TeamMemberDeleteButton 
                          key={`member-del-button-${memberRow.memberClientId}`} 
                          member={memberRow} 
                          isAdmin={isAdmin}
                          teamName={teamName}
                        /> 
                    </>
                );
            }
        }
    });

    if (isExpanded) {
        isMoreVisble = false;
    }

    let moreCount = key - maxItems - 1;
    if (moreCount < 1) isMoreVisble = false;

    return (
        <>
            {team && teamTapBehaviour && displayPermissions && displayPermissions.canAddMembers && displayPermissions.isTeamAdmin &&
                <>
                    <TeamTapBehaviourUpdate
                        team={team}
                        teamId={teamId}
                        teamTapBehaviour={teamTapBehaviour}
                        displayPermissions={displayPermissions}
                    />
                </>
            }

            { isAdmin ?  
              (
                  <List
                      grid={{
                          xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 5,
                      }}
                      size='large'
                      itemLayout='horizontal'
                      dataSource={adminTableData}
                      renderItem={item => (
                          <List.Item
                              key={`list-${item.key}`}
                              actions={actionsByMemberClientId[item.memberClientId]}
                          >
                              <List.Item.Meta
                                  avatar={<TeamMemberAvatarForRole key={`list-meta-${item.key}-${item.clientId}`} member={item} team={team} route={item.routeMember} size={'large'} />}
                                  title={<TeamMemberTitleForItem item={item} />}
                                  description={<TeamMemberDescription item={item} />}
                              />
                          </List.Item>
                      )}
                  />
              ) : (
                  <List
                      grid={{
                          xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 5,
                      }}
                      size='large'
                      itemLayout='horizontal'
                      dataSource={tableData}
                      renderItem={item => (
                        <List.Item
                            key={`list-${item.key}`}
                            actions={actionsByMemberClientId[item.memberClientId]}
                        >
                            <List.Item.Meta
                                avatar={<TeamMemberAvatarForRole key={`list-meta-${item.key}-${item.clientId}`} member={item} team={team} route={item.routeMember} size={'large'} />}
                                title={<TeamMemberTitleForItem item={item} />}
                                description={<TeamMemberDescription item={item} />}
                            />
                        </List.Item>
                      )}
                  />
              )
            }

            {isMoreVisble &&
                <Button onClick={() => { setIsExpanded(true); isMoreVisble = false; }}>More...</Button>
            }

            {isExpanded && tableData.length > maxItems &&
                <Button onClick={() => setIsExpanded(false)}>Less...</Button>
            }
        </>

    );

};


function getPermissionIcons(displayPermissions, teamMemberPermissions) {
    let permissionIcons = [];

    if (displayPermissions && displayPermissions.isTeamAdmin) {
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canSeeTeamBalance}
            labels={['Can See Team Balance', 'Cannot See Team Balance']}
            icons={[AreaChartOutlined, AreaChartOutlined]} />);
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canAddMembers}
            labels={['Can Invite Members', 'Cannot Invite Members']}
            icons={[UserAddOutlined, UserAddOutlined]} />);
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canPayMembers}
            labels={['Can Pay Members', 'Cannot Pay Members']}
            icons={[EuroCircleOutlined, EuroCircleOutlined]} />);
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canSeeAllTxns}
            labels={['Can See All Transacitons', 'Cannot See All Transacitons']}
            icons={[UnorderedListOutlined, UnorderedListOutlined]} />);
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canSeeMembers}
            labels={['Can See Members', 'Cannot See Members']}
            icons={[TeamOutlined, TeamOutlined]} />);
        permissionIcons.push(<TeamMemberPermissionIcon
            allowed={teamMemberPermissions.canSeePayouts}
            labels={['Can See Payouts', 'Cannot See Payouts']}
            icons={[BankOutlined, BankOutlined]} />);
    }
    return permissionIcons;
};

export const TeamLeaveTeamCard = ({ locStrings, clientId, team, permissions, isAdmin, ...rest }) => {
    const { teamName } = team;
    const {
        canAddMembers,
        canPayMembers,
        canSeeAllTxns,
        canSeeMembers,
        canSeePayouts,
        canSeeTeamBalance,
        isTeamAdmin,
    } =  safelyMapPermission(permissions);

    return true ? (
        <>
            {(!isTeamAdmin) && clientId && team &&
            (
                <Row key={`team-leave-row-${clientId}`} gutter={[{ xs: 4, sm: 6, md: 10, lg: 32 }, { xs: 2, sm: 3, md: 6, lg: 8 }]}> 
                    <ActionButtonCol key={`leave-${clientId}-button`}>
                        <TeamMemberLeaveButton 
                            clientId={clientId}
                            team={team}
                            isAdmin={isAdmin}
                        />
                    </ActionButtonCol>
                </Row>
            )
            }
        </>
    ) : <Skeleton button size='large' />;
};
