import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useTeamClientDetails(teamClientId, team, isAdmin) {
    const [details, setDetails] = useState(null);

    useEffect(() => {
        // Subscribe to the clients collection in Firestore
        if (!isAdmin) {
            setDetails(null);
            return;
        }

        const db = firebase.firestore();
        const ref = db.collection('clients').doc(teamClientId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const data = snapshot.data();
            const id = snapshot.id;
            setDetails({
                ...data, id, clientId: id,
            });
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe && unsubscribe();
        };
    },
        [teamClientId]
    );

    return details;
}
