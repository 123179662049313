import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker, Spin, Space, Typography, Card, Button } from 'antd';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

export const DateRangeCalendarSelector = ({ onUpdateDateRange, onClose, limitRange, closeText }) => {
  const [datesSelected, setDatesSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const textCloseButton = closeText || 'Clear and Close';

  async function selectDate(dates) {
    const startDateRaw = new Date(dates[0])
    const endDateRaw = new Date(dates[1])
    const startOfStartDate =  moment(startDateRaw).startOf('day').toDate()
    const endOfEndDate = moment(endDateRaw).endOf('day').toDate()
    // Convert start and end dates to UTC
    const startOfStartDateUTC = moment(startOfStartDate).clone().utc().toDate()
    const endOfEndDateUTC = moment(endOfEndDate).clone().utc().toDate()
    setStartDate(startOfStartDate)
    setEndDate(endOfEndDate)
    onUpdateDateRange && onUpdateDateRange({
      startDate: startOfStartDate, endDate: endOfEndDate,
      startDateUTC: startOfStartDateUTC, endDateUTC: endOfEndDateUTC,
    });
    setDatesSelected(true)
  }

  function disabledDate(current) {
    const { endDate: limitEndDate } = limitRange || {}
    const limitEnd = limitEndDate || moment().endOf('day');
    return current && current > limitEnd;
  }

  async function onClickCloseButton() {
    setLoading(true)
    onClose && onClose()
    setLoading(false)
  }

  return (
    <Card align='center' style={{ marginTop: 10 }}>
      <Paragraph>Select Date Range</Paragraph>
      <Space direction='vertical'>
        <RangePicker
          defaultValue={moment()}
          format={"DD/MM/YYYY"}
          onChange={selectDate}
          disabledDate={disabledDate}
          size='small'
        />
        <Button
          onClick={onClickCloseButton}
          disabled={loading}
        >
          {loading ? <Spin indicator={SpinnerIcon} /> : (`${textCloseButton}`)}
        </Button>
      </Space>
    </Card>
  );
};
