import { useState, useEffect } from 'react';

import TeamSitesModel from './TeamSitesModel';

export function useTeamSitesByType(props) {
    const { teamId, status, type } = props || {};
    const [sites, setSites] = useState(null);

    const createSite = async (data) => {
        const model = new TeamSitesModel({
            teamId,
        });
        data.type = type || data.type || null;
        return await model.createSite(data);
    };

    const updateSite = async (id, data) => {
        const model = new TeamSitesModel({
            teamId,
        });
        data.type = type || data.type || null;
        return await model.updateSite(id, data);
    };

    useEffect(() => {
        const onUpdate = async (list) => {
            setSites(list);
        };


        const model = new TeamSitesModel({
            teamId,
            status,
            type,
            onUpdate,
        });

        model.start();

        return function cleanup() {
            model.stop();
            setSites(null);
        };

    },
        [teamId, status, type]
    );

    return [sites, createSite, updateSite];
}
