import React, { useState } from 'react';
import { Alert , Card , Button, Modal, Radio, InputNumber, Tooltip } from 'antd';

import { TransferModel } from '../../models/TransferModel';

import { isLocalHost, ccy } from './AccountProfileComponents';
import { useClientTeams } from './useClientTeams';

const setDefaultTeam = (teams, currency) => {
  for (const [i, team] of teams.entries()) {
    if (team.defaultCurrency === currency) {
      return i;
    }
  }

  return 0;
}

export const CashOutToTeamDialog = ({ visible, handleOk, handleCancel, clientId, accountId, currency, clientCurrencyStr, balance, locStrings, ...rest }) => {
  const teams = useClientTeams(clientId);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(() => (teams && teams.length) ? setDefaultTeam(teams, clientCurrencyStr) : 0);
  const [payoutAmount, setPayoutAmount] = useState(() => balance ? balance / 100 : null);
  const [withdrawalError, setWithdrawalError] = useState(false);
  const [withdrawalErrorMessage, setWithdrawalErrorMessage] = useState("");

  const minimumWithdrawalAmount = 1;
  const minimumWithdrawalAmountString = (minimumWithdrawalAmount / 100).toFixed(2);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'left',
  };

  const onSelectedTeamChange = (event) => {
    const index = event.target.value;
    setSelectedTeamIndex(index);    
  };

  const onPayoutAmountChange = (number) => {
    setPayoutAmount(number);
  };

  const withdrawalAvailable = () => {
    return !!teams;
  };

  const teamOptions = teams ? teams.map((team, i) => (
    <Radio 
      style={radioStyle} value={i} key={i} disabled={false}
    >
      {team.teamName}
    </Radio>
    )
  ) : <></>;

  const setWithdrawalObject = async () => {
    if (!withdrawalAvailable()) {
      handleCancel();
      return;
    }

    const isTest = isLocalHost;
    const payoutAmountCents = Math.round(payoutAmount * 100);
    const teamMemberDetail = teams[selectedTeamIndex];
    const teamId = teamMemberDetail.teamId;

    const params = {
      amount: +payoutAmountCents,
      currency: clientCurrencyStr,
      teamId,
      clientId,
      client: teamMemberDetail,
      accountId,
      minimumWithdrawalAmount,
      isTest,
    };

    // { amount, currency, teamId, clientId, client, accountId, minimumWithdrawalAmount, isTest }

    const transferModel = new TransferModel();
    const errorMessage = await transferModel.createClientToTeamTransfer(params);

    switch (errorMessage) {
      case 'Error fetching team details':
        setWithdrawalError(true);
        setWithdrawalErrorMessage('Error: Team details not found');
        return;
      case 'Error fetching client details':
        setWithdrawalError(true);
        setWithdrawalErrorMessage('Error: Client details not found');
        return;
      case 'Error fetching account details':
        setWithdrawalError(true);
        setWithdrawalErrorMessage('Error: Account details not found');
        return;
      case 'Error currency does not match':
        setWithdrawalError(true);
        setWithdrawalErrorMessage(`Error: Bank account currency does not match funds being withdrawn (${currency}). Select another payout method or add a new one in Setup Payouts`);
        return;
      case 'Error minimum withdrawal amount not met':
        setWithdrawalError(true);
        setWithdrawalErrorMessage(`Error: Minimum withdrawal is ${currency}${minimumWithdrawalAmountString}`);
        return;
      default:
        setWithdrawalError(false);
        setWithdrawalErrorMessage(errorMessage);
        break;
    }

    handleOk();
  };

  return (
    <Modal title="Transfer to a Team" visible={visible} onOk={setWithdrawalObject} onCancel={handleCancel}>
      <div style={{ textAlign: 'center' }}>
        {withdrawalError && <Alert message={withdrawalErrorMessage} type="error" showIcon />}

        {withdrawalAvailable()
          ? (
            <>
              <p>{`Note: no limits on Team Transfers`}</p>
              <Card>
                <p>Select Team</p>

                {teams && teams.length > 0
                  ?
                  <Radio.Group onChange={onSelectedTeamChange} value={selectedTeamIndex}>
                    {teamOptions}
                  </Radio.Group>
                  :
                  <>
                    <p>You have no teams yet</p>
                  </>}
              </Card>

              <Card>
                <p>Enter Amount</p>
                <p>Your balance is {currency}{(balance / 100).toFixed(2)}</p>

                {balance >= minimumWithdrawalAmount
                  ?
                  <InputNumber
                    defaultValue={0}
                    min={minimumWithdrawalAmount / 100}
                    max={balance / 100}
                    precision={2}
                    onChange={onPayoutAmountChange} />
                  :
                  <p>{`Minimum payout amount is ${currency}${minimumWithdrawalAmountString}`}</p>}
              </Card>
            </>
          )
          : (
            <Card>
              <p>{`No teams found!`}</p>
            </Card>
          )}
      </div>
    </Modal>
  );
};
