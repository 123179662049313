import { useState, useEffect } from 'react';

import StrikeClientActivitiesModel from '../../models/StrikeClientActivitiesModel';

export function useRecentActivities(clientId, startDate, endDate) {    
    const [recentActivies, setRecentActivities] = useState(null);

    const isRangeSet = startDate && endDate;
    const dateRange = isRangeSet ? { startDate, endDate } : null;

    useEffect(() => {
        const onUpdateRecentActivities = async (activies) => {
            setRecentActivities(activies);
        };

        const model = new StrikeClientActivitiesModel({
            clientId,
            onUpdate: onUpdateRecentActivities,
        });

        if (isRangeSet && dateRange) {
            setRecentActivities(null);
            model.start(dateRange);
        } else {
            setRecentActivities([]);
        }

        return function cleanup() {
            model.stop();
        };

    },
        [clientId, startDate, endDate]
    );

    return recentActivies;
}
