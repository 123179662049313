import React, { Component } from 'react';
import { PageHeader } from 'antd';
import { Redirect } from 'react-router-dom';

import Spinner from '../../../components/Spinner';
import TeamSettingsBody from '../../../components/client/TeamSettingsBody';

import '../../../App.scss';
import 'antd/dist/antd.css';

export default class TeamNewSettingsScreen extends Component {
    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        const teamId = this.props.match.params.teamId;

        this.baseURLs = props.baseURLs || {
            teamDetails: `/client/manage/${clientId}/teams/${teamId}`
        };

        this.clientId = clientId;
        this.teamId = teamId;

        this.state = {
            teamId: teamId,
            clientId: clientId,
            loading: false,
            route: null,
        };
    }


    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isLoading = () => {
        return this.state.loading || false;
    }

    renderRedirect = () => {
        const {route} = this.state;
        const clientId = this.clientId;
        const teamId = this.teamId;
        if (route && clientId && teamId && this.baseURLs.manage) {
            const toRoute = `${this.baseURLs.manage}/${clientId}/teams/${teamId}/${route}`;
            return <Redirect push to={toRoute}/>
        } else if (route && clientId && teamId && this.baseURLs.teamDetails) {
            const toRoute = `${this.baseURLs.teamDetails}/${route}`;
            return <Redirect push to={toRoute}/>
        } else {
            return null;
        }
    }

    render() {
        const redirected = this.renderRedirect();
        if (redirected) {
            return redirected;
        }

        if (!this.state.loading) {
            return (
                <div style={{height: '100%', textAlign: 'center', width: '100%'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Settings`}
                        onBack={(() => window.history.back())}
                    />

                    <TeamSettingsBody teamId={this.state.teamId} />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Team Settings`}
                        onBack={(() => window.history.back())}
                    />
                    <Spinner condition={() => { return this.isLoading() }} />
                </div>
            );
        }
    }
}
