import firebase from 'firebase/app';
import moment from 'moment';

import StrikeTapsModel from './StrikeTapsModel';
import StrikeTeamTransfersModel from './StrikeTeamTransfersModel';
import StrikeWithdrawalModel from './StrikeWithdrawalModel';

const isLocalHost = window.location.hostname === 'localhost';

const collectionNames = {
  available: 'available-transactions',
  pending: 'pending-transactions',
};

const orderByOptionsList = [
  'tapCreatedTs',
  'createdAt',
];

const tapActions = ['tap-made-available', 'tap-paid', 'tap-refunded'];
const otherActions = ['withdrawal', 'transfer', 'monthly-invoice'];
const actions = [...tapActions, ...otherActions];

const pendingTapActions = ['tap-paid', 'tap-refunded'];
const availableTapActions = ['tap-made-available', 'tap-refunded'];

const tapActionOptions = {
  pending: [...pendingTapActions],
  available: [...availableTapActions],
};

const otherActionOptions = {
  pending: [],
  available: [...otherActions],
};

export default class ClientGenericTransactionsModel {

  constructor({ clientId, callbackOne, callbackAll, includeTest, status, isOthers }) {
    this.callbackOne = callbackOne;
    this.callbackAll = callbackAll;
    this.clientId = clientId;
    this.includeTest = includeTest || isLocalHost; // MARK: Show test and live accounts on lcoalhost

    this.tapsModel = new StrikeTapsModel({ clientId });
    this.transfersModel = new StrikeTeamTransfersModel({ clientId });
    this.withdrawalModel = new StrikeWithdrawalModel({ clientId });
    this.status = status || 'available';
    this.collectionName = collectionNames[status] || collectionNames.available;
    this.orderBy = !isOthers ? 'tapCreatedTs' : 'createdAt';
    const validActions = !isOthers ? tapActionOptions[this.status] : otherActionOptions[this.status];
    this.validActions = validActions;
  }

  stop() {
    this.stopOne();
    this.stopAll();
    this.stopHook();
  }

  stopAll() {
    this.callbackAll = null;
    this.unsubscribeForAll();
  }

  stopOne() {
    this.callbackOne = null;
    this.unsubscribeForOne();
  }

  stopHook() {
    this.unsubscribeForObserveAllWithDates();
  }

  unsubscribeForAll = () => {
    if (this.unsubscribeAll) {
      this.unsubscribeAll();
      this.unsubscribeAll = null;
    }
  }

  unsubscribeForOne = () => {
    if (this.unsubscribeOne) {
      this.unsubscribeOne();
      this.unsubscribeOne = null;
    }
  }

  unsubscribeForObserveAllWithDates = () => {
    if (this.unsubscribeAllWithDates) {
      this.unsubscribeAllWithDates();
      this.unsubscribeAllWithDates = null;
    }
  }

  getCollectionQuery = () => {
    const { clientId } = this;
    const db = firebase.firestore();
    const query = db.collection('clients').doc(clientId).collection(this.collectionName);
    return query;
  }

  getAllQuery = () => {
    const { includeTest } = this;
    const preQuery = this.getCollectionQuery();
    const queryWithOrWithoutTest = (includeTest) ? preQuery : preQuery.where('is_test', '==', false);
    const query = queryWithOrWithoutTest.orderBy('createdAt', 'desc');
    return query;
  }

  fetchOne = (id) => {
    const preQuery = this.getCollectionQuery();
    const query = preQuery.doc(id);

    this.unsubscribeOne = query.onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (this.callbackOne) { this.callbackOne(data) }
    });
  }

  fetchAll = () => {
    if (this.clientId === null) return;

    this.unsubscribeForAll();
    const query = this.getAllQuery();

    this.unsubscribeAll = query.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      if (this.callbackAll) { this.callbackAll(data) }
    });
  }

  getActionLabel = (item) => {
    const actionsMap = {
      'withdrawal': {
        payment: 'Withdrawal',
        tipping: 'Cash Out',
        giving: 'Withdrawal',
      },
      'transfer': {
        payment: 'Transfer',
        tipping: 'Transfer',
        giving: 'Transfer',
      },
      'tap-made-available': {
        payment: 'Payment',
        tipping: 'Tip',
        giving: 'Donation',
      },
      'monthly-invoice': {
        payment: 'Monthly fee',
        tipping: 'Monthly subscription',
        giving: 'Monthly fee',
      },
      'tap-paid': {
        payment: 'Payment',
        tipping: 'Tip',
        giving: 'Donation',
      },
      'tap-refunded': {
        payment: 'Payment refunded',
        tipping: 'Tip refunded',
        giving: 'Donation refunded',
      },
    };

    const { action, product } = item || {};
    if (action && product && actions.includes(action)) {
      const actionItem = actionsMap[action] || {};
      const actionName = actionItem[product];
      return actionName;
    }
    return null;
  }

  getTransferInfo = (item) => {
    const transferTypes = ['client-to-client', 'team-to-member'];
    if(transferTypes.includes(item.transferType)) {
      const directionText = item.credit > 0 ? 'from' : 'to';
      const whoText = item.credit > 0 ? (item.fromTeamName || item.fromClientName) : item.toClientName;
      return `${item.description} ${directionText} ${whoText}`;
    } 
    return `${item.description}`;
  }

  getTransferDetailInfo = (item) => {
    const transferTypes = ['client-to-client', 'team-to-member'];
    if(transferTypes.includes(item.transferType)) {
      const whoText = item.credit > 0 ? (item.fromTeamName || item.fromClientName) : item.toClientName;
      return `${whoText}`;
    } 
    return `N/A`;
  }

  getWithdrawalInfo = (item) => {
    const { payoutDisplayName, payoutIban } = item || {};
    let accoutLabel = payoutDisplayName || `from ${item.product}`;
    if(payoutDisplayName && payoutIban) {
      const fourDigit = payoutIban.slice(-4);
      accoutLabel = `to ${accoutLabel} (***${fourDigit})`;
    }
    return `${item.description} ${accoutLabel} account`;
  }

  getWithdrawalDetail = (item) => {
    const { payoutDisplayName, payoutIban } = item || {};
    let detail = payoutDisplayName || `N/A`;
    if(payoutIban) {
      const fourDigit = payoutIban.slice(-4);
      detail = `(***${fourDigit})`;
    }
    return `${detail}`;
  }

  getMapOfFieldForItem = (actionKey, item) => {
    const fallback = {
      key: (d) => { return `${d.transactionId}` },
      desc: (d) => { return `${d.description}` },
      detail: (d) => { return 'N/A' },
    };

    const mapping = {
      'withdrawal': {
        key: (d) => { return `${d.withdrawalId}` },
        desc: (d) => { return this.getWithdrawalInfo(d) },
        detail: (d) => { return this.getWithdrawalDetail(d) },
      },
      'transfer': {
        key: (d) => { return `${d.transferId}` },
        desc: (d) => { return this.getTransferInfo(d) },
        detail: (d) => { return this.getTransferDetailInfo(d) },
      },
      'tap-made-available': {
        key: (d) => { return `${d.tapId}` },
        desc: (d) => { 
          const teamName = ['Individual', ''].includes(d.teamName) ? '' : ` via ${d.teamName}`;
          const name = ['N/A', 'NONE', ''].includes(d.customerName) ? '' : ` by ${d.customerName}`;
          const creditText = d.credit > 0 ? `${d.actionLabel}` : `Fee applied to ${d.actionLabel}`;
          const finalText = d.credit > 0 ? `${creditText}${name}${teamName}` : `${creditText}${name}${teamName}`;
          return finalText;
        },
        detail: (d) => { return `${d.teamName || 'N/A'}` },
      },
      'monthly-invoice': {
        key: (d) => { return `${d.monthlyInvoiceId}` },
        desc: (d) => { return `${d.description}` },
        detail: (d) => { return 'N/A' },
      },
      'tap-paid': {
        key: (d) => { return `${d.tapId}` },
        desc: (d) => { 
          const teamName = ['Individual', ''].includes(d.teamName) ? '' : ` via ${d.teamName}`;
          const name = ['N/A', 'NONE', ''].includes(d.customerName) ? '' : ` by ${d.customerName}`;
          const creditText = d.credit > 0 ? `${d.actionLabel}` : `Fee applied to ${d.actionLabel}`;
          const finalText = d.credit > 0 ? `${creditText}${name}${teamName}` : `${creditText}${name}${teamName}`;
          return finalText;
        },
        detail: (d) => { return `${d.teamName || 'N/A'}` },
      },
      'tap-refunded': {
        key: (d) => { return `${d.tapId}` },
        desc: (d) => { 
          const teamName = ['Individual', ''].includes(d.teamName) ? null : `${d.teamName}`;
          const name = ['N/A', 'NONE', ''].includes(d.customerName) ? '' : `${d.customerName}`;
          const creditText = d.credit > 0 ? `${d.actionLabel}` : `${d.actionLabel}`;
          const descText = name ? `${creditText} by ${name}`: `${creditText}`; 
          const finalText = teamName ? `${descText} via ${teamName}` : descText;
          return `${finalText}`;
        },
        detail: (d) => { return `${d.teamName || 'N/A'}` },
      },
    };

    const mapped = mapping[actionKey] || fallback;

    return { 
      key: mapped.key(item),
      desc: mapped.desc(item),
      detail: mapped.detail(item),
    };
  }

  getReceiptLink = ({ tagId, tapId }) => {
    const linkPath = isLocalHost ? 'http://localhost:8088' : 'https://pay.strikepay.co';
    if (tapId && tagId) {
      const link = `${linkPath}/tags/${tagId}/${tapId}`;
      return link;
    }
    return 'N/A';
  }

  processTransction = async (txn) => {
    const { tapsModel, transfersModel, withdrawalModel } = this;

    const {
      amount,
      createdAt, tapCreatedTs, description,
      currency, product,
      fromTeamName, transferType,
      tagId, tapId, tapTxnSequence,
      withdrawalId, transferId, monthlyInvoiceId, transactionId,
      action, debit, credit, prevBalance, newBalance,
    } = txn;

    if (amount !== 0 && this.validActions.includes(action)) {
      const item = { ...txn };

      const amountValue = (parseFloat(amount) / 100).toFixed(2);
      const debitValue = (parseFloat(debit) / 100).toFixed(2);
      const creditValue = (parseFloat(credit) / 100).toFixed(2);
      const prevBalanceValue = (parseFloat(prevBalance) / 100).toFixed(2);
      const newBalanceValue = (parseFloat(newBalance) / 100).toFixed(2);

      const activityAt = tapCreatedTs || createdAt;

      item.activityAt = activityAt;
      item.created = createdAt;

      item.withdrawalId = withdrawalId;
      item.transferId = transferId;
      item.monthlyInvoiceId = monthlyInvoiceId;
      item.transactionId = transactionId;
      item.transferType = transferType;

      const withdrawalDetail = withdrawalId ? await withdrawalModel.getWithdrawalData(withdrawalId) : {};
      const { payout } = withdrawalDetail || {};
      const { displayName: payoutDisplayName, iban, accountNumber } = payout || {};
      item.payoutDisplayName = payoutDisplayName || null;
      item.payoutIban = iban || accountNumber || null;

      const transferDetail = transferId ? await transfersModel.getTransferDetails(transferId) : {};
      const { fromClient, toClient, fromTeam } = transferDetail;
      item.fromClientName = fromClient ? fromClient.displayName : null;
      item.toClientName = toClient ? toClient.displayName : null;
      item.fromTeamName = fromTeam ? fromTeam.displayName : fromTeamName;

      const fromClientType = fromClient ? fromClient.type : null;
      const toClientType = toClient ? toClient.type : null;
      item.clientType = (fromTeamName) ? 'team' : 'individual';

      item.postedDate = moment(activityAt.toDate()).format('DD/MM/YYYY');
      item.postedTime = moment(activityAt.toDate()).format('hh:mm A');
      item.transactionDate = moment(activityAt.toDate()).format('YYYY-MM-DD hh:mm A');
      item.actionLabel = this.getActionLabel({ action, product }) || action || '';
      item.subAction = transferType || 'N/A';

      item.description = description || 'Transaction';
      item.detail = item.fromClientName || item.fromTeamName || item.toClientName || 'N/A';

      item.amountValue = amountValue;
      item.creditValue = creditValue;
      item.debitValue = debitValue;
      item.direction = credit > 0 ? 'in' : 'out';

      item.prevBalanceValue = prevBalanceValue;
      item.newBalanceValue = newBalanceValue;

      item.tagId = tagId || 'N/A';
      item.tapId = tapId || 'N/A';
      item.sequence = tapTxnSequence ? `${tapTxnSequence}` : '0';
      item.link = this.getReceiptLink({ tagId, tapId });

      item.activityType = item.actionLabel;

      const tapDetail = await tapsModel.fetchTapDetailForExport({ tagId, tapId });

      const fullItem = { ...tapDetail, ...withdrawalDetail, ...transferDetail, ...item, };

      fullItem.clientType = (fullItem.teamId) ? 'team' : fullItem.clientType;
      fullItem.description = this.getMapOfFieldForItem(action, fullItem).desc;
      fullItem.detail = this.getMapOfFieldForItem(action, fullItem).detail;
      fullItem.keyId = this.getMapOfFieldForItem(action, fullItem).key;

      fullItem.title = fullItem.description;

      return (!fullItem.is_test || this.includeTest) ? fullItem : null;
    }
  }


  getQueryWithDates = ({ startDate, endDate, startDateUTC, endDateUTC }) => {
    // Convert dates to UTC
    const orderBy = this.orderBy;
    let query = this.getCollectionQuery();
    query = query
      .where(orderBy, ">=", startDateUTC || startDate)
      .where(orderBy, "<=", endDateUTC || endDate);
    query = query
      .orderBy(orderBy, 'desc')
      .orderBy('tapTxnSequence', 'desc');

    if (this.validActions.length > 0) {
      query = query.where('action', 'in', this.validActions);
    }
    return query;
  }

  processTransactions = async (updatedCollection, onUpdate) => {
    const data = [];
    await Promise.all(updatedCollection.map(async (txn) => {
      const item = await this.processTransction(txn);
      if (item) {
        data.push(item);
      }
    }));
    data.sort((a, b) => (b.activityAt - a.activityAt) == 0 ? (a.newBalance - b.newBalance) :  (b.activityAt - a.activityAt));
    if (onUpdate) { onUpdate(data); }
    return data;
  }

  // MARK: ENTRY POINT
  async fetchAllWithDates(params, onUpdate) {
    this.dataForExport = [];

    const query = this.getQueryWithDates(params);
    const querySnapshot = await query.get();

    const updatedCollection = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    this.dataForExport = await this.processTransactions(updatedCollection);

    if (onUpdate) {
      onUpdate(this.dataForExport);
    }

    return this.dataForExport;
  }

  observeAllWithDates = (params, onUpdate) => {
    if (this.clientId === null) return;

    this.unsubscribeForObserveAllWithDates();
    let query = this.getQueryWithDates(params);
    query = query.limit(600); // MARK: Hardcode 600 for now Feb 14, 2025 for loading bug

    this.unsubscribeAllWithDates = query.onSnapshot( async (snapshot) => {
      const dataDocs = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      this.processTransactions(dataDocs, onUpdate);
    });
  }

  getFieldHeaders () {
    return [
      { label: 'Unique ID', key: 'id' },
      { label: 'Date', key: 'postedDate' },
      { label: 'Time', key: 'postedTime' },

      { label: 'Transaction ID', key: 'keyId' },
      // { label: 'Paid Date', key: 'transactionDate' },
      { label: 'Description', key: 'description' },

      { label: 'Currency', key: 'currency' },
      { label: 'Credit', key: 'creditValue' },
      { label: 'Debit', key: 'debitValue' },

      // RUNNING BALANCE:
      // { label: 'Prev Balance', key: 'prevBalanceValue' },
      { label: 'New Balance', key: 'newBalanceValue' },

      // EXTRA:
      { label: 'Type', key: 'actionLabel' },
      { label: 'Sub Type', key: 'subAction' },
      { label: 'Detail', key: 'detail' },
      { label: 'Product', key: 'product' },
      { label: 'Tag ID', key: 'tagId' },
      // { label: 'Seq No', key: 'sequence' },

      // TAP:
      // { label: "Tap Source", key: "source" },
      // { label: "Tap Amount", key: "amount" },
      { label: "Customer Name", key: "customerName" },
      { label: "Reference", key: "paymentInfo" },

      // TRACKING:
      { label: "Tap RefId", key: "refId" },
      { label: "Device Location", key: "deviceLocation" },
      { label: "Device Number", key: "deviceNumber" },
      { label: "Device Internal ID", key: "deviceInternalID" },
      { label: "Contact Email", key: "contactEmail" },
      { label: "Contact Phone", key: "contactPhone" },
      // { label: "Net Amount", key: "netAmount" },
      // { label: "Applied Fee", key: "strikeFee" },
      // { label: "Total Paid", key: "totalPaid" },

      // NEW: Link
      { label: 'Receipt Link', key: 'link' },
    ];
  }

  getExportFilename () {
    const { clientId } = this;
    const shortName = `${clientId}`
    const now = new Date()
    const date = `${now.toISOString().split('T')[0]}`;
    return `${shortName}-accounting-${date}.csv`;
  }

}
