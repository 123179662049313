
import moment from 'moment';

export const FilterSettings = {
  rangeByRangeId: {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
  },

  defaultRangeId: {
    individual: 'today',
    team: 'thisWeek',
    company: 'today',
  }
};

export function getDateRange(period, minus) {
  let currentDate = moment();
  if (minus) {
    currentDate = currentDate.subtract(minus, period);
  } 
  let startDate = currentDate.clone().startOf(period).toDate();
  let endDate = currentDate.clone().endOf(period).toDate();
  const dateRange = {
    startDate,
    endDate,
  };
  return dateRange;
}

export function todayDateRange() {
  return getDateRange('day');
}

export function yesterdayDateRange() {
  return getDateRange('day', 1);
}

export function thisWeekDateRange() {
  return getDateRange('week');
}

export function lastWeekDateRange() {
  return getDateRange('week', 1);
}

export function thisMonthDateRange() {
  return getDateRange('month');
}

export function lastMonthDateRange() {
  return getDateRange('month', 1);
}

export const dateRangesForFilterCompact = {
  lastMonth: lastMonthDateRange(),
  thisMonth: thisMonthDateRange(),
  lastWeek: lastWeekDateRange(),
  thisWeek: thisWeekDateRange(),
  yesterday: yesterdayDateRange(),
  today: todayDateRange(),
};

export const dateRangesForFilter = {
  lastMonth: lastMonthDateRange(),
  thisMonth: thisMonthDateRange(),
  lastWeek: lastWeekDateRange(),
  thisWeek: thisWeekDateRange(),
  yesterday: yesterdayDateRange(),
  today: todayDateRange(),
};

export function dateFormatted(date) {
  return (date && moment(date)).format('LL') || null;
}
