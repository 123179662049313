import React, { useEffect, useState } from 'react';

import useClientAnnouncements from '../../models/useClientAnnouncements';

import AnnouncementBanner from './AnnouncementBanner';

const ClientAnnouncementList = ({ announcements, onClose, isAdmin }) => {
  const closeAnnouncement = (id) => {
    if (onClose) {
      onClose(id);
    }
  }

  return (
    <div>
      {announcements && announcements.length > 0 &&
        announcements.map((announcement, index) => (
          <AnnouncementBanner
            key={`announcement-${announcement.id}`}
            {...announcement} isAdmin={isAdmin}
            onClose={() => closeAnnouncement(announcement.id)}
          />
        ))
      }
    </div>
  );
};

export const ClientAnnouncementsCard = ({ isAdmin, clientId }) => {
  const [announcements, recordView, closeAnnouncement] = useClientAnnouncements(clientId);
  const [recordedIds, setRecordedIds] = useState([]);

  useEffect(() => {
    if (announcements && !isAdmin) {
      const ids = announcements.map((announcement) => announcement.id);
      const recordIds = ids.filter((id) => !recordedIds.includes(id));
      const newRecordedIds = [...recordedIds, ...recordIds];
      setRecordedIds(newRecordedIds);

      for (const id of recordIds) {
          recordView(id);
      }
    } 
  }, [announcements, isAdmin]);

  return (
    <div>
      <ClientAnnouncementList announcements={announcements} isAdmin={isAdmin} onClose={(id) => closeAnnouncement(id)}/>
    </div>
  );
};

export default ClientAnnouncementList;
