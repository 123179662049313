import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';
const NUM_MAX_ACTIVITIES = 100;


const allStatuses =  ['pending', 'successful', 'created', 'available_requested', 'available', 'refunded'];
const interStatuses =  ['successful', 'created'];
const availableStatuses =  ['available', 'refunded'];
const pendingStatuses =  ['pending', 'available_requested', 'refunded'];
const defaultStatuses =  ['pending', 'available_requested', 'available', 'refunded'];

export default class StrikeClientAutodebitedModel {

  constructor({ clientId, onUpdate, isPendingOnly }) {
    this.clientId = clientId;
    this.client = null;
    this.onUpdate = onUpdate;
    this.autoDebits = [];
    this.autoDebitsById = {};
    this.mode = "all";
    this.statuses = !!isPendingOnly ? pendingStatuses : defaultStatuses;
  }

  getQuery(dateRange) {
    const db = firebase.firestore();
    this.transfers = [];
    const { startDate, endDate } = dateRange || { };

    let query = db.collectionGroup('monthly-invoices')
      .where('clientId', '==', this.clientId)
      .where('status', '==', 'charged');

    if (startDate) {
      query = query.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      query = query.where('createdAt', "<=" , endDate)
    }
    query = query.orderBy('createdAt', 'desc');

    return query;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerAutoDebitedInvoices) {
      this.observerAutoDebitedInvoices();
      this.observerAutoDebitedInvoices = null;
    }

    this.autoDebits = [];
    this.autoDebitsById = {};
  }

  start(dateRange) {
    if (this.clientId === null) return;
    this.stop();
    this.mode = "all";
    this.observeAutoDebitedInvoices(dateRange);
  }

  observeAutoDebitedInvoices(dateRange) {
    const db = firebase.firestore();

    const queryAuto = this.getQuery(dateRange);

    this.observerAutoDebitedInvoices = 
      queryAuto.onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(
          change => {
            if (change.type === 'added' || change.type === 'modified') {
              const invoice = change.doc.data();
              invoice.type = 'auto-debited-invoice';
              const id = change.doc.id;
              if (invoice.skus && invoice.skus.length > 0) {
                let totalAmountChargedCentsEUR = 0;
                let totalAmountChargedCentsUSD = 0;
                let totalAmountChargedCentsGBP = 0;
                for(let g=0; g< invoice.skus.length; g++){
                  if (invoice.skus[g].currency === 'EUR') {
                    totalAmountChargedCentsEUR += invoice.skus[g].totalAmount;
                  }
                  if (invoice.skus[g].currency === 'USD') {
                    totalAmountChargedCentsUSD += invoice.skus[g].totalAmount;
                  }
                  if (invoice.skus[g].currency === 'GBP') {
                    totalAmountChargedCentsGBP += invoice.skus[g].totalAmount;
                  }
                }
                if (totalAmountChargedCentsEUR > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsEUR * -1;
                  invoice.currency = 'EUR';
                  this.autoDebitsById[id + 'EUR'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }

                if (totalAmountChargedCentsUSD > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsUSD * -1;
                  invoice.currency = 'USD';
                  this.autoDebitsById[id + 'USD'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }
                if (totalAmountChargedCentsGBP > 0) {
                  invoice.totalAmountChargedCents = totalAmountChargedCentsGBP * -1;
                  invoice.currency = 'GBP';
                  this.autoDebitsById[id + 'GBP'] = {
                    ...invoice,
                    created: invoice.chargedAt ? invoice.chargedAt : invoice.createdAt,
                    id
                  };
                }
              }

            }

            if (change.type === 'removed') {
              const id = change.doc.id;
              this.autoDebitsById[id] = null;
            }
          }
        );
        this.updateDataSource();
      }, err => {
        console.log(`Auto-debited invoices query error: ${err}`);
      });
  }

  async updateDataSource() {

    let autos = [];

    for (let prop in this.autoDebitsById) {
      autos.push(this.autoDebitsById[prop]);
    }

    autos.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
      const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
      return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
    });

    // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
    this.autoDebits = this.mode === "all" ? autos : autos.slice(0, NUM_MAX_ACTIVITIES);

    this.onUpdate && this.onUpdate(this.autoDebits);
  }

}
