import React from 'react';
import { List, Typography, Divider } from 'antd';

import { useTeamClientDetails } from '../../models/useTeamClientDetails';

import { AdminTeamClientListItem } from './AdminClientsComponents';

import '../../App.scss';
import 'antd/dist/antd.css';

const { Text } = Typography;

export const AdminCardForTeam = ({ isAdmin, clientId, team, locStrings }) => {
  const { teamOwnerClientId } = team || {};
  const client = useTeamClientDetails(clientId, team, isAdmin);
  const ownerClient = useTeamClientDetails(teamOwnerClientId, team, isAdmin);
  const loading = !(client && ownerClient);

  if (client) {
    client.displayName = `${client.displayName}`;
    client.profileBio = 'Team Client';
    client.avatar = team.topLogo;
    client.phoneNumber = ' ';
    client.emailVerified = true;
    client.email = `${team.teamName}`;
  }

  if (ownerClient) {
    ownerClient.profileBio = 'Team Owner';
    ownerClient.phoneNumber = ' ';
  }

  return isAdmin ? (
    <div className='card my-4'>
      <div className='card-header'>Admin Only</div>
      <List
        itemLayout="horizontal"
        pagination={false}
        size='small'
        loading={loading}
        dataSource={loading ? [] : [ownerClient, client]}
        renderItem={c => (
          <AdminTeamClientListItem
            client={c}
            locStrings={locStrings}
          />
        )}
      />
    </div>
  ) : (<></>);
}
