import React, { useState, useParams } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  List, Button, Space, Card, Checkbox, Radio, Form, Input, Modal, Typography, Table, Tabs, Tag, Tooltip, Empty, Divider,
  Badge, Avatar,
} from 'antd';
const { Text, Paragraph } = Typography;
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  CheckCircleOutlined,  
 } from '@ant-design/icons';

const { TabPane } = Tabs;
import Spinner from '../Spinner';
import { useSessionStorage } from '../../models/useSessionStorage';
import { useSitesByType } from '../../models/useSites';
import { capitalize } from '../../utils/ProductSwitcher';
import { SiteImageEditor } from '../client/UploadableImageEditor';

import { ColorPickerControlFormItem } from './AdminSitesColorPicker';

const isLocalHost = window.location.hostname === 'localhost';


const ExternalLink = ({ url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="link-primary text-decoration-underline">
      {url}
    </a>
  );
}

const EditReviewRequiredFields = ({ review }) => {
  return (
    <>
      <Form.Item
        name="brand"
        label="Unique Identifier"
        style={{ width: '100%', textAlign: 'left' }}
        rules={[
          {
            required: true,
            message: 'Please provide a unique identifier no spaces',
            whitespace: false,
            pattern: /^[a-zA-Z0-9]+$/,
          },
        ]}
      >
        <Input maxLength={22} />
      </Form.Item>
      <Form.Item
        name="displayName"
        label="Display Name"
        style={{ width: '100%', textAlign: 'left' }}
        rules={[
          {
            required: true,
            message: 'Please provide a display name',
          },
        ]}
      >
        <Input maxLength={64} />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        style={{ width: '100%', textAlign: 'left' }}
        rules={[
          {
            required: true,
            message: 'Please provide a description',
          },
        ]}
      >
        <Input maxLength={128} />
      </Form.Item>
    </>
  );
}

const EditReviewOptionalFields = ({ review }) => {
  return (
    <>
      <Form.Item
        name="link"
        label="Site Main Page"
        style={{ width: '100%', textAlign: 'left' }}
        placeholder="https://"
        rules={[
          {
            required: false,
            message: 'Please provide a valid https:// url',
            pattern: /^(https):\/\/[^ "]+$/, // https url link
          },
        ]}
      >
        <Input maxLength={220} />
      </Form.Item>
    </>
  );
}

const CreateSiteForm = ({ onCreate, onUpdate, editId, editItem, errorMessage, square, rectangle }) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState((editItem && editItem.imageUrl) || null);
  const modalCtaCopy = editId ? 'Save' : 'Create';
  const { rgbColor } = editItem || {};

  const onFinish = (values) => {
    values.link = values.link || null;
    values.imageUrl = values.imageUrl || null;

    if (editId) {
      values.id = editId;
      values.imageUrl = imageUrl || editItem.imageUrl || values.imageUrl;
      onUpdate && onUpdate(values);
      return;
    }
    onCreate && onCreate(values);
  };

  return (
      <div style={{ textAlign: 'center' }}>
        <Card>
          {
            errorMessage &&
            <div className='alert alert-danger' role='alert'>
              {errorMessage}
            </div>
          }
          <Form
            form={form}
            name="createSite"
            onFinish={onFinish}
            initialValues={{ ...editItem }}
          >
            <SiteImageEditor 
              siteId={editId}
              square={!!square}
              rectangle={!!rectangle}
              currentUrl={(editItem && editItem.imageUrl) || null}
              onUpdateImage={(imageUrl) => setImageUrl(imageUrl)}
            />
            <EditReviewRequiredFields />
            <ColorPickerControlFormItem title='Pick a color for the review' rgbColor={rgbColor} />
            <EditReviewOptionalFields />
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {modalCtaCopy}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
  );
}

const SiteStatusButton = ({ item, onClickUpdateStatus, loadingButton }) => {
  return (
    <Button
      type="primary"
      style={{
        backgroundColor: item.status === 'live' ? 'green' : 'grey',
        borderColor: item.status === 'live' ? 'green' : 'grey',
      }}
      onClick={() => onClickUpdateStatus(item)}
      icon={loadingButton.loading && loadingButton.id === item.id ? <LoadingOutlined /> : null}
    >
      {capitalize(item.status)}
    </Button>
  );
}



const SiteTypeTab = ({ reviews, createSite, updateSite, filterByStatus, status, type, square, rectangle }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [loadingButton, setLoadingButton] = useState({ loading: false, id: null });
  const [editId, setEditId] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const titles = {
    review: 'Review Site',
    affiliate: 'Affiliate Site',
    ad: 'Ad Site',
  };
  const title = titles[type] || 'Review Site';
  const modalTitle = editId ? `Edit ${title}` : `Create ${title}`;
  const selectedStatus = status || 'clear';

  const onClickModalCancel = () => {
    setMessage(null);
    setShowCreateModal(false);
    setEditId(null);
    setEditItem(null);
  }

  const onCreate = async (values) => {
    setMessage(null);
    const {
      brand,
      displayName,
      description,
    } = values || {};

    if (!brand || !displayName || !description) {
      setMessage('Missing required fields');
      return;
    }

    const newReview = await createSite(values);

    if (!newReview) {
      setMessage('Brand needs to be unique');
      return;
    }

    setMessage(null);
    setShowCreateModal(false);
  }

  const onUpdate = async (values) => {
    setMessage(null);
    const {
      id,
      brand,
      displayName,
      description,
    } = values || {};

    if (!id || !brand || !displayName || !description) {
      setMessage('Missing required fields');
      return;
    }

    await updateSite(id, values);

    setEditId(null);
    setEditItem(null);
    setMessage(null);
    setShowCreateModal(false);
  }

  const onClickFilter = (e) => {
    let status = e.target.value;
    status = status === 'clear' ? null : status;
    filterByStatus && filterByStatus(status, type);
  }

  const onClickUpdateStatus = async (item) => {
    const { id, status } = item;
    const statuses = ['live', 'paused', 'created'];
    if (!statuses.includes(status)) {
      return;
    }
    const flipStatuses = {
      live: 'paused',
      paused: 'live',
      created: 'live',
    };
    setLoadingButton({ loading: true, id });
    const newStatus = flipStatuses[status];
    await updateSite(id, { ...item, status: newStatus });
    setLoadingButton({ loading: false, id: null });
  }

  const onClickEdit = (item) => {
    const { id } = item;
    setEditId(id);
    setEditItem(item);
    setShowCreateModal(true);
  }

  return (
    <div>
      <div className='row'>
        <div className='col text-left'>
          <div className='container'>
            <Radio.Group onChange={onClickFilter} defaultValue={selectedStatus} buttonStyle={'outline'} size={'small'}>
              <Radio.Button value="created">Created</Radio.Button>
              <Radio.Button value="paused">Paused</Radio.Button>
              <Radio.Button value="live">Live</Radio.Button>
              { selectedStatus !== 'clear' && <Radio.Button value="clear"><CloseCircleOutlined /></Radio.Button> }
            </Radio.Group>
          </div>
        </div>
        <div className='col text-right'>
        <div className='container'>
            <Button
              type="primary"
              onClick={() => setShowCreateModal(true)} icon={<PlusCircleOutlined />}
              style={{ backgroundColor: 'green', borderColor: 'green' }}
              size='small'
            >
              {'Create'}
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title={modalTitle}
        visible={showCreateModal}
        onCancel={() => onClickModalCancel()}
        footer={null}
        bodyStyle={{ minHeight: '200px', minWidth: '280px' }}
        destroyOnClose={true}
      >
        <CreateSiteForm
          onCreate={onCreate}
          onUpdate={onUpdate}
          editId={editId}
          editItem={editItem}
          onCancel={onClickModalCancel}
          errorMessage={message}
          square={!!square}
          rectangle={!!rectangle}
        />
      </Modal>

      <Divider />

      <List
        itemLayout="horizontal"
        pagination={{
          pageSize: 8,
        }}
        style={{ textAlign: 'left' }}
        dataSource={reviews}
        split={true}
        renderItem={item => (
          <List.Item
            style={{ padding: '8px 0' }}
            actions={[
              <SiteStatusButton item={item} onClickUpdateStatus={onClickUpdateStatus} loadingButton={loadingButton} />,
              <Button onClick={() => onClickEdit(item)}>{`Edit`}</Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <div className='row h3 justify-content-left mx-1'>
                    {item && item.displayName}
                    <div className='mx-3'><Tag color={item.rgbColor}>{item && item.brand}</Tag></div>
                </div>
              }
              description={item && 
                <ul className='mx-1'>
                  <li>{item.description}</li>
                  <li><ExternalLink url={item.link} /></li>
                </ul>
              }
              avatar={<img src={item && item.imageUrl} style={{ width: 'auto', height: '90px', padding: '16px', minWidth: '90px' }} />}
            />
          </List.Item>
        )}
      />
    </div>
  );
}



const AdminAllSitesBody = ({ isAdmin, ...rest }) => {
  const [activeTab, setActiveTab] = useSessionStorage('adminAllSitesTab', 'reviews');
  const [statuses, setStatuses] = useState({});
  const reviewType = 'review';
  const [reviews, createReview, updateReview] = useSitesByType({  status: statuses.review, type: reviewType });
  const affiliateType = 'affiliate';
  const [affiliates, createAffiliate, updateAffiliate] = useSitesByType({ status: statuses.affiliate, type: affiliateType });
  const adType = 'ad';
  const [ads, createAd, updateAd] = useSitesByType({ status: statuses.ad, type: adType });
  const bookType = 'book';
  const [books, createBook, updateBook] = useSitesByType({ status: statuses.book, type: bookType });

  const filterByStatus = (status, type) => {
    setStatuses(
      statuses => ({
          ...statuses,
          [type]: status,
        })
    );
  }

  const tabsList = [
    { id: 'reviews', tab: 'Reviews', create: createReview, update: updateReview, data: reviews, type: 'review', square: true },
    { id: 'affiliates', tab: 'Affiliates', create: createAffiliate, update: updateAffiliate, data: affiliates, type: 'affiliate', square: true },
    { id: 'bookings', tab: 'Booking Sites', create: createBook, update: updateBook, data: books, type: 'book', square: true }, 
    { id: 'ads', tab: 'Ads', create: createAd, update: updateAd, data: ads, type: 'ad', square: true }, 
  ];

  return (
    (isAdmin) ?
      <>
        <Tabs defaultActiveKey={activeTab} type='card' onChange={v => setActiveTab(v)}>
          { tabsList.map(({ id, tab, create, update, data, type, square, rectangle }) => (
            <TabPane tab={tab} key={id} style={{ padding: '0px' }}>
              <div className='p-0 m-0 h-100 w-100'>
                {data && 
                  <SiteTypeTab 
                    reviews={data}
                    createSite={create}
                    updateSite={update}
                    filterByStatus={filterByStatus}
                    status={statuses[type] || null}
                    type={type}
                    square={!!square}
                    rectangle={!!rectangle}
                  />
                }
                <Spinner condition={() => !data} />
              </div>
            </TabPane>
          ))
          }
        </Tabs>
      </>

      :
      <Paragraph>
        You are not authorised to view this page.
      </Paragraph>
  );
};


export default AdminAllSitesBody;