import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  CalendarTwoTone,
  CloseCircleFilled,
} from '@ant-design/icons';
import { Typography, PageHeader, Button, Tooltip, Space, Card } from 'antd';

import {
  FilterSettings,
  dateRangesForFilter, dateFormatted, dateRangesForFilterCompact,
} from '../../utils/FilterSettings';
import { useSessionStorage } from '../../models/useSessionStorage';

import { DateRangeCalendarSelector } from './DateRangeCalendarSelector';

const { Paragraph, Text } = Typography;

const {
  rangeByRangeId,
  defaultRangeId,
} = FilterSettings;

const {
  individual: defaultRangeIdForIndividual,
  team: defaultRangeIdForTeam,
  company: defaultRangeIdForCompany,
} = defaultRangeId;

const dateRangeLabels = {
  ...rangeByRangeId,
};

const DateRangeDescriptor = ({ index }) => {
  const { startDate, endDate } = index ? dateRangesForFilter[index] : {};
  const dayOfStart = dateFormatted(startDate) || 'No Date';
  const dayOfEnd = dateFormatted(endDate) || 'No Date';

  return (startDate && endDate && 
    <>
      { ['lastWeek', 'lastMonth'].includes(index) && 
      <Text type='secondary'>From {dayOfStart} to {dayOfEnd}</Text>
      }
      { ['thisWeek', 'thisMonth'].includes(index) && 
      <Text type='secondary'>Since {dayOfStart}</Text>
      }      
      { ['yesterday', 'today'].includes(index) && 
      <Text type='secondary'>{' '}</Text>
      }      
    </>
  );
}

const SelectorButtonLabel = ({ currentIndex, selectedIndex }) => {
  return <p style={{ fontWeight: (currentIndex === selectedIndex ? 'bold' : 'normal')}}>
    {dateRangeLabels[currentIndex]}
  </p>;
}

const DateRangeFilterSelector = ({ selectedIndex, onSelectRange, onSelectRangeIndex, disabled, compact, isAdmin, storageId, type, startDate }) => {
  const admKey = isAdmin ? 'a' : 'u';
  const stortageKey = `${admKey}_${storageId}_index`;
  const defaultDateRangeIndex = defaultRangeId[type];
  const defaultIndex = selectedIndex || defaultDateRangeIndex;
  const defaultShowCalendar = type === 'company' ? true : false;
  const dateRanges = compact ? dateRangesForFilterCompact : dateRangesForFilter;
  const rangeIndexes = Object.keys(dateRanges);
  const [index, setIndex] = useSessionStorage(stortageKey, defaultIndex, rangeIndexes);
  const isDisabled = disabled;
  const [isShowCalendar, setIsShowCalendar] = useState(defaultShowCalendar);
  const textCloseButton = `Close and Show "${dateRangeLabels[index]}"`;

  useEffect(() => {
    if (index !== selectedIndex && !isShowCalendar) {
      onUpdateSelection(index);
    }
  }, [index]);

  const onUpdateSelection = (newIndex) => {
    setIndex(newIndex);
    onUpdateDateRange(dateRanges[newIndex]);
    onSelectRangeIndex && onSelectRangeIndex(newIndex);
  }

  const onClickButton = (newIndex) => {
    setIsShowCalendar(false);
    onUpdateSelection(newIndex);
  }

  const onUpdateDateRange = (updatedDateRange) => {
    const { startDate, endDate } = updatedDateRange;
    const startDateUTC = moment(startDate).clone().utc().toDate()
    const endDateUTC = moment(endDate).clone().utc().toDate()
    const adjustedDateRange = { startDate, endDate, startDateUTC, endDateUTC };
    onSelectRange && onSelectRange(adjustedDateRange);
  }

  const onCloseCalendar = () => {
    setIsShowCalendar(false);
    onUpdateSelection(index);
  }

  const onOpenCalendar = () => {
    setIsShowCalendar(true);
  }

  const CalendarButton = () => {
    const CalendarButtonText = () => {
      const btnStyle = isShowCalendar ? { fontWeight: 'bold' } : {};
      return <>
        {isShowCalendar ?
          <p style={btnStyle}><CloseCircleFilled /></p> :
          <p><CalendarTwoTone /></p>
        }
      </>;
    };

    return <>
      <Button
        key={`btn-ds-cal`}
        onClick={btnOnClick}
        disabled={isDisabled}
        size='small'
      >
        <CalendarButtonText />
      </Button>
    </>;
  }

  const btnOnClick = () => { isShowCalendar ? onCloseCalendar() : onOpenCalendar(); }

  return (<>
    { isShowCalendar &&
      <DateRangeCalendarSelector
        onUpdateDateRange={onUpdateDateRange}
        onClose={onCloseCalendar}
        closeText={textCloseButton}
      />
    }
    <CalendarButton />
    {rangeIndexes.map(v => (
      <Button
        key={`btn-ds-${v}`}
        onClick={() => (onClickButton(v))}
        disabled={isDisabled}
        size='small'
      >
        <SelectorButtonLabel selectedIndex={isShowCalendar ? null : index} currentIndex={v} />
      </Button>
    ))
    }
    {index && !isShowCalendar &&
      <div>
        <DateRangeDescriptor index={index} />
      </div>
    }
  </>);
};

export default DateRangeFilterSelector;
