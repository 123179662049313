import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeWithdrawalsModel {

  constructor({ viewModel, clientId, includeTestAccounts, limit }) {
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.uid = null;
    this.withdrawals = [];
    this.includeTestAccounts = includeTestAccounts || isLocalHost; // MARK: Show test and live accounts on lcoalhost
    this.limit = limit || 0;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }
  }

  waitForWithdrawlsData = () => {
    if (this.clientId === null) return;

    const clientId = this.clientId

    if(this.observerAccounts) {
      this.observerAccounts();
      this.observerAccounts = null;
    }

    const db = firebase.firestore();
    const preQuery = db.collection('clients').doc(clientId).collection('withdrawals');
    const queryWithOrWithoutTest = (this.includeTestAccounts) ? preQuery : preQuery.where('is_test', '==', false); // MARK: strangeness in Firebasee 
    let query = queryWithOrWithoutTest.orderBy('createdAt', 'desc');

    if ( this.limit > 0 ) {
      query = query.limit(this.limit);
    }

    this.promisesAll = [];

    this.observerAccounts = query.onSnapshot(querySnapshot => {
      let index = querySnapshot.size;
      this.withdrawals = [];

      querySnapshot.forEach(
        doc => {
          let withdrawal = doc.data();
          const { amount: providedAmount, payoutAmountCents } = withdrawal;
          const amount = providedAmount || payoutAmountCents; // MARK: Adjust for no `amount` field
          withdrawal = { ...withdrawal, amount };
          this.withdrawals.push( { ...withdrawal, id: doc.id });
        }
      );

      this.didFetchAllAccounts();
    }, err => {
      console.log(`Encountered a withdrawal model error: ${err}`);
    });

  }

  async didFetchAllAccounts() {
    this.updateDataSource();
  }

  async updateDataSource() {
    this.viewModel.updatedWithdrawalsDataSource(this.withdrawals);
  }

}
