import { useState, useEffect } from 'react';

import StrikeClientModel from '../../models/StrikeClientModel';


export function useClientDetails(clientId) {
    const [clientDetails, setClientDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onClientFound = async (client) => {
            setClientDetails(client);
            setLoading(false);
        };

        const onClientNotFound = async () => {
            setClientDetails(null);
            setLoading(false);
        };

        const model = new StrikeClientModel({
            clientId,
            onClientFound: onClientFound,
            onClientNotFound: onClientNotFound,
        });

        model.fetchClientDataOnly();

        return function cleanup() {
            model.stop();
        };

    },
        [clientId]
    );

    return clientDetails;
}
