import { useState, useEffect } from 'react';

import ClientAnnouncementsModel from './ClientAnnouncementsModel';

export default function useClientAnnouncements(clientId) {
    const [announcements, setAnnouncements] = useState(null);

    const recordView = async (id) => {
        const updateModel = new ClientAnnouncementsModel({ clientId });
        await updateModel.trackClientAnnouncementView({ id });
    }

    const closeAnnouncement = async (id) => {
        const updateModel = new ClientAnnouncementsModel({ clientId });
        const status = 'closed';
        await updateModel.updateAnnouncementStatus({ id, clientId, status });
    }

    useEffect(() => {
        const onUpdate = async (list) => {
            setAnnouncements(list);
        };


        const model = new ClientAnnouncementsModel({
            clientId,
            onUpdate,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        [clientId]
    );

    return [announcements, recordView, closeAnnouncement];
}
