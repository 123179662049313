import React, { Component } from 'react';
import { Typography, PageHeader, Button, Col, Row, Space, Switch } from 'antd';

import AccountTransactionsBody from '../../../components/client/AccountTransactionsBody';

import '../../../App.scss';
import 'antd/dist/antd.css';


// /client/manage/profile
export default class DownloadTransactionsScreen extends Component {
  constructor(props) {
    super(props);
    const clientId = this.props.match.params.clientId;
    const isAdmin = !!props.isAdmin;
    this.clientId = clientId; // Can be the main client or a team client
    this.isAdmin = isAdmin;
    this.locStrings = props.locStrings;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  isLoading = () => {
    return false;
  }

  render() {
    return (
      <div className="downloadTransactionsScreen" style={{ height: '100%' }}>
        <PageHeader
          ghost={false}
          title={`Download Transactions`}
          onBack={(() => window.history.back())}
        />
        {this.clientId &&
          <AccountTransactionsBody isAdmin={this.isAdmin} clientId={this.clientId} locStrings={this.locStrings} />
        }
      </div>
    );
  }
}

