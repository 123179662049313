import firebase from 'firebase/app';

import StrikeTeamTapsModel from './StrikeTeamTapsModel';

const isLocalHost = window.location.hostname === 'localhost';
const NUM_MAX_ACTIVITIES = 100;

export default class StrikeTeamDetailsModel {

    constructor({viewModel, teamId, memberClientId, onUpdateDetails, onUpdateActivities, fetchTeamTaps, fetchMemberTaps, fetchTransfers, limitedToMember, dateRange, permissions }) {
        this.viewModel = viewModel;
        this.teamId = teamId;
        this.teamDetails = null;
        this.teamMembersList = null;
        this.teamTapsList = null;
        this.teamActivitiesById = {};
        this.teamClient = null;
        this.memberClientId = memberClientId;
        this.memberClient = null;
        this.onUpdateActivities = onUpdateActivities;
        this.onUpdateDetails = onUpdateDetails;
        this.fetchTeamTaps = fetchTeamTaps;
        this.fetchMemberTaps = fetchMemberTaps;
        this.fetchTransfers = fetchTransfers;
        this.limitedToMember = limitedToMember;
        this.dateRange = dateRange || {};
        this.permissions = permissions;
        this.db = firebase.firestore();
    }
    
    updateQueryWithDates = (query, dateFieldName, dateRange) => {  
        const { startDate, endDate, startDateUTC, endDateUTC } = dateRange || {};
        if(!startDate || !endDate || !dateFieldName) return query;

        return query
          .where(dateFieldName, ">=", startDateUTC || startDate)
          .where(dateFieldName, "<=", endDateUTC || endDate)
    }  

    start() {
        this.stop();
        this.waitForTeamTransactions();
    }

    stop() {
        // stop all listeners.,.not sure they get droppped when object drops out of scope.
        this.viewModel = null;
        if (this.observerTeamMembers) {
            this.observerTeamMembers();
            this.observerTeamMembers = null;
        }

        if (this.observerTeamDetails) {
            this.observerTeamDetails();
            this.observerTeamDetails = null;
        }

        if (this.observerTeamTaps) {
            this.observerTeamTaps();
            this.observerTeamTaps = null;
        }

        if (this.observerTeamMember) {
            this.observerTeamMember();
            this.observerTeamMember = null;
        }

        if (this.observerTeamClient) {
            this.observerTeamClient();
            this.observerTeamClient = null;
        }

        if (this.observerTeamOnlyDetails) {
            this.observerTeamOnlyDetails();
            this.observerTeamOnlyDetails = null;
        }

        if ( this.observerTeamMembersList) {    
            this.observerTeamMembersList();
            this.observerTeamMembersList = null;
        }
    }

    // FETCHING

    stopObserverTeamMembersList = () => {
        if ( this.observerTeamMembersList) {    
            this.observerTeamMembersList();
            this.observerTeamMembersList = null;
        }
        this.teamMembersList = [];
    }

    stopObserverTeamDetails = () => {
        if ( this.observerTeamOnlyDetails) {    
            this.observerTeamOnlyDetails();
            this.observerTeamOnlyDetails = null;
        }
        this.teamDetails = null;
    }

    fetchTeamDetails = ({ onUpdated }) => {
        const query = this.db.collection('teams').doc(this.teamId);
        this.stopObserverTeamDetails();
        this.observerTeamOnlyDetails = query.onSnapshot(docSnapshot => {
            let teamDetails = docSnapshot.data();
            teamDetails.id = docSnapshot.id;
            teamDetails.teamId = docSnapshot.id;
            this.teamDetails = teamDetails;
            onUpdated && onUpdated(this.teamDetails);
        });
    }

    fetchTeamMembers = ({ isUnapprovedIncluded, onUpdated }) => {
        let query = this.db.collection('teams').doc(this.teamId).collection('members');
        query = isUnapprovedIncluded ? query : query.where('status', '==', 'approved');
        
        this.stopObserverTeamMembersList();

        this.observerTeamMembersList = query.onSnapshot(querySnapshot => {
            querySnapshot.forEach(
                doc => {
                    let teamMember = doc.data();
                    this.teamMembersList.push(teamMember);
                }
            );
            onUpdated && onUpdated(this.teamMembersList);
        });
    }

    // END FETCHING

    getDateForTap = ({ tap }) => {
        const {
            createdAt, created,
            accountsProcessingStartedAt,
            successfulAt, pendingAt,
            updatedAt,
        } = tap || {};
        const date = accountsProcessingStartedAt || successfulAt || pendingAt || createdAt || created || updatedAt;
        return date;
    }

    waitForTeamDetails = ({fetchTeamClient}) => {
        if (this.teamId === null) return;

        if (this.observerTeamMembers) {
            this.observerTeamMembers();
            this.observerTeamMembers = null;
        }

        if (this.observerTeamDetails) {
            this.observerTeamDetails();
            this.observerTeamDetails = null;
        }

        const query = this.db.collection('teams').doc(this.teamId).collection('members').where('status', '==', 'approved');

        this.observerTeamDetails = this.db.collection('teams').doc(this.teamId).onSnapshot(docSnapshot => {
            this.teamDetails = docSnapshot.data();

            if (fetchTeamClient) {
                this.waitForTeamClient(this.teamDetails);
            }

            this.observerTeamMembers = query.onSnapshot(querySnapshot => {
                let index = querySnapshot.size;
                this.teamMembersList = [];

                querySnapshot.forEach(
                    doc => {
                        let teamMember = doc.data();
                        this.teamMembersList.push(teamMember);
                    }
                );
                this.didFetchTeamData();
            }, err => {
                console.log(`Encountered a team members fetching error: ${err}`);
            });

        }, err => {
            console.log(`Encountered a team details fetching error: ${err}`);
        });
    }

    waitForTeamTransactions = (fetchTaps) => {
        if (this.teamId === null) return;

        let { fetchTeamTaps, fetchMemberTaps, fetchTransfers, limitedToMember, permissions } = this;
        const { isTeamAdmin, canSeePayouts, canSeeAllTxns } = permissions || {}; 
        const tapsLimitedToMemberId = (isTeamAdmin || canSeeAllTxns) ? null : limitedToMember;
        const transfersLimitedToMemberId = (isTeamAdmin || canSeePayouts) ? null : limitedToMember;
        fetchTeamTaps = fetchTaps || fetchTeamTaps;

        const isFetchingTransfersAllowed = true;

        if (fetchTeamTaps && this.observerTeamTaps) {
            this.observerTeamTaps();
            this.observerTeamTaps = null;
        }

        const query = this.db.collection('teams').doc(this.teamId).collection('members').where('status', '==', 'approved');

        this.observerTeamDetails = this.db.collection('teams').doc(this.teamId).onSnapshot(docSnapshot => {
            let teamDetails = docSnapshot.data();
            const teamId = docSnapshot.id;

            const { teamClientId } = teamDetails || {};

            if(!teamClientId) return;

            // MARK: Source of Team Taps (not Team Member Taps)
            if(fetchTeamTaps || fetchMemberTaps) {
                let query = this.db.collectionGroup('taps')
                            .where('teamId', '==', teamId);

                if(!fetchMemberTaps) {
                    query = query.where('clientId', '==', teamClientId)
                } else if(tapsLimitedToMemberId) {
                    query = query.where('clientId', '==', tapsLimitedToMemberId)
                }

                query = query
                            .where('status', 'in', ['pending', 'available_requested', 'available', 'refunded'])

                query = query.orderBy('created', 'desc');

                query = this.updateQueryWithDates(query, 'created', this.dateRange);

                this.observerTeamTaps = query.onSnapshot(querySnapshot => {
                        let index = querySnapshot.size;
                        this.teamActivitiesList = [];
                        querySnapshot.forEach(
                            doc => {
                                let teamTap = doc.data();
                                teamTap.type = 'tap';
                                teamTap.activityType = teamTap.product === 'tipping' ? 'Tip' : 'Pay';
                                teamTap.title = teamTap.displayName;
                                teamTap.activityAt = this.getDateForTap({ tap: teamTap });
                                teamTap.currency = teamTap.paymentDetails.currency;
                                teamTap.clientType = teamTap.teamMemberId ? 'individual' : 'team';
                                teamTap.direction = teamTap.status === 'refunded' ? 'out' : 'in';
                                this.teamActivitiesById[doc.id] = {...teamTap, id: doc.id, tapId: doc.id };
                            }
                        );
                        this.didFetchTeamActivitiesData();
                    }, err => {
                        console.log(`Encountered a team taps fetching error: ${err}`);
                    });
            }

            if (isFetchingTransfersAllowed) {
                let query = this.db.collection('transfers');
                if(transfersLimitedToMemberId) {
                    query = query.where('fromClientId', '==', transfersLimitedToMemberId)
                    query = query.where('toClientId', '==', teamClientId)
                } else {
                    query = query.where('toClientId', '==', teamClientId)
                }
                query = query
                    .where('status', '==', 'sent')
                    .orderBy('createdAt', 'desc');

                query = this.updateQueryWithDates(query, 'createdAt', this.dateRange);

                this.observerInwardTransfers = query.onSnapshot(querySnapshot => {

                        querySnapshot.docChanges().forEach(
                            change => {
                                if (change.type === 'added' || change.type === 'modified') {
                                    const transfer = change.doc.data();
                                    transfer.type = 'transfer';
                                    transfer.subType = 'transfer-in';
                                    transfer.activityType = 'Paid in';
                                    transfer.activityAt = transfer.sentAt || transfer.readyAt|| transfer.createdAt;
                                    transfer.amount = +(transfer.payoutAmountCents);
                                    transfer.netAmount = +(transfer.payoutAmountCents);
                                    transfer.avatar = transfer.fromClient.avatar;
                                    transfer.title = transfer.fromClient.displayName;
                                    transfer.clientType = transfer.fromClient.type;
                                    transfer.direction = 'in';
                                    const id = change.doc.id;
                                    this.teamActivitiesById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
                                }

                                if (change.type === 'removed') {
                                    const id = change.doc.id;
                                    this.teamActivitiesById[id] = null;
                                }
                            }
                        );
                        this.didFetchTeamActivitiesData();
                    }, err => {
                        console.log(`Team inwards transfer query error: ${err}`);
                    });

                query = this.db.collection('transfers');
                if(transfersLimitedToMemberId) {
                    query = query.where('fromClientId', '==', teamClientId)
                    query = query.where('toClientId', '==', transfersLimitedToMemberId)
                } else {
                    query = query.where('fromClientId', '==', teamClientId)
                }
                query = query    
                    .where('status', '==', 'sent')
                    .orderBy('createdAt', 'desc');
                query = this.updateQueryWithDates(query, 'createdAt', this.dateRange);

                this.observerOutwardTransfers = query.onSnapshot(querySnapshot => {
                        querySnapshot.docChanges().forEach(
                            change => {
                                if (change.type === 'added' || change.type === 'modified') {
                                    const transfer = change.doc.data();
                                    transfer.type = 'transfer';
                                    transfer.subType = 'transfer-out';
                                    transfer.activityAt = transfer.sentAt || transfer.readyAt|| transfer.createdAt;
                                    transfer.activityType = 'Paid out';
                                    const id = change.doc.id;
                                    transfer.payoutAmountCents = transfer.payoutAmountCents * -1;
                                    transfer.amount = +(transfer.payoutAmountCents);
                                    transfer.netAmount = +(transfer.payoutAmountCents);
                                    transfer.avatar = transfer.toClient.avatar;
                                    transfer.title = transfer.toClient.displayName;
                                    transfer.clientType = transfer.toClient.type;
                                    transfer.direction = 'out';
                                    this.teamActivitiesById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
                                }

                                if (change.type === 'removed') {
                                    const id = change.doc.id;
                                    this.teamActivitiesById[id] = null;
                                }
                            }
                        );
                        this.didFetchTeamActivitiesData();

                    }, err => {
                        console.log(`Team outwards transfer query error query error: ${err}`);
                    });

            }

        }, err => {
            console.log(`Encountered a team details fetching error: ${err}`);
        });

    }

    waitForTeamClient = (teamDetails) => {
        if (this.teamId === null) return;
        
        const { teamClientId } = teamDetails || {};
        if (!teamClientId) return;

        if (this.observerTeamClient) {
            this.observerTeamClient();
            this.observerTeamClient = null;
        }

        this.observerTeamClient = this.db.collection('clients').doc(teamClientId).onSnapshot(docSnapshot => {
            this.teamClient = docSnapshot.data();
            this.teamClient.id = teamClientId;
            this.didFetchTeamClientData();
        }, err => {
            console.log(`Encountered a team client fetching error: ${err}`);
        });

    }

    waitForTeamMember = (teamDetails) => {
        if (this.teamId === null || this.memberClientId === null) return;

        if (this.observerTeamMember) {
            this.observerTeamMember();
            this.observerTeamMember = null;
        }

        this.observerTeamMember = this.db.collection('team').doc(this.teamId).collection('members').doc(this.memberClientId).onSnapshot(docSnapshot => {
            this.memberClient = docSnapshot.data();
            this.memberClientId.id = this.memberClientId;
            this.didFetchTeamMemberData();
        }, err => {
            console.log(`Encountered a team client fetching error: ${err}`);
        });
    }

    async didFetchTeamData() {
        await this.updateDataSource();
    }

    async updateDataSource() {
        if (this.viewModel) {
            this.viewModel.setTeamDetailsOnPage(this.teamDetails, this.teamMembersList);
        }
    }

    async didFetchTeamActivitiesData() {
        await this.updateTeamTapDataSource();
    }

    updateTeamTapDataSource = async () => {
        let teamActivities = [];

        for (let prop in this.teamActivitiesById) {
            teamActivities.push(this.teamActivitiesById[prop]);
        }

        teamActivities.sort((a, b) => {
            const now = new Date();
            const nowString = now.toISOString();
            const aCreated = (a && a.created) ? a.created : nowString;
            const bCreated = (b && b.created) ? b.created : nowString;
            const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
            const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
            return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
        });

        // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
        this.recentActivities = this.mode === "all" ? teamActivities : teamActivities.slice(0, NUM_MAX_ACTIVITIES);

        if (this.viewModel) {
            this.viewModel.setTeamTapDetailsOnPage(teamActivities);
        }

        this.onUpdateActivities && this.onUpdateActivities(this.recentActivities);
    }

    async didFetchTeamClientData() {
        await this.updateTeamClientDataSource();
    }

    async updateTeamClientDataSource() {
        if (this.viewModel) {
            this.viewModel.setTeamClientDetailsOnPage(this.teamClient);
        }
    }

    async didFetchTeamMemberData() {
        await this.updateTeamMemberDataSource();
    }

    async updateTeamMemberDataSource() {
        if (this.viewModel) {
            this.viewModel.setTeamClientDetailsOnPage(this.teamClient);
        }
    }

    async updateExistingTeam(data) {
        const res = await this.db.collection('teams').doc(this.teamId).update(data);
    }

    async updateExistingTeamMember(data) {
        const res = await this.db.collection('teams').doc(this.teamId).collection('members').doc(this.memberClientId).update(data);
    }

    async addClientIdAsMember(clientId, client, addedByClientId) {
        const { teamName, teamDescription } = this.teamDetails || {  };
        const { displayName, email, avatar } = client || {  };

        await this.db.collection('teams').doc(this.teamId).collection('members').doc(clientId).set({
            teamId: this.teamId, //teamId added for reverse lookup of client's teams using members collection group
            teamName: teamName || null, //teamId added for reverse lookup of client's teams using members collection group
            teamDescription: teamDescription || null,
            avatar: avatar || null,
            clientId: clientId,
            displayName: displayName || null,
            addedBy: addedByClientId,
            email: email || null,
            showOnTap: true,
            roles: ['member'],
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
            status: 'approved', //initiated, claimed, approved
            isDeleted: false,
        });
    }

    async deleteMemberFromTeam(clientId) {
        await this.db.collection('teams').doc(this.teamId).collection('members').doc(clientId).update({
            isDeleted: true,
            deletedAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async restoreMemberFromTeam(clientId) {
        await this.db.collection('teams').doc(this.teamId).collection('members').doc(clientId).update({
            isDeleted: false,
            restoredAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async updateTeamTapBehaviour(teamTapBehaviour) {
        const allowedTeamTapBehaviour = ['both', 'team', 'members'];
        if (allowedTeamTapBehaviour.includes(teamTapBehaviour)) {
            await this.db.collection('teams').doc(this.teamId).update({
                teamTapBehaviour,
                teamTapBehaviourUpdatedAt: firebase.firestore.Timestamp.now(),
                updatedAt: firebase.firestore.Timestamp.now(),
            });
        }
    }

    async updateTeamColor({ rgbColor }) {
        await this.db.collection('teams').doc(this.teamId).update({
            rgbColor: rgbColor || null,
            colorUpdatedAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async updateTeamName({ teamName, teamDescription }) {
        let data = { };
        if (teamName) {
            data = {
                ...data,
                teamName,
                teamNameUpdatedAt: firebase.firestore.Timestamp.now(),
            }
        }
        if (teamDescription) {
            data = {
                ...data,
                teamDescription,
                teamDescriptionUpdatedAt: firebase.firestore.Timestamp.now(),
            };
        }

        await this.db.collection('teams').doc(this.teamId).update({
            ...data,
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async updateDefaultPermissions(team) {
        const { defaultPermissions } = team || {};

        let data = { };
        if (defaultPermissions) {
            data = {
                ...data,
                defaultPermissions,
                defaultPermissionsUpdatedAt: firebase.firestore.Timestamp.now(),
            }
        }

        await this.db.collection('teams').doc(this.teamId).update({
            ...data,
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async updateReviewDetails(team) {
        const { reviewDetails } = team || {};

        let data = { };
        if (reviewDetails) {
            data = {
                ...data,
                reviewDetails,
                reviewDetailsUpdatedAt: firebase.firestore.Timestamp.now(),
            }
        }

        await this.db.collection('teams').doc(this.teamId).update({
            ...data,
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async setShowMemberOnTapScreenFlag(showOnTap, member) {
        await this.db.collection('teams').doc(this.teamId).collection('members').doc(member.clientId).update({
            showOnTap: showOnTap,
            showOnTapUpdatedAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

    async setTeamOwnerClientId(teamOwnerClientId) {
        await this.db.collection('teams').doc(this.teamId).update({
            teamOwnerClientId,
            teamOwnerUpdatedAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        });
    }

}
