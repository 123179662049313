import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    CheckCircleFilled,
    PlusCircleFilled,
    MinusCircleFilled,
} from '@ant-design/icons';
import {
    List,
    Tag,
    Space,
    Row,
    Col,
    message,
} from 'antd';

import { ActivityItemAdminButton } from '../admin/ActivityItemAdminButton';
import { useClientTransactions } from '../../models/useClientTransactions';
import { capitalize } from '../../utils/ProductSwitcher';

import { MovementSummaryView } from './MovementSummaryView';
import { ActivityDescriptionView, MoneyStatus, TapMoneyView, AvatarBadge } from './TapsComponents';
import { DailyRecentActivityList } from './DailyRecentActivityList';
import { useTeamRecentActivities } from './useTeamRecentActivities';
import DateRangeFilterSelector, { dateRangesForFilter } from './DateRangeFilterSelector';
import { isLocalHost } from './AccountProfileComponents';


function DisplayDates({
    startDate,
    endDate,
    startDateUTC,
    endDateUTC,
  }) {
    return <>
        <p>Start Date (Local): {moment(startDate).format('YYYY-MM-DD HH:mm:ss z')}</p>
        <p>End Date (Local): {moment(endDate).format('YYYY-MM-DD HH:mm:ss z')}</p>
        <p>Start Date (UTC): {moment(startDateUTC).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
        <p>End Date (UTC): {moment(endDateUTC).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
    </>;
  }

const PaymentInfoTag = ({ paymentInfo }) => {
    const removeText = ['N/A', 'NONE', ''];
    const isVisible = paymentInfo && paymentInfo.length > 0 && !removeText.includes(paymentInfo);
    return <>
        {isVisible &&
            <div>
                <Tag color='darkgreen'>{`"${paymentInfo}"`}</Tag>
            </div>
        }
    </>;
}

export const TeamProfileRecentActivityList = ({ teamId, isAdmin, clientId, team, displayPermissions, locStrings, ...rest }) => {
    const [dateRange, setDateRange] = useState(null);
    const recent = useTeamRecentActivities(teamId, clientId, displayPermissions, dateRange);
    const { activites: recentActivities, summary: recentSummary } = recent || {};
    const { byCurrency } = recentSummary || {}; 

    const onSelectRange = (range) => {
        setDateRange(range);
    }

    return (<>
        <DateRangeFilterSelector
            storageId={teamId}
            isAdmin={isAdmin}
            type={'team'}
            onSelectRange={onSelectRange}
            disabled={dateRange && !recentActivities}
        />
        <MovementSummaryView byCurrency={byCurrency}/>
        <DailyRecentActivityList
            recentActivities={recentActivities}
            isAdmin={isAdmin}
            locStrings={locStrings}
            listItemComponent={TeamActivityListItem}
        />
    </>);

};

export const ClientRecentAvailableActivityList = ({ isAdmin, clientId, client, locStrings, ...rest }) => {
    const [dateRange, setDateRange] = useState(null);
    const recentActivities = useClientTransactions(clientId, dateRange); // MARK: useClientAvailableTransactions
    const { isCompany } = client || {};

    const onSelectRange = (range) => {
        setDateRange(range);
    }

    return (<>
        <DateRangeFilterSelector 
            storageId={clientId}
            isAdmin={isAdmin}
            type={isCompany ? 'company' : 'individual'}
            onSelectRange={onSelectRange}
            disabled={dateRange && !recentActivities}
        />
        <DailyRecentActivityList
            recentActivities={recentActivities}
            isAdmin={isAdmin}
            locStrings={locStrings}
            listItemComponent={ClientActivityListItem}
        />
    </>);

};

const ActivityItemTag = ({ item }) => {
    const { link } = item;

    const statusTag = ({ item }) => { 
        const { direction, status, activityType } = item;

        return <>
        {status === 'refunded' ? 
            <Tag color={'red'}>{capitalize(status)}</Tag> :
            <Tag color={direction === 'in' ? 'green' : 'red'}>{activityType}</Tag>
        }
        </>;
    };

    return <ConditonalLink link={link} component={statusTag} item={item} />;
}

const ConditonalLink = ({ link, component: Component, ...rest }) => {
    return <>
        { (link && link !== 'N/A') ?
            <a href={link}>
                <Component { ...rest }/>
            </a> :
            <>
                <Component { ...rest } />
            </>
        }
    </>;
};

const TeamActivityListItem = ({
    id, isAdmin, item, activityType, direction, clientType, title, avatar, amount, currency, status, activityAt, locStrings,
    fee, strikeFee, source, tagUid, templateTagId,
    paymentInfo, reference, messageText, customerName,
    ...rest
}) => {
    const titleText = ({ title }) => {
        let fullTitle = `${activityType} for ${title}`;
        fullTitle = customerName ? `${fullTitle} by ${customerName}` : fullTitle;
        return <>{fullTitle}</>;
    };

    // MARK: Move all link paths to a global
    let path = isLocalHost ? `http://localhost:8088` : `https://pay.strikepay.co`;
    path = `${path}/tags`;
    let link = null;
    if (item && item.tagId) {
        link = `${path}/${item.tagId}/${item.tapId}`;   
    }

    const titleLine = <>
        <ConditonalLink
            link={link}
            component={titleText}
            title={title}
        />
        <PaymentInfoTag paymentInfo={paymentInfo} />
    </>;

    return (
        <List.Item key={`ra-li-ti-${id}`} style={{ width: '100%', marginTop: '4px' }}>
            <List.Item.Meta
                avatar={
                    <AvatarBadge
                        avatar={avatar}
                        direction={direction}
                        isTeam={(clientType === 'team')}
                    />
                }
                title={titleLine}
                description={<>
                    <ActivityDescriptionView
                        activityAt={activityAt} clientType={clientType} activityType={activityType} direction={direction}
                        status={status} source={source} templateTagId={templateTagId} tagUid={tagUid}
                    />
                    {isAdmin && item.status !== 'sent' &&
                        <ActivityItemAdminButton
                            isAdmin={isAdmin}
                            item={item}
                        />
                    }
                </>}
            />
            <Space direction='horizontal' align='center' size={0}>
                <TapMoneyView
                    amount={amount} strikeFee={strikeFee} fee={fee} currency={currency} status={status}
                />
            </Space>
        </List.Item>
    );
};


const MoneyCircleFilled = ({ amount, isTotal }) => {
    const colorTotal = amount > 0 ? 'green' : '#aa3333';
    const plusCircle =  <PlusCircleFilled style={{ color: 'green' }} />;
    const minusCircle = <MinusCircleFilled style={{ color: '#aa3333' }} />;
    const checkCircleFilled = isTotal ? <CheckCircleFilled style={{ color: colorTotal }} /> : null;

    let moneyCircle = amount > 0 ? plusCircle : minusCircle;
    moneyCircle = checkCircleFilled || moneyCircle;
    return moneyCircle;
}

const MoneyStatusRow = ({ item }) => {
    const { id, status, amount, currency, isTotal, activityType } = item;
    const isFilled = ['sent', 'available', 'refunded', 'paid'].includes(status);
    const isStrikeThru = status === 'refunded';
    const colorTotal = amount > 0 ? 'green' : '#aa3333';
    const checkCirleFilled = <CheckCircleFilled style={{ color: colorTotal }} />;
    
    return (
        <Row key={`ca-li-ri-${id}`} wrap={false} justify='end'>
            <MoneyStatus 
                amount={amount} 
                currency={currency}
                filled={isFilled}
                strikethrough={isStrikeThru}
                icon={<MoneyCircleFilled amount={amount} isTotal={isTotal}/>}
            />
        </Row>  
    );

}

const MoneyStatusTag = ({ statusTag }) => {
    return (
        <Row wrap={false} justify='end' style={{ paddingTop: '4px', paddingRight: '6px' }}>
            {statusTag}
        </Row>  
    );
}

const MoneyStatusRows = ({ id, items, totalOnly, statusTag }) => {
    const showTotalOnly = false;
    const excludeTotal = true;
    let rows = items.slice(0,1);
    const row = rows[0];
    let tap;

    const { amount, currency, status, strikeFee, fee, action } = row;
    if (['tap-made-available', 'tap-paid', 'refunded'].includes(action)) {
        tap = { ...row }; 
        rows = null;
    }

    return <>
        <Row justify='middle' key={`msr-row-${id}`}>
            <Col >
                { tap && 
                    <Row key={`tap-li-ri-${id}`} wrap={false} justify='end'>
                        <TapMoneyView
                            amount={amount} strikeFee={strikeFee} fee={fee} currency={currency} status={status}
                        />
                    </Row>
                }
                {rows && rows.map(item => (
                    <MoneyStatusRow key={`ca-li-ri-${item.id}`} item={item} />
                ))}
                <MoneyStatusTag key={`ca-li-st-${id}`} statusTag={statusTag} />
            </Col>
        </Row>
    </>;
}

const ClientActivityListItem = ({ id, activityAt, items, item, locStrings, isAdmin, ...rest }) => {
    const {
        avatar, direction, clientType, title, teamName, tagUid, link, is_test,
        status, activityType, templateTagId, source,
        paymentInfo, reference, messageText, customerName,
    } = items[0];
    const activityItemTag = <ActivityItemTag key={`ait-id-${id}`} item={items[0]}/>;
    const isTest = !!is_test;
    const testTag = isTest ? <Tag>Test only</Tag> : null;
    const isTeam = (clientType === 'team');

    const titleText = ({ title }) => {
        return <>{title}</>;
    };

    const titleLine = <div>
            <ConditonalLink 
                link={link}  
                component={titleText}
                title={title} 
            />
            <PaymentInfoTag paymentInfo={paymentInfo}/>
        </div>;

    return (
        <List.Item key={`ca-li-${id}`} style={{ width: '100%', marginTop: '4px' }}>
            <List.Item.Meta
                avatar={
                    <AvatarBadge
                        avatar={avatar}
                        direction={direction}
                        isTeam={isTeam}
                    />
                }
                title={titleLine}
                description={<>
                    <ActivityDescriptionView 
                        activityAt={activityAt} clientType={clientType} activityType={activityType} direction={direction}
                        status={status} source={source} templateTagId={templateTagId} tagUid={tagUid}
                    />
                    {isAdmin && item.status !== 'sent' &&
                        <ActivityItemAdminButton
                            isAdmin={isAdmin}
                            item={items[0]}
                        />
                    }
                </>}
            />
            <MoneyStatusRows id={id} items={items} totalOnly={false} statusTag={testTag} />
        </List.Item>
    );
};

