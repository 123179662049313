import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

// Admin imports:
import AdminAppRoute from './AdminAppRoute';
import AdminLoginScreen from './pages/admin/auth/AdminLoginScreen';
import AdminDashboardScreen from './pages/admin/manage/AdminDashboardScreen';
import AdminLayout from './layouts/admin/AdminLayout';
import AdminLoginLayout from './layouts/admin/AdminLoginLayout';
import AdminBalancesScreen from './pages/admin/manage/AdminBalancesScreen';
import AdminDeveloperScreen from './pages/admin/manage/AdminDeveloperScreen';
import AdminClientsScreen from './pages/admin/manage/AdminClientsScreen';
import AdminWithdrawalsScreen from './pages/admin/manage/AdminWithdrawalsScreen';
import AdminTemplateTagsScreen from './pages/admin/manage/AdminTemplateTagsScreen';
import AdminTemplateTagScreen from './pages/admin/manage/AdminTemplateTagScreen';
import AdminNotificationsScreen from './pages/admin/dashboard/AdminNotificationsScreen';
import AdminBatchedPayoutsScreen from './pages/admin/manage/AdminBatchedPayoutsScreen';
import AdminBatchedPayoutDetailScreen from './pages/admin/manage/AdminBatchedPayoutDetailScreen';
import AdminAllSitesScreen from './pages/admin/manage/AdminAllSitesScreen';
import AdminSiteScreen from './pages/admin/manage/AdminSiteScreen';
// client layouts
import ClaimLayout from './layouts/client/ClaimLayout';
// client pages
import ClientLoginScreen, { ClientSignupScreen } from './pages/client/auth/ClientLoginScreen';
import CompleteClientSignup from './pages/client/auth/CompleteClientSignup';
import ConfirmClaimTagScreen from './pages/client/claim/ConfirmClaimTagScreen'
import ClientDashboardScreen from './pages/client/manage/ClientDashboardScreen';
import ClientDeveloperScreen from './pages/client/manage/ClientDeveloperScreen';
// customer pages
import PaymentSuccessScreen from './pages/customer/PaymentSuccessScreen';
import TagDetailScreen from './pages/customer/TagDetailScreen';
import QRDetailScreen from './pages/customer/QRDetailScreen';
import TapDetailScreen from './pages/customer/TapDetailScreen';
import BeginClaimScreen from './pages/customer/BeginClaimScreen'
import SetupRequiredScreen from './pages/customer/SetupRequiredScreen';
import N404Screen from './pages/N404Screen';
// Account Profile imports
import ConstrainedAppRoute from './ConstrainedAppRoute';
import AccountProfileLayout from './layouts/client/AccountProfileLayout';
import TeamLayout from './layouts/client/TeamLayout';
import AccountProfileScreen from './pages/client/manage/AccountProfileScreen';
import EditClientProfileScreen from './pages/client/manage/EditClientProfileScreen';
import IDVerificationScreen from './pages/client/manage/IDVerificationScreen';
import TeamsClientProfileScreen from './pages/client/manage/TeamsClientProfileScreen';
import BankClientProfileScreen from './pages/client/manage/BankClientProfileScreen';
import BankClientRedirectScreen from './pages/client/manage/BankClientRedirectScreen';
import PayoutsBankClientScreen from './pages/client/manage/PayoutsBankClientScreen';
import QrsClientProfileScreen from './pages/client/manage/QrsClientProfileScreen';
import TagsClientProfileScreen from './pages/client/manage/TagsClientProfileScreen';
import TagClientScreen from './pages/client/manage/TagClientScreen';
import SkuProductDisplayPage from './pages/client/orders/SkuProductDisplayPage';
import QRTipmeScreen from './pages/client/manage/QRTipMeScreen';
import QRPayMeScreen from './pages/client/manage/QRPayMeScreen';
import ManageAccessScreen from './pages/client/manage/ManageAccessScreen';
import AccessInviteScreen from './pages/client/manage/AccessInviteScreen';
import TeamInviteScreen from './pages/client/manage/TeamInviteScreen';
import TeamSettingsScreen from './pages/client/manage/TeamSettingsScreen';
import TeamNewSettingsScreen from './pages/client/manage/TeamNewSettingsScreen';
import DownloadTransactionsScreen from './pages/client/manage/DownloadTransactionsScreen';
import AnonLayout from './layouts/client/AnonLayout';
// datasheets
import DataSheet_localizationSheet from './DataSheet_localizationSheet';
import StrikeStateFlows from './strikeStateFlows';
import TeamMemberDetailsScreen from './pages/client/manage/TeamMemberDetailsScreen';
import TeamQRScreen from './pages/client/manage/TeamQRScreen';
import TeamTagsScreen from './pages/client/manage/TeamTagsScreen';
import TeamTagEditScreen from './pages/client/manage/TeamTagEditScreen';
import TeamPayoutsScreen from './pages/client/manage/TeamPayoutsScreen';
import TeamTipScreen from './pages/client/manage/TeamTipScreen';
import TeamMemberPayoutsScreen from './pages/client/manage/TeamMemberPayoutsScreen';
import ClientVerifyEmailScreen from './pages/client/auth/ClientVerifyEmailScreen';
import ClientEmailVerifiedScreen from './pages/client/auth/ClientEmailVerifiedScreen';
import TeamProfileScreen from './pages/client/manage/TeamProfileScreen';
import ClientPayoutScreen from './pages/client/manage/ClientPayoutScreen';
import ClientWithdrawalScreen from './pages/client/manage/ClientWithdrawalScreen';

import './App.scss';

export default class StrikeRouter extends Component {

    constructor(props) {
        super(props);

        this.dataSheets = {};
        this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
        this.dataSheetLoaded = {};
    
        this.dataSlots = {};
        this.dataSlots['ds_activeLang'] = "en";
        this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);
    
        this.strikeStateFlows = new StrikeStateFlows();
    
        this.state = {
          screenTransitionForward: true,
          loading: true,
          found: false,
          userLogdedInStatus : 'logged-out', // [`logged-out`, `logging-in-anonymous`, `anonymous-logged-in`,`real-logging-in, `real-logged-in`]
          tagLoadingStatus : 'loading', // [`loading`, `not-found`, ...all_tag_statuses+owner]
        }
    
      }
    
      windowDidResize = () => {
        let w = window.innerWidth;
        let formatId;
        if (w < 576) formatId = 'narrow-phone';
        else if (w < 768) formatId = 'wide-phone';
        else if (w < 1024) formatId = 'narrow-tablet';
        else formatId = 'wide-tablet';
        if (formatId !== this.state.screenFormatId) {
          this.setState({screenFormatId: formatId});
        }
      }
    
      componentDidMount() {
        this.windowDidResize();
        window.addEventListener('resize', this.windowDidResize);
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.windowDidResize);
      }
    
      isLoading() {
        return this.state.loading;
      }
    
      goToScreen = (screenId, props) => {
        // This method is the default implementation and could be customized by a navigation plugin.
        this.props.history.push('/'+screenId, {...props, appActions: null, locStrings: null, dataSheets: null});
        window.scrollTo(0, 0);
      }
    
      goBack = () => {
        // This method is the default implementation and could be customized by a navigation plugin.
        this.props.history.goBack();
      }
    
      getDataSheet = (sheetId) => {
        // This method is the default implementation and could be customized by a state management plugin.
        return this.dataSheets[sheetId];
      }
    
      addToDataSheet = (sheetId, newRow, actionId) => {
        // This method is the default implementation and could be customized by a state management plugin.
        let sheet = this.dataSheets[sheetId];
        if (sheet && newRow) {
          sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
        }
        this.setState({});
      }
    
      updateInDataSheet = (sheetId, row, actionId) => {
        // This method is the default implementation and could be customized by a state management plugin.
        let sheet = this.dataSheets[sheetId];
        if (sheet && row) {
          sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
          this.setState({});
        }
      }
    
      removeFromDataSheet = (sheetId, row) => {
        let sheet = this.dataSheets[sheetId];
        if (sheet && row) {
          sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
        }
        this.setState({  });
      }
    
      updateDataSlot = (slotId, value, actionId) => {
        // This method is the default implementation and could be customized by a state management plugin.
        if (value === this.dataSlots[slotId])
          return;
    
        this.dataSlots[slotId] = value;
    
        if (slotId === 'ds_activeLang') {
          this.locStrings.setLanguage(value);
        }
    
        this.setState({});
      }
    
      dataSheetDidUpdate = (dataSheet) => {
        // This method is the default implementation and could be customized by a state management plugin.
        this.setState({ loading : false });
      }
    
      updateLocalizationFromDataSheet = (dataSheet) => {
        const stringsObj = dataSheet.getStringsByLanguage();
        if (stringsObj && Object.keys(stringsObj).length > 0) {
          this.locStrings = new LocalizedStrings(stringsObj);
        } else {
          this.locStrings = new LocalizedStrings({en: {}});
        }

        this.dataSlots['ds_activeLang'] = this.locStrings.getLanguage();
        this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
      }

  render() {

    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
        let screenProps = {
          ...baseProps,
          loading : this.state.loading,
          atTopOfScreenStack: atTop,
          transitionForward: forward,
          appActions: this,
          dataSheets: this.dataSheets,
          locStrings: this.locStrings,
          actions: this.strikeStateFlows.actions,
          deviceInfo: {
            screenFormatId: this.state.screenFormatId
          },
          'ds_activeLang': this.dataSlots['ds_activeLang'],
        };

        switch (screenId) {
          default:
            return null;
          case 'tags':
            return (<TagDetailScreen {...screenProps}  />)
          case 'taps':
            return (<TapDetailScreen {...screenProps }  />)
          case 'n404':
            return (<N404Screen {...screenProps} />)
          case 'setupRequired':
            return (<SetupRequiredScreen {...screenProps} />)
          }
      }

    const AppRoute = ({ component: Component, layout: Layout, locStrings, ...rest }) => {
      return (
        <Route {...rest} render={props => (
            <Layout locStrings={locStrings}>
                <Component {...props} locStrings={locStrings}/>
            </Layout>
        )} />
      );
    };

    return (
      <BrowserRouter>
        <Switch>
            {/* SSR Routing */}
            <Route path="/tags/:tagId" component={ (props) => {
              const baseUrl = (window.location.hostname === 'localhost') ? 'http://localhost:8088' : 'https://pay.strikepay.co';
              const tagId = props.match.params.tagId;
              const queryParams = props.location && props.location.search ? props.location.search : '';
              window.location.href = `${baseUrl}/tags/${tagId}${queryParams}`; 
              return null;
            }}/>

            {/* Admin routes */}
            <AppRoute exact path="/admin/login" layout={AdminLoginLayout} component={AdminLoginScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/dashboard" crumbs={['Admin', 'Dashboard']}  layout={AdminLayout} component={AdminDashboardScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/withdrawals" crumbs={['Admin', 'Withdrawals']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminWithdrawalsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/batches" crumbs={['Admin', 'Batches']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminBatchedPayoutsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/batches/:batchId" crumbs={['Admin', 'Batches', 'Batch']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminBatchedPayoutDetailScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/notifications" crumbs={['Admin', 'Notifications']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminNotificationsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/balances" crumbs={['Admin', 'Balances']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminBalancesScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/webhooks" crumbs={['Admin', 'Webhooks']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminDeveloperScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/sites" crumbs={['Admin', 'Sites']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminAllSitesScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/sites/:siteId" crumbs={['Admin', 'Sites', 'Site']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminSiteScreen} locStrings={this.locStrings} />

            <AdminAppRoute exact path="/admin/clients/:clientId" crumbs={['Admin', 'Client']}  condition={(user) => { return true }} layout={AdminLayout} component={AccountProfileScreen} locStrings={this.locStrings} baseURLs={{manage : '/admin/clients',login : '/admin/login'}} />
            <AdminAppRoute exact path="/admin/clients" crumbs={['Admin', 'Clients']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminClientsScreen} locStrings={this.locStrings} />

            {/* /admin/clients sub routes  */}
            <AdminAppRoute exact path="/admin/clients/:clientId/home" crumbs={['Admin', 'Clients', 'Home']}  condition={(user) => { return true }} layout={AdminLayout} component={AccountProfileScreen} locStrings={this.locStrings} baseURLs={{manage : '/admin/clients',login : '/admin/login'}} />
            <AdminAppRoute exact path="/admin/clients/:clientId/profile" crumbs={['Admin', 'Clients', 'Profile']}  condition={(user) => { return true }} layout={AdminLayout} component={EditClientProfileScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/idverification" crumbs={['Admin', 'Clients', 'ID Verification']}  condition={(user) => { return true }} layout={AdminLayout} component={IDVerificationScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/qrs" crumbs={['Admin', 'Clients', 'QRs']}  condition={(user) => { return true }} layout={AdminLayout} component={QrsClientProfileScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/tags" crumbs={['Admin', 'Clients', 'Tags']}  condition={(user) => { return true }} layout={AdminLayout} component={TagsClientProfileScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/tags/:tagId" crumbs={['Admin', 'Clients', 'Tags']}  condition={(user) => { return true }} layout={AdminLayout} component={TagClientScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/bank" crumbs={['Admin', 'Clients', 'Money']}  condition={(user) => { return true }} layout={AdminLayout} component={BankClientProfileScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/bank/:payoutId" crumbs={['Admin', 'Clients', 'Money', 'Payout']}  condition={(user) => { return true }} layout={AdminLayout} component={ClientPayoutScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/withdrawal/:withdrawalId" crumbs={['Admin', 'Clients', 'Withdrawal']}  condition={(user) => { return true }} layout={AdminLayout} component={ClientWithdrawalScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/bank/:payoutId/payouts" crumbs={['Admin', 'Clients', 'Money', 'Payouts']}  condition={(user) => { return true }} layout={AdminLayout} component={PayoutsBankClientScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/access" crumbs={['Admin', 'Client', 'Access']}  condition={(user) => { return true }} layout={AdminLayout} component={ManageAccessScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/tipme/:tagId" crumbs={['Admin', 'Client', 'Tip Me']}  condition={(user) => { return true }} layout={AdminLayout} component={QRTipmeScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/payme/:tagId" crumbs={['Admin', 'Client', 'Pay Me']}  condition={(user) => { return true }} layout={AdminLayout} component={QRPayMeScreen} locStrings={this.locStrings}  />
            <AdminAppRoute exact path="/admin/clients/:clientId/transactions" crumbs={['Admin', 'Client', 'Transactions']}  condition={(user) => { return true }} layout={AdminLayout} component={DownloadTransactionsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/developer" crumbs={['Admin', 'Developer', 'Home']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminDeveloperScreen} locStrings={this.locStrings}  />

            {/* /admin/clients teams sub routes  */}
            <AdminAppRoute exact path="/admin/clients/:clientId/teams" crumbs={['Home', 'Manage', 'Teams']}  condition={(user) => { return true }} layout={AdminLayout} component={ClientDashboardScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId" crumbs={['Home', 'Manage', 'Teams', 'Team Details']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamProfileScreen} locStrings={this.locStrings} baseURLs={{manage : '/admin/clients',login : '/admin/login'}}/>
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/members/:memberId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Team Members', 'Member Details']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamMemberDetailsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/payouts" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Payouts']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamPayoutsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/payout/:accountId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Payouts']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamPayoutsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/payout/payouts/:memberId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Payouts', 'Member Payouts']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamMemberPayoutsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/tags" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Tags']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamTagsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/tags/:tagId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Tags']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamTagEditScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/qrcode" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'QR Code']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamQRScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/settings" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Settings']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamSettingsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/member-tips" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Member Tips']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamTipScreen} locStrings={this.locStrings} />  
            <AdminAppRoute exact path="/admin/clients/:clientId/teams/:teamId/newsettings" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Settings']}  condition={(user) => { return true }} layout={AdminLayout} component={TeamNewSettingsScreen} locStrings={this.locStrings} />

            {/* /admin/issuers sub routes  */}
            <AdminAppRoute exact path="/admin/issuers/:issuerId/templates" crumbs={['Admin', 'Issuers', 'Templates']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminTemplateTagsScreen} locStrings={this.locStrings} />
            <AdminAppRoute exact path="/admin/issuers/:issuerId/templates/:templateTagId" crumbs={['Admin', 'Issuers', 'Templates']}  condition={(user) => { return true }} layout={AdminLayout} component={AdminTemplateTagScreen} locStrings={this.locStrings} />

            {/* Anon routes */}
            <AppRoute path="/claim/:tagId" layout={AnonLayout} component={BeginClaimScreen} locStrings={this.locStrings} />
            <AppRoute path="/qr/:tagId" layout={AnonLayout} component={QRDetailScreen} locStrings={this.locStrings} />

            {/* Client routes */}
            <AppRoute path="/client/login" layout={ClaimLayout} component={ClientLoginScreen} locStrings={this.locStrings} />
            <AppRoute path="/client/signup" layout={ClaimLayout} component={ClientSignupScreen} locStrings={this.locStrings} />

            <ConstrainedAppRoute exact path="/client/verify-email" crumbs={['Home', 'Claim', 'Tag']}  condition={(user) => { return true }} layout={ClaimLayout} component={ClientVerifyEmailScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/email-verified" crumbs={['Home', 'Claim', 'Tag']}  condition={(user) => { return true }} layout={ClaimLayout} component={ClientEmailVerifiedScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/completeSignup/:clientId" crumbs={['Home', 'Signup', 'Complete']} condition={(user) => { return true }} layout={AccountProfileLayout} component={CompleteClientSignup} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/claim/:tagId" crumbs={['Home', 'Claim', 'Tag']}  condition={(user) => { return true }} layout={ClaimLayout} component={ConfirmClaimTagScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId" crumbs={['Home', 'Manage']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={AccountProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/home" crumbs={['Home', 'Dashboard']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={AccountProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/profile" crumbs={['Home', 'Manage', 'Profile']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={EditClientProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/idverification" crumbs={['Home', 'Manage', 'ID Verification']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={IDVerificationScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/qrs" crumbs={['Home', 'Manage', 'QRs']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={QrsClientProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/tags" crumbs={['Home', 'Manage', 'Tags']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={TagsClientProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/tags/:tagId" crumbs={['Admin', 'Clients', 'Tags']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={TagClientScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/bank" crumbs={['Home', 'Manage', 'Money']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={BankClientProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/bank-callback" crumbs={['Home', 'Manage', 'Money']}  condition={(user) => { return true }} layout={ClaimLayout} component={BankClientRedirectScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/bank/:payoutId" crumbs={['Home', 'Manage', 'Money', 'Payout']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={ClientPayoutScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/withdrawal/:withdrawalId" crumbs={['Home', 'Manage', 'Withdrawal']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={ClientWithdrawalScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/bank/:payoutId/payouts" crumbs={['Home', 'Manage', 'Money', 'Payouts']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={PayoutsBankClientScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/order/:skuId" crumbs={['Home', 'Manage', 'Ordering']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={SkuProductDisplayPage} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/tipme/:tagId" crumbs={['Home', 'Manage', 'Get Tipped']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={QRTipmeScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/payme/:tagId" crumbs={['Home', 'Manage', 'Take Payment']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={QRPayMeScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/access" crumbs={['Home', 'Manage', 'Access']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={ManageAccessScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/accessInvite/:invitingClientId/:inviteId" crumbs={['Home', 'Manage', 'Access']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={AccessInviteScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teamInvites/:teamId/:inviteUuid" crumbs={['Home', 'Manage', 'Access']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={TeamInviteScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/transactions" crumbs={['Home', 'Manage', 'Transactions']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={DownloadTransactionsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/developer" crumbs={['Home', 'Manage', 'Developer']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={ClientDeveloperScreen} locStrings={this.locStrings} />

            {/* Team routes */}
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams" crumbs={['Home', 'Manage', 'Teams']}  condition={(user) => { return true }} layout={AccountProfileLayout} component={ClientDashboardScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId" crumbs={['Home', 'Manage', 'Teams', 'Team Details']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamProfileScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/members/:memberId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Team Members', 'Member Details']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamMemberDetailsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/payouts" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Payouts']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamPayoutsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/payout/:accountId" crumbs={['Home', 'Manage', 'Teams', 'Payout', 'Currency']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamPayoutsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/payout/payouts/:memberId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Payouts', 'Member Payouts']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamMemberPayoutsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/member-tips" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Member Tips']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamTipScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/tags" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Tags']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamTagsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/tags/:tagId" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Tags']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamTagEditScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/qrcode" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'QR Code']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamQRScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/settings" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Settings']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamSettingsScreen} locStrings={this.locStrings} />
            <ConstrainedAppRoute exact path="/client/manage/:clientId/teams/:teamId/newsettings" crumbs={['Home', 'Manage', 'Teams', 'Team Details', 'Settings']}  condition={(user) => { return true }} layout={TeamLayout} component={TeamNewSettingsScreen} locStrings={this.locStrings} />

            {/* Customer routes */}
            <Route path="/team-tipping/:teamId/:tagId/:tapId" render={(props) => {
              return makeElementForScreen('n404', props, true, true);
            }} />
            <Route path="/v1/tags/:tagId/taps/:tapId" render={(props) => {
              return makeElementForScreen('n404', props, true, true);
            }} />
            <Route path="/v1/tags/:tagId" render={(props) => {
              return makeElementForScreen('tags', props, true, true);
            }} />
            <Route path="/setup/:tagId" render={(props) => {
              return makeElementForScreen('setupRequired', props, true, true);
            }} />
            <Route path="/404" status={404} render={(props) => {
              return makeElementForScreen('n404', props, true, true);
            }} />

            {/* Legacy route support (redirects) */}
            <Route exact path="/client/manage/:clientId/teams/:teamId/transactions" render={(props) => {
              return <Redirect 
                from="/client/manage/:clientId/teams/:teamId/transactions"
                to={{
                  pathname: `/client/manage/${props.match.params.clientId}/teams/${props.match.params.teamId}`,
                  state: { fromPath: props.location ? props.location.pathname : null }
                }}
              />
            }} />

            <Route exact path="/admin/clients/:clientId/teams/:teamId/transactions" render={(props) => {
              return <Redirect 
                from="/admin/clients/:clientId/teams/:teamId/transactions"
                to={{
                  pathname: `/admin/clients/${props.match.params.clientId}/teams/${props.match.params.teamId}`,
                  state: { fromPath: props.location ? props.location.pathname : null }
                }}
              />
            }} />

            <Redirect exact from="/" to="/client/login" />

            {/* Wildcard */}
            <Route path="*" render={(props) => { 
              return <Redirect 
                from="*"
                to={{
                  pathname: "/404",
                  state: { fromPath: props.location ? props.location.pathname : null }
                }}
              />
            }} />
        </Switch>
      </BrowserRouter>
    );
  }
}
