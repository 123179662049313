import ClientGenericTransactionsModel from './ClientAvailableTransactionsModel';

const isLocalHost = window.location.hostname === 'localhost';
const modelsList = ['available', 'pending', 'others'];
const withoutOthersModelsList = ['available', 'pending'];

export default class ClientAllTransactionsModel {

  constructor({ clientId, dateRange, onUpdate, useModelsList, isWithoutOthers }) {
    this.onUpdate = onUpdate;
    this.clientId = clientId;
    this.dateRange = dateRange;
    this.models = {};
    this.modelsList = isWithoutOthers ? withoutOthersModelsList : modelsList;
    this.modelsList = useModelsList || this.modelsList;
    this.onStop();
  }

  onStart() {
    this.pendingTxns = [];
    this.availableTxns = [];
    this.availableOthersTxns = [];
    this.dataArray = [];
    this.updatesReadyToUse = { pending: false, available: false, others: false };
  }

  onStop() {
    this.pendingTxns = [];
    this.availableTxns = [];
    this.availableOthersTxns = [];
    this.dataArray = [];
    this.updatesReadyToUse = { pending: false, available: false, others: false };
  }

  isUpdatesReadyToUse = () => {
    // check that the modelsList is all true
    const isReady = this.modelsList.every((model) => this.updatesReadyToUse[model]);
    return isReady;
    // return this.updatesReadyToUse.pending && this.updatesReadyToUse.available && this.updatesReadyToUse.others;
  }

  start() {
    this.stop();
    this.onStart();
    const { clientId, dateRange } = this;
    // this.pendingModel = new ClientGenericTransactionsModel({
    //   clientId, status: 'pending',
    //   callbackAll: (data) => this.onUpdatePending(data),
    // });
    // this.availableModel = new ClientGenericTransactionsModel({
    //   clientId, status: 'available',
    //   callbackAll: (data) => this.onUpdateAvailable(data),
    // });
    // this.availableOtherModel = new ClientGenericTransactionsModel({
    //   clientId, status: 'available',
    //   callbackAll: (data) => this.onUpdateAvailable(data),
    //   isOthers: true,
    // });
    // this.pendingModel.observeAllWithDates(dateRange, (data) => this.onUpdateDataSource(data, 'pending'));
    // this.availableModel.observeAllWithDates(dateRange, (data) => this.onUpdateDataSource(data, 'available'));
    // this.availableOtherModel.observeAllWithDates(dateRange, (data) => this.onUpdateDataSource(data, 'others'));

    this.models = {};
    this.modelsList.forEach((model) => {
      const status = model === 'others' ? 'available' : model;
      this.models[model] = new ClientGenericTransactionsModel({
        clientId, 
        status,
        callbackAll: (data) => this.onUpdateDataSource(data, model),
        isOthers: model === 'others',
      });
      this.models[model].observeAllWithDates(dateRange, (data) => this.onUpdateDataSource(data, model));
    });
  }

  stop() {
    this.onStop();
    // if (this.pendingModel) {
    //   this.pendingModel.stop();
    // }
    // if( this.availableModel) {
    //   this.availableModel.stop();
    // }
    // if (this.availableOtherModel) {
    //   this.availableOtherModel.stop();
    // }

    this.modelsList.forEach((model) => {
      if (this.models[model]) {
        this.models[model].stop();
      }
    });
  }

  onUpdateDataSource = (data, source) => {
    this.updatesReadyToUse[source] = true;
    switch (source) {
      case 'pending':
        this.onUpdatePending(data);
        break;
      case 'available':
        this.onUpdateAvailable(data);
        break;
      case 'others':
        this.onUpdateAvailableOthers(data);
        break;
    }
  }

  onUpdatePending = (data) => {
    this.pendingTxns = data;
    this.updateDataSource();
  }

  onUpdateAvailable = (data) => {
    this.availableTxns = data;
    this.updateDataSource();
  }

  onUpdateAvailableOthers = (data) => {
    this.availableOthersTxns = data;
    this.updateDataSource();
  }

  async updateDataSource() {
    if (!this.isUpdatesReadyToUse()) {
      return;
    }

    const { pendingTxns, availableTxns, availableOthersTxns } = this
    let data = [ ...pendingTxns, ...availableTxns, ...availableOthersTxns ];
    // data = data.filter((v) => !(['tap-made-available', 'tap-refunded'].includes(v.action)));
    data.sort((a, b) => (b.activityAt - a.activityAt) == 0 ? (a.newBalance - b.newBalance) :  (b.activityAt - a.activityAt));
    this.dataArray = [ ...data ];
    if(this.onUpdate) {
      this.onUpdate(this.dataArray);
    }
  }

}
