import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class AllSitesModel {

  constructor({ isAdmin, onUpdate, limit, status, type }) {
    this.onUpdate = onUpdate;
    this.isAdmin = isAdmin || true;
    this.status = status;           // status of site: 'created', 'live', 'paused', etc. 
    this.type = type;               // type of site: 'review', 'booking', 'affiliate', 'ad', etc.
    this.limit = limit || 1000;
    this.sites = [];
    this.sitesById = {};
    const db = firebase.firestore();
    const sitesRef = db.collection('sites');
    this.collection = sitesRef;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observer) {
      this.observer();
      this.observer = null;
    }
    this.sites = [];
    this.sitesById = {};
  }

  start = () => {
    if (this.observer) { this.observer = null; }

    this.stop();

    let query = this.collection;
    if (this.status) {
      query = query.where('status', '==', this.status);
    }
    if (this.type) {
      query = query.where('type', '==', this.type);
    }
    // query = query.orderBy('createdAt', 'desc');
    query = query.limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      this.sites = [];

      if (querySnapshot.empty) {
        this.handleUpdateSites();
      }

      querySnapshot.docs.reverse().forEach(doc => {
        const data = doc.data();
        const id = doc.id;
        const full_data = { ...data, id };
        this.sites.push(full_data);
      });

      this.handleUpdateSites();
    }, err => {
      console.log(`Encountered a 'sites' model error: ${err}`);
      this.handleUpdateSites();
    });
  }

  // Place 'created' reviews first, then sort by displayName
  sortSites = () => {
    this.sites = this.sites.sort((a, b) => {
      const { status: statusA, displayName: nameA } = a;
      const { status: statusB, displayName: nameB } = b;

      if (statusA !== statusB) {
        if (statusA === 'created') {
          return -1;
        } else if (statusB === 'created') {
          return 1;
        }
      }

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // names must be equal
    });
  }

  handleUpdateSites = () => {
    this.sortSites();
    const reviews = this.sites || [];
    if (this.onUpdate) {
      this.onUpdate(reviews);
    }
  }

  createSite = async (data) => {
    delete data.id;
    const newReview = await this.collection.add({
      ...data,
      status: 'created',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    return newReview;
  }

  updateSite = async (id, data) => {
    delete data.id;
    const siteRef = this.collection.doc(id);
    await siteRef.set({
      ...data,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });
  }

}
