import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Select,
  Button,
  Collapse,
  Checkbox,
} from 'antd';
import { QuestionCircleOutlined, CaretRightOutlined } from '@ant-design/icons';

import { FormItemLayout, TailFormItemLayout } from '../../utils/Constants';
import { supportedCurrencies, defaultCurrencyByCountry } from '../../utils/CurrencyUtils';

import { InitiateTrueLayerForm } from './InitiateTrueLayerForm';
import AddPayoutMoneyJarBanner from './AddPayoutMoneyJarBanner';

const { Option } = Select;
const { Panel } = Collapse;

const sampleBanks = {
  US: 'BofA, Chase, etc.',
  UK: 'Lloyds, RBS, Monzo, etc.',
  GB: 'Lloyds, RBS, Monzo, etc.',
  IE: 'Revolut, AIB, Bank of Ireland, AIB, N26, etc.',
  LT: 'Revolut',
  FR: 'BNP Paribas, Societe Generale, etc.',
  IT: 'Intesa Sanpaolo, UniCredit, etc.',
  DE: 'Commerzbank, Deutsche Bank, etc.',
  ES: 'BBVA, Santander, etc.',
};

const countriesSEPA = ['IE', 'FR', 'ES', 'IT', 'DE', 'LT'];

const selectedCountryListDefault = {
  // "US" : "United States",
  "IE" : "Ireland",
  "GB" : "United Kingdom",
  "FR" : "France",
  "ES" : "Spain",
  "IT" : "Italy",
  "DE" : "Germany",
};

let currencies = {
  ...supportedCurrencies,
};

const defaultCurrency = {
  ...defaultCurrencyByCountry,
};

const limitCountries = {
  "US" : "United States",
};

const limitCurrencies = {
  "USD" : currencies["USD"],
};

const otherCurrencies = {
  "EUR" : currencies["EUR"],
  "GBP" : currencies["GBP"],
};

const limitedCountries = ['US'];

const holderNameRegEx =  () => { 
  const fullNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū]+(?:['-]?[A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū]+)*(?:\s+[A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū]+(?:['-]?[A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū]+)*)+$/;
  // Match full names with special characters and spaces and hyphens and apostrophes
  return new RegExp(fullNameRegex);
}

const holderNameTest = holderNameRegEx();

const businessNameRegEx = () => {
  const businessNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū0-9]+(?:[ '&.,@#-][A-Za-zÀ-ÖØ-öø-ÿĀ-žā-žū-ū0-9]+)*(?:\.)?$/;
  // Match business names with special characters and spaces and hyphens and apostrophes
  return new RegExp(businessNameRegex);
}

const businessNameTest = businessNameRegEx();

export const EditBankAccountForm = ({ callback, bank, edit, trueLayerAttemptedAt, isTrueLayerEnabled, clientId, payouts, client, ...rest }) => {
  const { country } = client || {};
  const isCompany = bank ? !!bank.isCompany : (client && client.isCompany);
  let bankCountry = bank && bank.country; 

  const defaultBankCountry = bankCountry || country || "IE";
  const defaultCurrencyValue = defaultCurrency[defaultBankCountry] || "EUR";
  const isLimitedByCountry = limitedCountries.includes(country); // MARK: USD limited to US
  const countryOptions = isLimitedByCountry ? limitCountries : selectedCountryListDefault;
  const currenciesOptions = isLimitedByCountry ? limitCurrencies : otherCurrencies;
  
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState(defaultBankCountry);
  const [isCompanyChecked, setIsCompanyChecked] = useState(isCompany);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrencyValue);

  if (!bank && client) {
    bank = {
      isCompany: client.isCompany,
      holderName: client.displayName,
      country: defaultBankCountry,
      currency: defaultCurrencyValue,
      address: {
        country: defaultBankCountry,
      },
      hide: false,
    };
  }

  const onFinish = (values) => {
    values["address"]["address-line-two"] = values["address"]["address-line-two"] || "";
    values["isCompany"] = !!isCompanyChecked;
    if (callback) {
      callback(values);
    }
  };

  const onSelectCountry = (value) => {
    setSelectedCountry(value);
  };

  const onSelectCurrency = (value) => {
    setSelectedCurrency(value);
  };

  let getPanelForTrueLayer = (<Panel header={<span style={{ fontWeight: 600, fontSize: '17px' }}>Seamlessly connect your bank account using TrueLayer</span>} key="1" style={{ fontWeight: 600 }}>

    <InitiateTrueLayerForm bank={bank}
      callback={callback}
      trueLayerAttemptedAt={trueLayerAttemptedAt}
      clientId={clientId}
    />
  </Panel>);


  const GetManualBankForm = (
    <Panel header={<span style={{ fontWeight: 600, fontSize: '17px' }}>Manually enter your bank details</span>} key="2" style={{ fontWeight: 600 }}>
      <Form
        {...FormItemLayout}
        form={form}
        name="bankRegister"
        onFinish={onFinish}
        initialValues={bank}
        scrollToFirstError
      >

        <Form.Item
          name="displayName"
          label={
            <span>
              Account Label&nbsp;
              <Tooltip title="A name to help you identify this account when making withdrawals">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: 'Please input a name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            <label className="" style={{ fontWeight: "bold" }}>Account Holder Details</label>
          </div>
        </div>

        { isCompany &&
        <Form.Item
          name="isCompany"
          valuePropName="checked"
          rules={[
            {
              required: false,
            },
          ]}
          {...TailFormItemLayout}
        >
          <Checkbox onChange={(v) => (setIsCompanyChecked(!isCompanyChecked))}>
            Business Account
          </Checkbox>
        </Form.Item>
        }

        {!isCompanyChecked &&
          <Form.Item
            name="holderName"
            label={
              <span>
                Personal Account Holder Name&nbsp;
                <Tooltip title="Name assosciated with bank account; it must match the name of the bank account holder">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Must have first and last names (no trailing spaces)",
                whitespace: true,
                pattern: holderNameTest,
              },
            ]}
          >
            <Input />
          </Form.Item>
        }
        {isCompanyChecked &&
          <Form.Item
            name="holderName"
            label={
              <span>
                Business Account Holder Name&nbsp;
                <Tooltip title="Name assosciated with bank account; it must match the name of the business">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "Enter full business name (no trailing spaces)",
                whitespace: true,
                pattern: businessNameTest,
              },
            ]}
          >
            <Input />
          </Form.Item>
        }
        <Form.Item
          label={
            <span>
              Your Address&nbsp;
              <Tooltip title="The address you registered with your bank">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Input.Group>
            <Form.Item
              name={['address', 'address-line-one']}
              noStyle
              rules={[{ required: true, message: 'Address is required' }]}
            >
              <Input style={{ width: '50%' }} placeholder="Address line 1" />
            </Form.Item>
            <Form.Item
              name={['address', 'address-line-two']}
              noStyle
              rules={[{ required: false, message: 'Street is required' }]}
            >
              <Input style={{ width: '50%' }} placeholder="(Optional) Address line 2" />
            </Form.Item>
            <Form.Item
              name={['address', 'town']}
              noStyle
              rules={[{ required: true, message: 'Town is required' }]}
            >
              <Input style={{ width: '50%' }} placeholder="Town" />
            </Form.Item>
            <Form.Item
              name={['address', 'county']}
              noStyle
              rules={[{ required: true, message: 'County/State is required' }]}
            >
              <Input style={{ width: '50%' }} placeholder="County/State" />
            </Form.Item>
            <Form.Item
              name={['address', 'zip']}
              noStyle
              rules={[{ required: true, message: 'Zip/Postcode is required' }]}
            >
              <Input style={{ width: '50%' }} placeholder="Zip/Postcode" />
            </Form.Item>
            <Form.Item
              name={['address', 'country']}
              noStyle
              rules={[{ required: true, message: 'Country is required' }]}
            >
              <Select
                style={{ width: '50%' }}
                placeholder="Select a country"
                disabled={countryOptions.length <= 1}
              >
                {Object.keys(countryOptions).map(
                  (i) => (
                    <Option key={i} value={i}>{countryOptions[i]}</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <div className="ant-row ant-form-item">
          <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            <label className="" style={{ fontWeight: "bold" }}>Your Bank Details</label>
          </div>
        </div>

        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: 'Please provide the country where the bank is located',
            },
          ]}
        >
          <Select
            placeholder="Select a country"
            onSelect={onSelectCountry}
            disabled={countryOptions.length <= 1}
          >
            {Object.keys(countryOptions).map(
              (i) => (
                <Option key={`bank-ctry-${countryOptions[i]}`} value={i}>{countryOptions[i]}</Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="currency"
          label="Currency"
          rules={[
            {
              required: true,
              message: 'Please input a currrency!',
            },
          ]}
        >
          <Select
            placeholder="Select a currency"
            onSelect={onSelectCurrency}
            disabled={currenciesOptions.length <= 1}
          >
            {Object.keys(currenciesOptions).map(
              (i) => (
                <Option key={`bank-ccy-${currenciesOptions[i]}`} value={i}>{currenciesOptions[i]}</Option>
              ))
            }
          </Select>
        </Form.Item>


        {
          (countriesSEPA.includes(selectedCountry)) &&
          <Form.Item
            name="bic"
            label="BIC"
            rules={[
              {
                required: true,
                message: 'Please input the BIC',
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        {
          (countriesSEPA.includes(selectedCountry)) &&
          <Form.Item
            name="iban"
            label="IBAN"
            rules={[
              {
                required: true,
                message: 'Please input an IBAN',
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        {
          selectedCountry === 'US' &&
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                required: true,
                message: 'Please input your Account Number',
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        {
          selectedCountry === 'US' &&
          <Form.Item
            name="routingNumber"
            label="Routing Number"
            rules={[
              {
                required: true,
                message: "Please input your bank's Routing Number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        { ['UK', 'GB'].includes(selectedCountry) &&
          <Form.Item
            name="sortCode"
            label="Sort Code"
            rules={[
              {
                required: true,
                message: "Please input your bank's Sort Code 99-99-99",
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        { ['UK', 'GB'].includes(selectedCountry) &&
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                required: true,
                message: 'Please input your Account Number',
              },
            ]}
          >
            <Input />
          </Form.Item>
        }

        <Form.Item
          name="bankName"
          label={
            <span>
              Bank Name&nbsp;
              <Tooltip title={`Name of your bank - e.g. ${sampleBanks[selectedCountry]}`}>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Please input your bank's name",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        { isCompanyChecked &&
        <Form.Item
          name="descriptor"
          label={
            <span>
              Custom Payout Reference&nbsp;
              <Tooltip title={'Bank transfer reference'}>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: false,
              message: "Please input a custom reference code",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        }

        <Form.Item {...TailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {(edit) ? "Save" : "Create New"}
          </Button>
        </Form.Item>
      </Form>
    </Panel>
  );

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={isTrueLayerEnabled ? ['1'] : ['2']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{ backgroundColor: '#FFFFFF' }}
      accordion={true}
    >
      <AddPayoutMoneyJarBanner clientId={clientId} onClickAdd={callback} payouts={payouts} />
      {isTrueLayerEnabled && getPanelForTrueLayer}
      {GetManualBankForm}
    </Collapse>
  );




};
