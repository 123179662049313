import { useState, useEffect } from 'react';

import ClientAnnouncementsModel from './ClientAnnouncementsModel';

export default function usePublicAnnouncements() {
    const [announcements, setAnnouncements] = useState(null);

    const recordView = async (id) => {
        const updateModel = new ClientAnnouncementsModel({ });
        await updateModel.trackAnnouncementView({ id });
    }

    useEffect(() => {
        const onUpdate = async (list) => {
            setAnnouncements(list);
        };


        const model = new ClientAnnouncementsModel({
            onUpdate,
        });

        model.start();

        return function cleanup() {
            model.stop();
        };

    },
        []
    );

    return [announcements, recordView];
}
