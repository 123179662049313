import path from 'path';

import React, {Component} from 'react';
import {PageHeader, Tag, Button} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import firebase from 'firebase';

import {TeamsTable} from '../../../components/client/TeamsComponents';
import {EditTeamForm} from '../../../components/client/EditTeamForm';
import StrikeClientModel from '../../../models/StrikeClientModel';
import StrikeTeamsModel from '../../../models/StrikeTeamsModel';
import CenteredSpinner from '../../../components/CenteredSpinner';
import TrackerService from '../../../utils/TrackerService';
import { TeamImageEditor } from '../../../components/client/UploadableImageEditor';

import 'react-image-crop/dist/ReactCrop.css';
import '../../../App.scss';
import 'antd/dist/antd.css';

// MARK: DELETE THIS COMPONENT

export default class TeamsClientProfileScreen extends Component {

    constructor(props) {
        super(props);

        const clientId = this.props.match.params.clientId;
        this.clientId = clientId;
        const isAdmin = !!props.isAdmin;
        this.clientModel = new StrikeClientModel({viewModel: this, clientId, isAdmin});
        this.model = new StrikeTeamsModel({viewModel: this, clientId});

        this.state = {
            clientId: clientId,
            client: null,
            loading: true,
            loadingTeams: true,
            clientNotFound: false,
            mode: 'viewing',
            savingNewTeam: false,
            teamId: props.match.params.teamId,
            isAdmin: isAdmin,
        };
    }

    componentDidMount() {
        this.clientModel.waitForClientData();
    }

    componentWillUnmount() {
        if (this.clientModel) this.clientModel.stop();
        if (this.model) this.model.stop();
    }

    componentDidUpdate() {
    }

    isLoading = () => {
        return this.state.loadingTeams || false;
    }


    foundClient = async (client) => {
        if(client.hasOwnProperty('teamsAllowed') && !client.teamsAllowed){
            this.props.history.push(`/client/manage/${this.state.clientId}`);
        }
        this.setState({
            client,
            loading: false,
            loadingTeams: true,
        });

        await this.model.fetchAllTeamsForClient();
    }

    updatedTeamsDataSource = (teams) => {
        this.setState({
            teamList: teams,
            loadingTeams: false,
        });
    }

    clientNotFound = () => {
        this.setState({
            loading: false,
            clientNotFound: true,
        });
    }

    changeMode = (mode) => {
        if (mode === 'viewing') {
            return 'adding'
        } else if (mode === 'adding') {
            return 'saving'
        } else {
            return 'viewing';
        }
    }

    onClickAdd = () => {
        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        )
    }

    doneSaving = () => {
        this.setState((state) => {
                return {
                    mode: this.changeMode(state.mode)
                }
            }
        );
    }

    internalSave = async (data) => {
        let teamId = await this.model.makeNewTeamObject(data, this.state.client);
        if (this.state.base64Image) {
            await this.uploadTopLogo(teamId)
        }
        this.doneSaving();
        setTimeout(() => {
            this.setState({
                savingNewTeam:false
            });
        }, 9000); //Wait for 9 seconds and then release 'creating your team' hold on screen. Will be used only if team creation in backend exceeds 9 seconds
        this.listenToNewTeamGoingLive(teamId);
    }

    listenToNewTeamGoingLive = async (teamId) => {
        const db = firebase.firestore();
        let newTeamObserverUnsubscribe = db.collection('teams').doc(teamId).onSnapshot(snapshot => {
          if(snapshot.data() && snapshot.data().status && snapshot.data().status === 'live'){
              this.setState({
                teamId,
                savingNewTeam: false
              })
          }

          const isLocalHost = window.location.hostname === 'localhost';
          const isTest = isLocalHost;

          const tracker = new TrackerService({
            isTest,
          });

          const data = {
            teamOwnerClientId: this.state.clientId,
            teamOwnerDisplayName: this.state.client && this.state.client.displayName,
            teamId: this.state.teamId,
          }

          tracker.mixpanelTrack('create_team', data);
        
          if (!isTest) {
            tracker.googleTrack(
              'create_team', data);
          }
          newTeamObserverUnsubscribe();
        });
    }

    onClickSave = async (data) => {
        this.setState((state) => {
                return {
                    savingNewTeam: true,
                    mode: this.changeMode(state.mode)
                }
            }
        );
        await this.internalSave(data);
    }

    onClickCancel = () => {
        this.setState({mode: 'viewing'});
    }

    getButtons = () => {
        if (this.state.mode === 'viewing') {
            return [
                <Button key="1" type="text" shape='circle'
                        icon={<PlusCircleOutlined style={{fontSize: '28px', color: '#070'}}/>}
                        onClick={this.onClickAdd}/>
            ]
        } else if (this.state.mode === 'adding') {
            return [
                <Button key="1" type="danger" onClick={this.onClickCancel}>
                    Cancel
                </Button>,
            ];
        } else {
            return [];
        }
    }

    render() {
        const teamSelected = {}; // this.state.teamSelected;

        if (this.state.mode === 'viewing' && !this.state.loadingTeams && !this.state.savingNewTeam && this.state.client && this.state.teamList) {
            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`All Teams`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ?
                            <Tag color="blue">Adding</Tag> : <Tag color="green">Saving...</Tag>)}
                        onBack={(() => window.history.back())}
                        extra={this.getButtons()}
                    />
                    <TeamsTable
                        condition={() => {
                            return !this.state.loadingTeams
                        }}
                        teams={this.state.teamList}
                        onAddAccount={this.onClickAdd}
                        isAdmin={this.state.isAdmin}
                    />
                </div>
            );
        } else if (!this.state.loadingTeams && this.state.mode === 'adding' && !this.state.savingNewTeam) {
            const { topLogoUrl} = this.state;

            return (
                <div style={{height: '100%', textAlign: 'center'}}>
                    <PageHeader
                        ghost={false}
                        title={`Create New Team`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'adding' ? null :
                            <Tag color="green">Saving...</Tag>)}
                        extra={this.getButtons()}
                    />

                    <TeamImageEditor
                        teamId={this.state.teamId}
                        currentUrl={topLogoUrl}
                        isLoggedInMemberAdmin={this.state.isAdmin}
                    />

                    <EditTeamForm
                        team={teamSelected}
                        callback={this.onClickSave}
                        edit={false}
                    />
                </div>
            );
        } else if ((this.state.loadingTeams && !this.state.savingNewTeam) && this.state.mode === 'viewing' && !this.state.teamList) {
            return (
                <div>
                    <CenteredSpinner loading={this.isLoading()} noLayout={false} />
                </div>
            );
        } else if (this.state.savingNewTeam) {
            return (
                <div>
                    <CenteredSpinner loading={this.state.savingNewTeam} noLayout={false} />
                </div>
            );
        } else {
            return (
                <div style={{height: '100%'}}>
                    <PageHeader
                        ghost={false}
                        title={`All Teams`}
                        tags={this.state.mode === 'viewing' ? null : (this.state.mode === 'editing' ?
                            <Tag color="blue">Add new</Tag> : <Tag color="green">Saving...</Tag>)}
                        onBack={(() => window.history.back())}
                        extra={this.getButtons()}
                    />
                </div>
            );
        }


    }
}

