function paidInListByCcy(activities, currency) {
  return activities.filter(
    (activity) => 
      (activity.currency === currency && activity.subType === 'transfer-in')
  );
}

function membersInListByCcy(activities, currency) {
return activities.filter(
  (activity) => 
    (activity.currency === currency && activity.direction === 'in' && activity.type === 'tap' && activity.clientType === 'individual')
);
}

function pooledInListByCcy(activities, currency) {
return activities.filter(
  (activity) => 
    (activity.currency === currency && activity.direction === 'in' && activity.type === 'tap' && activity.clientType === 'team')
);
}

function paidOutListByCcy(activities, currency) {
  return activities.filter(
    (activity) => 
      (activity.currency === currency && activity.subType === 'transfer-out')
  );
}

function refundMemberListByCcy(activities, currency) {
  return activities.filter(
    (activity) =>
      activity.currency === currency && activity.direction === 'out' && activity.amount > 0 && activity.clientType === 'individual'
  );
}

function refundPooledListByCcy(activities, currency) {
return activities.filter(
  (activity) =>
    activity.currency === currency && activity.direction === 'out' && activity.amount > 0 && activity.clientType === 'team'
);
}

function paidInAmount(activities, currency) {
const paidIn = paidInListByCcy(activities, currency);
return {
  amount: paidIn.reduce((acc, activity) => acc + (activity.netAmount), 0),
  count: paidIn.length,
};
}

function paidOutAmount(activities, currency) {
  const paidOut = paidOutListByCcy(activities, currency);
  return {
    amount: paidOut.reduce((acc, activity) => acc - activity.netAmount, 0),
    count: paidOut.length,
  };
}

function paidMemberAmount(activities, currency) {
const membersIn = membersInListByCcy(activities, currency);
const refundMembers = refundMemberListByCcy(activities, currency);
const memberAmount = membersIn.reduce((acc, activity) => acc + (activity.netAmount), 0);
const refundAmount = refundMembers.reduce((acc, activity) => acc + (activity.netAmount), 0);
return {
  amount: memberAmount - refundAmount,
  count: membersIn.length,
};
}

function paidPoolAmount(activities, currency) {
const pooledIn = pooledInListByCcy(activities, currency);
const refundPooled = refundPooledListByCcy(activities, currency);
const poolAmount = pooledIn.reduce((acc, activity) => acc + (activity.netAmount), 0);
const refundAmount = refundPooled.reduce((acc, activity) => acc + (activity.netAmount), 0);
return {
  amount: poolAmount - refundAmount,
  count: pooledIn.length,
};
}

function aggregateActivitiesByCurrency(activities) {
  let summaries = {};

  if (!activities) {
      return summaries;
  }

  // List of unique currencies in activities array
  const currencies = activities.map((activity) => activity.currency).filter((value, index, self) => self.indexOf(value) === index);
  currencies.forEach((currency) => {
      summaries[currency] = { currency, in: 0, out: 0, members: 0, pool: 0 };
      summaries[currency].paidIn = paidInAmount(activities, currency);
      summaries[currency].paidOut = paidOutAmount(activities, currency);
      summaries[currency].members = paidMemberAmount(activities, currency);
      summaries[currency].pool = paidPoolAmount(activities, currency);
  });

  return { byCurrency: summaries };
}

function aggregateActivities(activities) {
  const { byCurrency } = aggregateActivitiesByCurrency(activities);
  return {
    byCurrency,
  }
}

export default aggregateActivities;
