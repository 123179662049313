import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';

import CenteredSpinner from '../CenteredSpinner';

import { DownloadTapsReport, DownloadTransactionsReport, SwitchBigRedCloudExport } from './AccountProfileComponents';
import { RecentActivitiesTable } from './RecentActivitiesComponents';
import { useClientDetails } from './useClientDetails';
import { useRecentActivities } from './useRecentActivities';

const bigredTextWhen = {
  off: 'Toggle export format',
  on: 'Toggle export format',
};

const AccountTransactionsSettings = ({ clientId, client, isAdmin, showBigRedBook, onUpdateDates, onUpdateMode }) => {
  const showBrcCTA = showBigRedBook ? bigredTextWhen.on : bigredTextWhen.off;

  const updatedTapsRange = ({ startDate, endDate }) => {
    onUpdateDates && onUpdateDates({ startDate, endDate });
  }

  const toggleExportMode = () => {
    onUpdateMode && onUpdateMode();
  }

  return (
    <div>
      {clientId && client &&
        <Row align='center' direction='vertical' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col>
            {true &&
              <SwitchBigRedCloudExport
                clientId={clientId}
                showBrcCTA={showBrcCTA}
                toggleExportMode={toggleExportMode}
                isAdmin={isAdmin}
                isChecked={showBigRedBook}
              />
            }
            {showBigRedBook &&
              <DownloadTransactionsReport
                client={client}
                clientId={clientId}
                updatedTapsRange={updatedTapsRange}
              />
            }
            {!showBigRedBook &&
              <DownloadTapsReport
                client={client}
                clientId={clientId}
                updatedTapsRange={updatedTapsRange}
              />
            }
          </Col>
        </Row>
      }
    </div>
  );
};

// /client/manage/profile
const AccountTransactionsBody = ({ isAdmin, clientId, locStrings }) => {
  const defaultDateRange = { startDate: null, endDate: null };
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [isShowBigRedBook, setIsShowBigRedBook] = useState(true);
  const recentActivies = useRecentActivities(clientId, dateRange.startDate, dateRange.endDate);
  const client = useClientDetails(clientId);

  const isLoading = !client || !recentActivies;

  const updatedTapsRange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setDateRange({ startDate, endDate });
    }
  }

  const toggleExportMode = (checked) => {
    setIsShowBigRedBook(!isShowBigRedBook);
    setDateRange(defaultDateRange);
  }

  return (
    <div>
      <AccountTransactionsSettings
        clientId={clientId}
        client={client}
        isAdmin={isAdmin}
        showBigRedBook={isShowBigRedBook}
        onUpdateDates={updatedTapsRange}
        onUpdateMode={toggleExportMode}
      />
      <CenteredSpinner loading={isLoading} />
      { dateRange.startDate && dateRange.endDate && client &&
        <RecentActivitiesTable
          condition={() => { return !isLoading }}
          locStrings={locStrings}
          client={client}
          isAdmin={isAdmin}
          showDates={true}
          recentActivities={recentActivies}
        />
    }
    </div>
  );
};

export default AccountTransactionsBody;
