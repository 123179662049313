import React, { useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
  Tooltip,
  Divider,
} from 'antd';
import {
  QuestionCircleOutlined,
} from '@ant-design/icons';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import {
  DefaultTeamPermissions, AdminTeamPermissions, FormItemLayout, TailFormItemLayout,
} from '../../utils/Constants';

import {
  tailFormItemLayout,
  TeamEditReviewDetailsFormItem,
} from './EditTeamForm';

export const EditTeamReviewSettingsForm = ({ onUpdated, team, teamId, ...rest }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const capturedValues = { ...values };
    const successReviewDetails = capturedValues.reviewDetails ? capturedValues.reviewDetails.success : null;
    if (successReviewDetails) {
      capturedValues.reviewDetails.success = {
        url: successReviewDetails.url || null,
        title: successReviewDetails.title || null,
        button: successReviewDetails.button || null,
      };
    }
    if (onUpdated) {
      onUpdated(capturedValues);
    }
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamNameSettingsForm"
      onFinish={onFinish}
      initialValues={{ ...team }}
      scrollToFirstError
    >
      <Input.Group style={{ textAlign: 'left' }}>
        <TeamEditReviewDetailsFormItem team={team} />
      </Input.Group>
      <Form.Item
        {...tailFormItemLayout}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: 'auto', maxWidth: '325px' }}
        >
          {"Save Changes"}
        </Button>
      </Form.Item>
    </Form>
  );
}

export const EditTeamReviewSettingsUpdate = ({ teamId, team, onLoading }) => {
  const teamDetailsModel = new StrikeTeamDetailsModel({
    teamId,
  });
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (team) => {
    onLoading && onLoading({ message: `Saving reviews ...` });
    setLoading(true);
    await teamDetailsModel.updateReviewDetails(team);
    setLoading(false);
    onLoading && onLoading({ message: null })
  }

  return (
    <div style={{ marginTop: '12px', marginLeft: '12px' }}>
      <EditTeamReviewSettingsForm
        team={team}
        onUpdated={handleUpdate}
        teamId={teamId}
      />
    </div>
  )
}


/**
 * @review  { 
 *  displayName, description, imageUrl, brand, link, rgbColor, 
 *  status: 'created' | 'live', 
 *  type: 'review',
 *  url, title, button,
 *  campaign
 * }
 * @updatedReview { url, title, button, campaign }
 */
export const EditTeamReviewForm = ({ team, review, onUpdated }) => {
  const [form] = Form.useForm();

  const { displayName, description, imageUrl, brand, link, rgbColor, id, tid, sid } = review;
  const { url, title, button } = review;

  const onFinish = (values) => {
    const capturedValues = { ...review, ...values };
    capturedValues.url = capturedValues.url || null;
    capturedValues.title = capturedValues.title || null;
    capturedValues.button = capturedValues.button || null;
    capturedValues.campaign = capturedValues.campaign || null;
    if (onUpdated) {
      onUpdated(capturedValues);
    }
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamEditReview"
      onFinish={onFinish}
      initialValues={{ ...review }}
      scrollToFirstError
    >
      <>
        <div className='card'>
          <div className="card-body">
            <div className='card-title h3'>
              {imageUrl && <img className='rounded' src={imageUrl} alt={displayName} style={{ width: '60px', height: '60px' }} />}
              {displayName || 'Add Review'}
            </div>
            {description && <p className='card-text'>{description}</p>}
            {link && <a href={link} className="card-link">{link}</a>}
            {link && <div className='card-subtitle'>{'Use this website to find out how to get your review site link'}</div>}

            <Divider />

            <Form.Item
              name={["url"]}
              label={
                <span>
                  Button link&nbsp;
                  <Tooltip title="Enter a review site link">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ width: '100%', textAlign: 'left', marginBottom: '5px' }}
              rules={[
                {
                  required: false,
                  message: 'Enter your url',
                  whitespace: true
                },
                {
                  validator: (_, value) =>
                    ((/^$|^(https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[a-zA-Z0-9-._~:\/?#[@\]!$&'()*+,;=]*)?)?$|^$/.test(value || ''))) ?
                      Promise.resolve() :
                      Promise.reject('Please enter a valid url'),
                },
              ]}
            >
              <Input
                maxLength={140} style={{ maxWidth: '425px' }}
                placeholder={'https://www.example.com'}
              />
            </Form.Item>

            <Form.Item
              name={["title"]}
              label={
                <span>
                  Call to action&nbsp;
                  <Tooltip title="Add a call to action">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ width: '100%', textAlign: 'left', marginBottom: '5px' }}
              rules={[
                {
                  required: false,
                  message: 'Enter a short call to action message',
                },
                {
                  validator: (_, value) =>
                    (/^.{0,30}$/.test(value)) ?
                      Promise.resolve() :
                      Promise.reject('Please enter a shorter message'),
                },
              ]}
            >
              <Input
                maxLength={30} style={{ maxWidth: '425px' }}
                placeholder={'Want to leave a review? (default message)'}
              />
            </Form.Item>

            <Form.Item
              name={["button"]}
              label={
                <span>
                  Button label&nbsp;
                  <Tooltip title="Add a button label">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ width: '100%', textAlign: 'left' }}
              rules={[
                {
                  required: false,
                  message: 'Enter a short and simple button label',
                  whitespace: false
                },
                {
                  validator: (_, value) =>
                    (/(^(?=.{1,12}$)(\b\w+\b)(\s\b\w+\b)?$)|^$/.test(value)) ?
                      Promise.resolve() :
                      Promise.reject('Please enter a simple button label'),
                },
              ]}
            >
              <Input
                maxLength={12} style={{ maxWidth: '425px' }}
                placeholder={'Review (default button label)'}
              />
            </Form.Item>

            <Form.Item
              name={["campaign"]}
              label={
                <span>
                  Campaign ID&nbsp;
                  <Tooltip title="Add a campaign ID">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              style={{ width: '100%', textAlign: 'left' }}
              rules={[
                {
                  required: false,
                  message: 'Enter a campaign ID for utm tracking',
                  whitespace: false
                },
                {
                  validator: (_, value) =>
                    (/^[a-zA-Z0-9_-]+$/.test(value)) ?
                      Promise.resolve() :
                      Promise.reject('Google only uses numbers, Meta uses letters and numbers'),
                },
              ]}
            >
              <Input
                style={{ maxWidth: '425px' }}
                placeholder={'Enter ID for tracking'}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {'Save Changes'}
              </Button>
            </Form.Item>

          </div>
        </div>
      </>

    </Form>

  );
}
