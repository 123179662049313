import FirebaseSetup from '../FirebaseSetup';

const firebaseSetup = new FirebaseSetup();

export default class WebhookModel {
  static instance() {
    return new WebhookModel();
  }

  async replayWebhook({ eventId }) {
    const firebase = firebaseSetup.getFirebase();
    const manualRetryWebhook = firebase.functions().httpsCallable('notificationService-webhookHandler-manualRetryWebhook');

    let errorMessage = null;
    try {
      await manualRetryWebhook({ webhookEventId: eventId });
    } catch (e) {
      errorMessage = e.toString();
    }
    
    return errorMessage;
  }
}
