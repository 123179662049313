import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Typography, Tooltip, Form } from 'antd';
import { TwitterPicker } from 'react-color';

const fmtRgb = ({ r, g, b }) => `rgb(${r}, ${g}, ${b})`;

export const ColorPickerControl = ({ rgbColor, onColorChange, title }) => {
    const [rgb, setRgb] = useState(rgbColor || fmtRgb({ r: 255, g: 0, b: 152, a: 1 }));
    const [loading, setLoading] = useState(false);

    const handleColorChange = async ({ rgb }) => {
        const formattedColor = fmtRgb(rgb);
        setRgb(formattedColor);
        setLoading(true);
        onColorChange && await onColorChange({ rgb });
        setLoading(false);
    }

    return (
        <>
            { title &&
                <span>
                    Team Color&nbsp;
                    <Tooltip title={title}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            }
            <div className='row justify-content-center' >
                <TwitterPicker
                    triangle="hide"
                    colors={['#FF0098', '#27AE60', ...TwitterPicker.defaultProps.colors, '#FFFFFF', '#000000',]}
                    color={rgb}
                    onChangeComplete={handleColorChange}
                    width='240px'
                />
            </div>
        </>
    );

}


export const ColorPickerControlFormItem = ({ title, rgbColor, onColorChange }) => {
    const [rgb, setRgb] = useState(rgbColor || fmtRgb({ r: 255, g: 0, b: 152, a: 1 }));

    const handleColorChange = ({ rgb }) => {
      onColorChange && onColorChange({ rgb });
      setRgb(fmtRgb(rgb));
    }
  
    return (
      <Form.Item
        name="rgbColor"
        label={
          <span>
            Pick Color&nbsp;
            <Tooltip title={title}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        getValueFromEvent={({ rgb }) => fmtRgb(rgb)}
      >
        <TwitterPicker
          triangle="hide"
          colors={['#FF0098', '#27AE60', ...TwitterPicker.defaultProps.colors, '#FFFFFF', '#000000',]}
          color={rgb}
          onChangeComplete={handleColorChange}
        />
      </Form.Item>
    );
  }
