import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class StrikeNotificationsModel {

  constructor({ onUpdate, clientId, limit }) {
    this.data = [];
    this.onUpdate = onUpdate; // Call back with dict = { data: XXX }
    this.clientId = clientId; // clientId can be null in a limited saet of cases, we ignore those
    this.limit = limit || 100;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.viewModel = null;
    this.data = [];
    if(this.observer) {
      this.observer();
      this.observer = null;
    }
  }

  start = () => {
    const clientId = this.clientId

    if(this.observer) {
      this.observer();
      this.observer = null;
    }

    const db = firebase.firestore();
    const preQuery = db.collection('notifications');
    const queryWithOrWithoutTest = (clientId) ? preQuery.where('clientId', '==', clientId) : preQuery;
    let query = queryWithOrWithoutTest.orderBy('createdAt', 'desc');
    query = query.limit(this.limit);

    this.promisesAll = [];

    this.observer = query.onSnapshot(querySnapshot => {
      this.data = [];

      querySnapshot.forEach(
        doc => {
          let item = doc.data();
          this.data.push( { item, id: doc.id });
        }
      );

      this.didFetchData();
    }, err => {
      console.log(`Encountered a notifications model error: ${err}`);
    });

  }

  async didFetchData() {
    this.updateDataSource();
  }

  async updateDataSource() {
    if(this.onUpdate) this.onUpdate(this.data);
  }

}
