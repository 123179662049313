import StrikeClientTapsModel from './StrikeClientTapsModel';
import StrikeClientTransfersModel from './StrikeClientTransfersModel';
import StrikeClientAutodebitedModel from './StrikeClientAutodebitedModel';

const NUM_MAX_ACTIVITIES = 100;

export default class StrikeClientActivitiesModel {

  constructor({ clientId, includeTestAccounts, onUpdate, isPendingOnly, mode }) {
    this.clientId = clientId;
    this.onUpdate = onUpdate;
    this.includeTestAccounts = includeTestAccounts;
    this.isPendingOnly = isPendingOnly;
    this.mode = mode || "limited"; // "all" or "limited"

    this.recentActivities = [];
    this.taps = [];
    this.transfers = [];
    this.autodebits = [];
  }

  start(dateRange) {
    if (dateRange) {
      this.mode = "all";
    }
    this.dateRange = dateRange;
    this.stop();
    this.startTaps();
    this.startTransfers();
    this.startAutodebits();
  }

  stop() {
    if (this.tapsModel) {
      this.tapsModel.stop();
    }
    if (this.transfersModel) {
      this.transfersModel.stop();
    }
    if(this.autodebitsModel) {
      this.autodebitsModel.stop();
    }

    this.tapsModel = null;
    this.transfersModel = null;
    this.autodebitsModel = null;

    this.isAutodebitLoading = false;
    this.isTapsLoading = false;
    this.isTransfersLoading = false;

    this.recentActivities = [];
    this.taps = [];
    this.transfers = [];
    this.autodebits = [];
  }

  startTaps() {
    this.tapsModel = new StrikeClientTapsModel({ clientId: this.clientId, onUpdate: this.updateTaps, isPendingOnly: this.isPendingOnly });
    this.isTapsLoading = true;
    this.tapsModel.start(this.dateRange);
  }

  updateTaps = (taps) => {
    this.isTapsLoading = false;
    this.taps = taps;
    this.updateDataSource();
  }

  startTransfers() {
    this.transfersModel = new StrikeClientTransfersModel({ clientId: this.clientId, onUpdate: this.updateTransfers, isPendingOnly: this.isPendingOnly });
    this.isTransfersLoading = true;
    this.transfersModel.start(this.dateRange);
  }

  updateTransfers = (transfers) => {
    this.isTransfersLoading = false;
    this.transfers = transfers;
    this.updateDataSource();
  }

  startAutodebits() {
    this.autodebitsModel = new StrikeClientAutodebitedModel({ clientId: this.clientId, onUpdate: this.updateAutodebits, isPendingOnly: this.isPendingOnly });
    this.isAutodebitLoading = true;
    this.autodebitsModel.start(this.dateRange);
  }

  updateAutodebits = (autodebits) => {
    this.isAutodebitLoading = false;
    this.autodebits = autodebits;
    this.updateDataSource();
  }

  async updateDataSource() {
    let recentActivities = [];

    if (!(this.isTapsLoading || this.isTransfersLoading || this.isAutodebitLoading)) {
      recentActivities = [...this.taps, ...this.transfers, ...this.autodebits];
    }

    recentActivities.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
      const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
      return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
    });

    // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
    this.recentActivities = this.mode === "all" ? recentActivities : recentActivities.slice(0, NUM_MAX_ACTIVITIES * 3);

    this.onUpdate && this.onUpdate(this.recentActivities);
  }

}
