import firebase from 'firebase/app';
import moment from 'moment';

function useBooleanOrDefault(bool, defaultValue) {
  return typeof bool === 'boolean' ? bool : defaultValue;
}

function useBooleansOrDefault(bool1, bool2, defaultValue) {
  const valueBool1 = useBooleanOrDefault(bool1, defaultValue);
  const valueBool2 = useBooleanOrDefault(bool2, defaultValue);
  return typeof bool1 === 'boolean' ? valueBool1 : valueBool2;
}

export default class StrikeClientModel {

  constructor({ onClientFound, onClientNotFound, viewModel, clientId, isAdmin }) {
    this.onClientFound = onClientFound;
    this.onClientNotFound = onClientNotFound;
    this.viewModel = viewModel;
    this.clientId = clientId;
    this.isAdmin = !!isAdmin;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerClient) this.observerClient();
    this.observerClient = null;
    if (this.authObserver) this.authObserver();
    this.authObserver = null;
    this.currentUser = null;
  }

  async checkLoggedIn(user, client) {
    let isAdmin = this.isAdmin;
    const db = firebase.firestore();

    if (isAdmin && user) {
      let admins = {};
      const adminIds = ['strike', client.clientId];
      isAdmin = false;

      for (let i in adminIds) {
        const v = adminIds[i];
        const query = db.collection('clients').doc(v).collection('admins').doc(user.uid);
        const admin = await query.get();
        if (admin.exists) {
          const adminData = admin.data();
          isAdmin = isAdmin || (adminData.role === 'Admin');
        }
      }
    } else {
      isAdmin = false;
    }

    let hasClientAccess = false;

    if (!isAdmin && !(user && user.uid === client.ownerId)) {
      const snapshot = await db.collection('users').doc(user.uid).collection('client_roles').get();

      let clientRoles = {};

      snapshot.docs.forEach(doc => {
        clientRoles[doc.id] = doc.data().role;
      });

      hasClientAccess = clientRoles.hasOwnProperty(client.clientId);
    }

    return (isAdmin || (user && user.uid === client.ownerId) || hasClientAccess) ? client : null;
  }

  waitForClientData = async () => {
    if (this.clientId === null) return;
    const clientId = this.clientId;
    this.waitOnLoginBeforeClientData();
  }

  fetchVerificationData = async (clientId, client) => {
    try {
      const db = firebase.firestore();
      const isLocalHost = window.location.hostname === 'localhost';

      if (!client.idVerificationStatus) {
        const initiateIDPalForWebAuthFunction = firebase.functions().httpsCallable('clientService-clientHandler-initiateIDPalForWebAuth');
        let response = await initiateIDPalForWebAuthFunction({ clientId: clientId, is_test: isLocalHost });
        if (response && response.result) {
          if (this.viewModel) this.viewModel.updateVerificationData(response.result.uuid);
        }
      } else {
        let idVerificationDocSnapshot = await db.collection('clients').doc(clientId).collection('idVerifications')
        .where('status', '==', 'uuid-generated')
        .orderBy('updatedAt', 'desc')
        .limit(1)
        .get();

        if (!idVerificationDocSnapshot.empty) {
          let idVerificationDocData = idVerificationDocSnapshot.docs[0].data();
          if (this.viewModel) this.viewModel.updateVerificationData({ uuid: idVerificationDocData.uuid, idVerificationDocData: idVerificationDocData });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  waitOnLoginBeforeClientData() {
    if (this.authObserver) this.authObserver();
    this.currentUser = null;
    this.authObserver = firebase.auth().onAuthStateChanged(currentUser => {
      this.currentUser = currentUser;
      this.fetchClientData();
    });
  }

  fetchClientData = async () => {
    if (this.clientId === null || this.currentUser === null)
      return this.clientNotFound();

    const clientId = this.clientId;

    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }

    const db = firebase.firestore();
    const query = db.collection('clients').doc(clientId);
    this.observerClient = query.onSnapshot(async snap => {
      this.client = null;
      if (snap.exists) {
        let client = { clientId: snap.id, ...snap.data() };
        client = await this.checkLoggedIn(this.currentUser, client);
        if (client) {
          this.client = client;
          this.didFindClient(this.client, this.currentUser);
        } else {
          this.clientNotFound();
        }
      } else {
        this.clientNotFound();
      }
    });

  }

  fetchClientDataOnly = async () => {
    if (this.clientId === null)
      return this.clientNotFound();

    const clientId = this.clientId;

    if (this.observerClient) {
      this.observerClient();
      this.observerClient = null;
    }

    const currentUser = firebase.auth().currentUser;

    const db = firebase.firestore();
    const query = db.collection('clients').doc(clientId);
    this.observerClient = query.onSnapshot(async snap => {
      this.client = null;
      if (snap.exists) {
        let client = { clientId: snap.id, ...snap.data() };
        this.client = client;
        this.currentUser = currentUser;
        this.didFindClient(this.client, this.currentUser);
      } else {
        this.clientNotFound();
      }
    });

  }


  commit = async (data) => {
    if (this.clientId === null) return;
    const clientId = this.clientId;

    const country = data.country || this.client.country || null;
    const displayName = data.displayName || this.client.displayName || "";
    const profileBio = data.profileBio || this.client.profileBio || "";
    const avatar = data.avatar || this.client.avatar;
    const dob = data.dob || this.client.dob;
    const email = data.email || this.client.email || "";
    const employerName = data.employerName || this.client.employerName || "";
    const agreeTerms = data.agreeTerms || this.client.agreeTerms || false;
    const agreedTermsAt = (data.agreedTermsAt && data.agreedTermsAt.toDate()) || (this.client.agreedTermsAt && this.client.agreedTermsAt.toDate()) || (agreeTerms && firebase.firestore.FieldValue.serverTimestamp());
    const phoneNumber = data.phoneNumber || this.client.phoneNumber || "";
    const ownerId = data.ownerId || this.client.ownerId || "";
    const type = data.type || this.client.type || "";
    const idVerificationStatus = data.idVerificationStatus || this.client.idVerificationStatus || "";
    const marketingOptIn = useBooleansOrDefault(data.marketingOptIn, this.client.marketingOptIn, false);
    const marketingOptInAt = (data.marketingOptIn && data.marketingOptInAt && data.marketingOptInAt.toDate()) || (this.client.marketingOptIn && this.client.marketingOptInAt && this.client.marketingOptInAt.toDate()) || (marketingOptIn && firebase.firestore.FieldValue.serverTimestamp()) || (!marketingOptIn && null);

    const categoryCode = data.categoryCode || this.client.categoryCode || null;
    const categoryName = data.categoryName || this.client.categoryName || null;

    const isUseDashboard = data.isUseDashboard || this.client.isUseDashboard || false;
    const kycStatus = data.kycStatus || this.client.kycStatus || 'unverified';

    const isTransferToTeamAllowed = useBooleansOrDefault(data.isTransferToTeamAllowed, this.client.isTransferToTeamAllowed, false);
    const isDeveloper = useBooleansOrDefault(data.isDeveloper, this.client.isDeveloper, false);
    const isNewActivity = useBooleansOrDefault(data.isNewActivity, this.client.isNewActivity, false);
    const shouldNotPauseTags = useBooleansOrDefault(data.shouldNotPauseTags, this.client.shouldNotPauseTags, false);
    const isCompany = useBooleansOrDefault(data.isCompany, this.client.isCompany, false);
    const isShowAds = useBooleansOrDefault(data.isShowAds, this.client.isShowAds, false);
    const isNewTeamSettings = useBooleansOrDefault(data.isNewTeamSettings, this.client.isNewTeamSettings, false);

    const idLast4 = data.idLast4 || this.client.idLast4 || null;

    const foundClient = {
      agreeTerms,
      agreedTermsAt,
      displayName,
      dob,
      email,
      employerName,
      ownerId,
      phoneNumber,
      profileBio,
      type,
      categoryCode,
      categoryName,
      idVerificationStatus,
      isUseDashboard,
      marketingOptIn,
      marketingOptInAt,
      isTransferToTeamAllowed,
      kycStatus,
      isDeveloper,
      isNewActivity,
      shouldNotPauseTags,
      isCompany,
      isShowAds,
      isNewTeamSettings,
      country,
      idLast4,
    };

    // protect against null
    if (avatar) {
      foundClient['avatar'] = avatar;
    }

    this.client = foundClient;

    const db = firebase.firestore();
    const query = db.collection('clients').doc(clientId);

    return await query.set(
      {
        ...foundClient
      },
      { merge: true }
    );
  }

  async didFindClient(client, user) {
    const country = client.country || this.client.country || null;
    const displayName = client.displayName || this.client.displayName || "";
    const profileBio = client.profileBio || this.client.profileBio || null;
    const avatar = client.avatar || this.client.avatar || null;
    const dob = client.dob || this.client.dob || null;
    const email = client.email || this.client.email || null;
    const employerName = client.employerName || this.client.employerName || null;
    const agreeTerms = client.agreeTerms || this.client.agreeTerms || false;
    const agreedTermsAt = client.agreedTermsAt || this.client.agreedTermsAt || null;
    const phoneNumber = client.phoneNumber || this.client.phoneNumber || null;
    const ownerId = client.ownerId || this.client.ownerId || "";
    const type = client.type || this.client.type || "";
    const teamsAllowed = client.hasOwnProperty('teamsAllowed') ? client.teamsAllowed : true;
    const idVerificationAllowed = client.hasOwnProperty('idVerificationAllowed') ? client.idVerificationAllowed : true;
    const dobMoment = dob ? moment(dob.toMillis()) : null;
    const agreedTermsAtMoment = agreedTermsAt ? moment(agreedTermsAt.toMillis()) : null;
    const idVerificationStatus = client.idVerificationStatus || this.client.idVerificationStatus || null;
    const status = client.status || this.client.status || null;
    const isRestricted = (status === 'paused');
    const recentStatusHistory = client.recentStatusHistory || null;
    const marketingOptIn = client.marketingOptIn || this.client.marketingOptIn || null;
    const marketingOptInAt = client.marketingOptInAt || this.client.marketingOptInAt || null;
    const marketingOptInAtMoment = marketingOptInAt ? moment(marketingOptInAt.toMillis()) : null;

    const categoryCode = client.categoryCode || this.client.categoryCode || null;
    const categoryName = client.categoryName || this.client.categoryName || null;

    const isUseDashboard = client.isUseDashboard || this.client.isUseDashboard || false;
    const isTransferToTeamAllowed = useBooleansOrDefault(client.isTransferToTeamAllowed, this.client.isTransferToTeamAllowed, false);
    const trueLayerAttemptedAt = client.trueLayerAttemptedAt || this.client.trueLayerAttemptedAt || null;
    const isDeveloper = client.isDeveloper || this.client.isDeveloper || false;
    const isNewActivity = client.isNewActivity || this.client.isNewActivity || false;

    const shouldNotPauseTags = client.shouldNotPauseTags || false;
    const isCompany = client.isCompany || false;
    const isShowAds = client.isShowAds || false;
    const isNewTeamSettings = client.isNewTeamSettings || false;

    const idLast4 = client.idLast4 || this.client.idLast4 || null;

    const foundClient = {
      agreeTerms,
      agreedTermsAt: agreedTermsAtMoment,
      avatar,
      displayName,
      dob: dobMoment,
      email,
      employerName,
      ownerId,
      phoneNumber,
      profileBio,
      type,
      categoryCode,
      categoryName,
      teamsAllowed,
      idVerificationAllowed,
      isRestricted,
      status,
      recentStatusHistory,
      idVerificationStatus,
      isUseDashboard,
      trueLayerAttemptedAt,
      marketingOptIn,
      marketingOptInAt: marketingOptInAtMoment,
      isTransferToTeamAllowed,
      isDeveloper,
      isNewActivity,
      shouldNotPauseTags,
      isCompany,
      isShowAds,
      isNewTeamSettings,
      country,
      idLast4,
    };

    this.client = foundClient;
    this.onClientFound ? this.onClientFound(foundClient, user) : this.viewModel.foundClient(foundClient, user);
  }

  clientNotFound() {
    this.onClientNotFound ? this.onClientNotFound() : this.viewModel.clientNotFound();
  }

  pauseOrEnableClient = async () => {
    const db = firebase.firestore();
    const query = db.collection('clients').doc(this.clientId);
    const newStatus = this.client.isRestricted ? 'live' : 'paused';

    let clientRecentStatusHistory = this.client.recentStatusHistory ? this.client.recentStatusHistory : [];
    clientRecentStatusHistory.unshift({
      previousStatus: this.client.status,
      newStatus: newStatus,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedBy: {
        uid: this.currentUser.uid,
        displayName: this.currentUser.displayName ? this.currentUser.displayName : null,
        email: this.currentUser.email ? this.currentUser.email : null,
      }
    });

    if (clientRecentStatusHistory.length > 5) {
      clientRecentStatusHistory.pop();
    }

    let updateObject = { status: newStatus, recentStatusHistory: clientRecentStatusHistory };
    if (newStatus === 'paused' //Start idVerification if a 'live' client has been set to 'paused'
      && (!this.client.idVerificationAllowed || (this.client.idVerificationStatus && this.client.idVerificationStatus !== 'rejected'))//Do not start idverification if client kyc is already rejected
    ) {
      updateObject.idVerificationAllowed = true; //this flag shows KYC Verification button on Account Profile page
    }
    try {
      return await query.set(
        updateObject,
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setTeamsAllowedSetting = async ({ isTeamsAllowed }) => {
    const db = firebase.firestore();
    const query = db.collection('clients').doc(this.clientId);

    try {
      return await query.set(
        {
          teamsAllowed: isTeamsAllowed
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }
}
