import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';
const NUM_MAX_ACTIVITIES = 100;


const allStatuses =  ['pending', 'successful', 'created', 'available_requested', 'available', 'refunded'];
const interStatuses =  ['successful', 'created'];
const availableStatuses =  ['available', 'refunded'];
const pendingStatuses =  ['pending', 'available_requested', 'refunded'];
const defaultStatuses =  ['pending', 'available_requested', 'available', 'refunded'];

export default class StrikeClientTransfersModel {

  constructor({ clientId, onUpdate, isPendingOnly }) {
    this.clientId = clientId;
    this.client = null;
    this.onUpdate = onUpdate;
    this.transfers = [];
    this.transfersById = {};
    this.mode = "all";
    this.statuses = !!isPendingOnly ? pendingStatuses : defaultStatuses;
  }

  getQuery(dateRange, direction) {
    const db = firebase.firestore();
    this.transfers = [];
    const { startDate, endDate } = dateRange || { };

    let query = db.collection('transfers');

    switch (direction) {
      case 'inward':
        query = query.where('toClientId', '==', this.clientId);
        break;
      case 'outward':
        query = query.where('fromClientId', '==', this.clientId);
        break;
      default:
        query = query.where('fromClientId', '==', this.clientId);
        break;
    }

    query = query
      .where('status', '==', 'sent');
    
    if (startDate) {
      query = query.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      query = query.where('createdAt', "<=" , endDate)
    }

    query = query.orderBy('createdAt', 'desc');

    return query;
  }

  updateInwardTransfers() {
    this.isSomeInwardTransfers = true;
    this.updateDataSource();
  }

  updateOutwardTransfers() {
    this.isSomeOutwardTransfers = true;
    this.updateDataSource();
  }

  isBothTransfers() {
    return this.isSomeInwardTransfers && this.isSomeOutwardTransfers;
  }


  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerInwardTransfers) {
      this.observerInwardTransfers();
      this.observerInwardTransfers = null;
    }
    if (this.observerOutwardTransfers) {
      this.observerOutwardTransfers();
      this.observerOutwardTransfers = null;
    }
    this.transfersById = {};
    this.transfers = [];
    this.isSomeInwardTransfers = false;
    this.isSomeOutwardTransfers = false;
  }

  start(dateRange) {
    if (this.clientId === null) return;
    this.stop();
    this.mode = "all";
    this.observeInwardTransfers(dateRange);
    this.observeOutwardTransfers(dateRange);
  }


  observeInwardTransfers(dateRange) {
    const queryInward = this.getQuery(dateRange, 'inward');
    this.observerInwardTransfers = 
      queryInward
      .onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(
          change => {
            if (change.type === 'added' || change.type === 'modified') {
              const transfer = change.doc.data();
              transfer.type = 'transfer';
              transfer.subType = 'transfer-in';
              const id = change.doc.id;
              this.transfersById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
            }

            if (change.type === 'removed') {
              const id = change.doc.id;
              this.transfersById[id] = null;
            }
          }
        );
        this.updateInwardTransfers();
      }, err => {
        console.log(`Inwards transfer query error: ${err}`);
      });
  }

  observeOutwardTransfers(dateRange) {
    let queryOutward = this.getQuery(dateRange, 'outward');
    this.observerOutwardTransfers = 
      queryOutward
        .onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(
              change => {
                if (change.type === 'added' || change.type === 'modified') {
                  const transfer = change.doc.data();
                  transfer.type = 'transfer';
                  transfer.subType = 'transfer-out';
                  const id = change.doc.id;
                  transfer.payoutAmountCents = transfer.payoutAmountCents * -1;
                  this.transfersById[id] = { ...transfer, created: transfer.createdAt, id }; //created added for sorting in same list as taps
                }

                if (change.type === 'removed') {
                  const id = change.doc.id;
                  this.transfersById[id] = null;
                }
              }
          );
          this.updateOutwardTransfers();
        }, err => {
          console.log(`Outwards transfer query error: ${err}`);
        });
  }

  async updateDataSource() {
    const isNotBothTransfers = !this.isBothTransfers();

    // console.log('StrikeClientTransfersModel :: updateDataSource -> isNotBothTransfers', isNotBothTransfers);

    if (isNotBothTransfers) return;

    let recentActivities = [];

    for (let prop in this.transfersById) {
      recentActivities.push(this.transfersById[prop]);
    }

    recentActivities.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
      const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
      return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
    });

    // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
    this.transfers = this.mode === "all" ? recentActivities : recentActivities.slice(0, NUM_MAX_ACTIVITIES);

    this.onUpdate && this.onUpdate(this.transfers);
  }

}
