import firebase from 'firebase/app';
const isLocalHost = window.location.hostname === 'localhost';

export default class AdminTapModel {
  constructor() {
    const db = firebase.firestore();
    this.db = db;
  }

  static instance() {
    return new AdminTapModel();
  }

  async updateTapStatusToAvailableRequested({ tagId, tapId }) {
    if (tagId && tapId) {
      const query = this.db.collection('tags')
        .doc(tagId)
        .collection('taps')
        .doc(tapId);

      const doc = await query.get();

      if(!doc.exists) {
        console.error(`Document not found: ${tagId} ${tapId}`);
        return;
      }

      return await query.set({
        status: 'available_requested',
        availableRequestedAt:  firebase.firestore.FieldValue.serverTimestamp(),
      }, {
        merge: true
      });
    }
  }
}
