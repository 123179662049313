import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';
const NUM_MAX_ACTIVITIES = 100;


const allStatuses =  ['pending', 'successful', 'created', 'available_requested', 'available', 'refunded'];
const interStatuses =  ['successful', 'created'];
const availableStatuses =  ['available', 'refunded'];
const pendingStatuses =  ['pending', 'available_requested', 'refunded'];
const defaultStatuses =  ['pending', 'available_requested', 'available', 'refunded'];

export default class StrikeClientTapsModel {

  constructor({ clientId, onUpdate, isPendingOnly }) {
    this.clientId = clientId;
    this.accounts = [];
    this.client = null;
    this.observerTransactions = [];
    this.onUpdate = onUpdate;

    this.taps = [];
    this.tapsById = {};
    this.mode = "all";
    this.statuses = !!isPendingOnly ? pendingStatuses : defaultStatuses;
  }

  start(dateRange) {
    const { shortDate } = dateRange || { };
    if (shortDate) {
      this.fetchIncompleteTaps(dateRange);
    } else {
      this.fetchAllActivitiesData(dateRange, false);
    }
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    if (this.observerTaps) {
      this.observerTaps();
      this.observerTaps = null;
    }
    if (this.observerShortTaps) {
      this.observerShortTaps();
      this.observerShortTaps = null;
    }

    this.tapsById = {};
  }

  getQueryForTapsForClient(params) {
    const {
      is_test,
      clientId,
      startDate,
      endDate,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    let query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', this.statuses);

    if (startDate) {
      query = query.where('createdAt', ">=" , startDate)
    }
    if (endDate) {
      query = query.where('createdAt', "<=" , endDate)
    }
    query = query.orderBy('createdAt', 'desc');

    return query;
  }

  getShortQueryForTapsForClient(params) {
    const {
      is_test,
      clientId,
      shortDate,
      limit,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    let query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', interStatuses)

    if (shortDate) {
      query = query.where('createdAt', ">=" , shortDate)
    }
    query = query.orderBy('createdAt', 'desc');

    if(limit > 0) {
      query = query.limit(limit);
    }

    return query;
  }

  getQueryForLatestTapsForClient(params) {
    const {
      is_test,
      clientId,
    } = params;

    const db = firebase.firestore();
    const taps = db.collectionGroup('taps');
    const query = taps
      .where('clientId', '==', clientId)
      .where('status', 'in', this.statuses)
      .orderBy('createdAt', 'desc')
      .limit(NUM_MAX_ACTIVITIES);

    return query;
  }

  async fetchLatestActivitiesData(isTapsOnly) {
    if (this.clientId === null) return;

    const isAllQueries = !isTapsOnly;
    const clientId = this.clientId;
    this.stop();

    this.mode = "latest";

    const query = this.getQueryForLatestTapsForClient({
      clientId,
      is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
    });

    this.observeTaps(query);
  }

  async fetchAllActivitiesData(dateRange, isTapsOnly) {
    if (this.clientId === null) return;
    const { startDate, endDate, shortDate } = dateRange || { };

    const isAllQueries = !isTapsOnly;
    const clientId = this.clientId;
    this.stop();

    this.mode = "all";

    const query = this.getQueryForTapsForClient({
      clientId,
      startDate,
      endDate,
      is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
    });

    this.observeTaps(query);
  }

  async fetchIncompleteTaps({ shortDate, limit }) {
    if (shortDate) {
      const clientId = this.clientId;
      const query = this.getShortQueryForTapsForClient({
        clientId,
        is_test: isLocalHost,    // MARK: Only shows test accounts on localhost
        shortDate,
      });
      this.observeShortTaps(query);
    }
  }

  observeTaps(query) {
    this.observerTaps = query.onSnapshot(querySnapshot => {
      this.taps = [];
      querySnapshot.docChanges().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const tap = change.doc.data();
            tap.type = 'tap';
            const id = change.doc.id;
            const full_tap = { ...tap, id };
            this.tapsById[id] = full_tap;
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            delete this.tapsById[id];
          }
        }
      );
      this.updateDataSource();
    }, err => {
      console.log(`Taps query error: ${err}`);
    });
  }

  observeShortTaps(query) {
    this.observerShortTaps = query.onSnapshot(querySnapshot => {
      this.taps = [];
      querySnapshot.docChanges().forEach(
        change => {
          if (change.type === 'added' || change.type === 'modified') {
            const tap = change.doc.data();
            tap.type = 'tap';
            const id = change.doc.id;
            const full_tap = { ...tap, id };
            this.tapsById[id] = full_tap;
          }

          if (change.type === 'removed') {
            const id = change.doc.id;
            delete this.tapsById[id];
          }
        }
      );
      this.updateDataSource();
    }, err => {
      console.log(`Taps query error: ${err}`);
    });
  }


  async updateDataSource() {

    let recentTaps = [];

    for (let prop in this.tapsById) {
      recentTaps.push(this.tapsById[prop]);
    }

    recentTaps.sort((a, b) => {
      const now = new Date();
      const nowString = now.toISOString();
      const aCreated = (a && a.created) ? a.created : nowString;
      const bCreated = (b && b.created) ? b.created : nowString;
      const aDate = (a && a.accountsProcessingStartedAt) ? a.accountsProcessingStartedAt : aCreated;
      const bDate = (b && b.accountsProcessingStartedAt) ? b.accountsProcessingStartedAt : bCreated;
      return (aDate < bDate) ? 1 : ((aDate > bDate) ? -1 : 0);
    });

    // Ensures we don't have lots of taps frontloaded and then every older transfer at the end of the dataset
    this.taps = this.mode === "all" ? recentTaps : recentTaps.slice(0, NUM_MAX_ACTIVITIES);

    this.onUpdate && this.onUpdate(this.taps);
  }

}
