import React, { useState } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Typography, Tooltip } from 'antd';
import { TwitterPicker } from 'react-color';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';

const { Text } = Typography;

const fmtRgb = ({ r, g, b }) => `rgb(${r}, ${g}, ${b})`;

export const TeamEditColorUpdate = ({ teamId, team, rgbColor, onColorChange, onLoading }) => {
    const teamDetailsModel = new StrikeTeamDetailsModel({
        teamId,
    });
    const { teamName } = team || {};

    const [rgb, setRgb] = useState(rgbColor || fmtRgb({ r: 255, g: 0, b: 152, a: 1 }));
    const [loading, setLoading] = useState(false);

    const handleColorChange = async ({ rgb }) => {
        onLoading && onLoading({ message: `Saving 🌈 ...` });
        const formattedColor = fmtRgb(rgb);
        setRgb(formattedColor);
        setLoading(true);
        await teamDetailsModel.updateTeamColor({ rgbColor: formattedColor });
        setLoading(false);
        onColorChange && onColorChange({ rgb });
        onLoading && onLoading({ message: null })
    }

    return (
        <>
            <span>
                Team Color&nbsp;
                <Tooltip title="Choose a color for your team to customise your team's page">
                    <QuestionCircleOutlined />
                </Tooltip>
            </span>

            <div className='row justify-content-center' >
                <TwitterPicker
                    triangle="hide"
                    colors={['#FF0098', '#27AE60', ...TwitterPicker.defaultProps.colors, '#FFFFFF', '#000000',]}
                    color={rgb}
                    onChangeComplete={handleColorChange}
                    width='240px'
                />
            </div>
        </>
    );

}

