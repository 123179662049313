import React, { useState } from 'react';
import { Input, Button, Form, Table, Popconfirm, Space, Tag } from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import MoneyTitle from '../customer/MoneyTitle';
import StrikeTeamModel from '../../models/StrikeTeamModel';

const limits = {
  min: 1,
  max: 100,
  countMin: 2,
  countMax: 5,
};

const TeamEditorPercents = ({ teamId, team, defaultTag, onLoading }) => {
  const { updatedValuesAt, updatedPercentsAt } = team || {};

  const [form] = Form.useForm();
  const [percents, setPercents] = useState(team.percents || []);
  const [newValue, setNewValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const model = new StrikeTeamModel({ teamId });
  const tagColor = '#666';

  // Sort values in ascending order
  const sortedPercents = [...percents].sort((a, b) => a - b);

  const handleSave = async ({ percents }) => {
    setLoading(true);
    onLoading && onLoading({ message: `Saving percentages ...` });
    const sortedPercents = percents.sort((a, b) => a - b);
    await model.saveTeamPercents({ percents: sortedPercents });
    onLoading && onLoading({ message: null });
    setLoading(false);
    setIsChange(false);
  };

  const handleClear = () => {
    setPercents([]);
    setIsChange(true);
  }

  const handleAddPercent = (percent) => {
    setPercents([...percents, percent * 100]); // Store as hundredths of a percent
    setIsChange(true);
  };

  const handleDeletePercent = (percent) => {
    setPercents(percents.filter((p) => p !== percent));
    setIsChange(true);
  };

  const handleFinish = (formData) => {
    const { amount } = formData;
    if (amount) {
      handleAddPercent(parseFloat(amount));
    }
    form.resetFields();
  };

  const isAddDisabled = () => {
    const newValueCent = parseFloat(newValue) * 100;
    const isInValues = percents.includes(newValueCent);
    return percents.length >= limits.countMax || newValue < limits.min || newValue > limits.max || isInValues;
  }

  const onChangeNewValue = (v) => {
    setNewValue(v);
  }

  const isSaveDisabled = () => {
    if (!isChange) {
      return true;
    }
    if (percents.length === 0) {
      return false;
    }
    return percents.length < limits.countMin || percents.length > limits.countMax;
  }

  const columns = [
    {
      title: `Percentages (max: ${limits.countMax})`,
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) =>
        record.type === 'percent' ?
          <div className='row'>
            <div className='col d-flex justify-content-end align-items-center'>
              <Button color={tagColor} style={{ minWidth: '100px', fontWeight: 'bolder', fontSize: '16px' }}>
                <div style={{ height: '24px', textAlign: 'center' }}>
                  {`${(record.amount / 100).toFixed(2)}%`}
                </div>
              </Button>
            </div>
            <div className='col d-flex justify-content-start align-items-center'>
              <Popconfirm
                title={`Are you sure you want to delete?`}
                onConfirm={() =>
                  handleDeletePercent(record.amount)
                }
              >
                <Button type="danger" icon={<MinusCircleOutlined />} >Delete</Button>
              </Popconfirm>
            </div>
            <div className='col-auto'>
            </div>
          </div>
          :
          <div className='row'>
            <div className='col d-flex justify-content-center align-items-center'>
              <Form layout="inline" form={form} onFinish={handleFinish}>
                <Form.Item name="amount">
                  <Input
                    type="number" placeholder="Add value" 
                    min={limits.min} step={0.01} max={limits.max} 
                    onChange={ e => onChangeNewValue(e.target.value) }
                    style={{ minWidth: '120px' }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button 
                    type="primary"
                    htmlType="submit"
                    disabled={isAddDisabled()}
                    icon={<PlusCircleOutlined />}
                    style={{ minWidth: '80px' }}
                  >Add</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
      ,
    },
  ];

  const data = [
    ...sortedPercents.map((p) => ({ key: `percent-${p}`, type: 'percent', amount: p })),
    { key: 'value-add-new', type: 'add', amount: 0 },
  ];

  return (
    <div className="container mt-4">
      <h3>Set up Percentages</h3>

      {percents.length > 0 && (
        <div className="mt-4">
          Percentages override the pre-set values. Please clear all percentages to use values.
        </div>
      )}

      <Table
        className="mt-4"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
      />

      <Button
        type="primary"
        onClick={() => handleSave({ percents })}
        style={{ marginTop: '12px', marginLeft: '-24px' }}
        loading={loading}
        disabled={isSaveDisabled()}
      >
        {'Save Changes'}
      </Button>

      <Button
        type="danger"
        onClick={() => handleClear()}
        style={{ marginTop: '12px', marginLeft: '16px' }}
        loading={loading}
        disabled={percents.length === 0}
        icon={<MinusCircleOutlined />}
      >
        {'Clear All'}
      </Button>

      { updatedPercentsAt && (
        <div className="mt-0">
          <small>Last updated: {updatedPercentsAt.toDate().toLocaleString()}</small>
        </div>
      )}

    </div>
  );
};

export default TeamEditorPercents;
