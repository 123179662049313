import { firebaseSetup } from '../FirebaseSetup';
import TrackerService from '../utils/TrackerService';

export class TransferModel {
  static instance() {
    return new TransferModel();
  }

  async createClientToTeamTransfer({ amount, currency, teamId, clientId, client, accountId, minimumWithdrawalAmount, isTest }) {
    const db = firebaseSetup.getDB();
    let errorMessage;
    let teamDetail;
    const payoutAmountCents = +amount;
    const payoutAmountWhole = +((amount / 100).toFixed(2));

    try {
      const teamDetailDoc = await db.collection('teams').doc(teamId).get();
      teamDetail = teamDetailDoc.data();
      teamDetail.id = teamId;
    } catch (error) {
      console.log('Error fetching team details');
      return 'Error fetching team details';
    }

    if (teamDetail.defaultCurrency !== currency) {
      errorMessage = 'Currency does not match';
      return errorMessage;
    }

    if (payoutAmountCents < minimumWithdrawalAmount) {
      errorMessage = 'Minimum withdrawal amount not met';
      return;
    }

    const tracker = new TrackerService({
      isTest,
    });

    const { displayName } = client;
    const transferType = 'client-to-client';
    const fromClientType = 'client';
    const toClientType = 'team';

    const transferData = {
      fromClientId: clientId,
      accountId,
      fromClientType,

      toTeamId: teamId,
      toClientId: teamDetail.teamClientId,
      currency,
      payoutAmountCents: +payoutAmountCents,
      toClientType,

      transferType,
    };

    const trackData = {
      ...transferData,
      clientId,
      teamId,
      displayName: displayName || null,
      teamName: teamDetail.teamName || null,
      amount: +payoutAmountWhole,
    };


    tracker.mixpanelTrack("client_transfer", {
      distinct_id: clientId,
      ...trackData,
    });

    if (!isTest) {
      tracker.googleTrack(
        "client_transfer",
        {
          ...trackData,
        }
      );
    }

    try {
        await db.collection('transfers').add(transferData);
    } catch (error) {
        console.log(JSON.stringify(error));
        console.log('Transfer request failed.')
    }
  };

}
