import firebase from 'firebase/app';

const isLocalHost = window.location.hostname === 'localhost';

const announcement = {
  headline: 'Welcome to the Strike Platform!',
  message: 'Please check out our new features.',
  status: 'active', // 'paused', 'completed'
  type: 'info', // 'warning', 'error', 'marketing'
  startAt: new Date(), // or null Timestamp
  endAt: new Date(), // or null Timestamp
  createdAt: new Date(), // Timestamp
  updatedAt: new Date(), // Timestamp
  count: 0, // view count
  maxCount: 100, // max view count
};

const collectonName = 'announcements';

export default class ClientAnnouncementsModel {

  constructor({ onUpdate, clientId, teamId, limit }) {
    this.data = [];
    this.onUpdate = onUpdate; // Call back with dict = { data: XXX }
    this.clientId = clientId;
    this.teamId = teamId;
    this.limit = limit || 5;
  }

  stop() {
    // stop all listeners.,.not sure they get droppped when object drops out of scope.
    this.data = [];
    if(this.observer) {
      this.observer();
      this.observer = null;
    }
  }

  start = () => {
    const clientId = this.clientId;
    const teamId = this.teamId;
    const status = 'active';

    if(this.observer) {
      this.observer();
      this.observer = null;
    }

    
    const todayTimestamp = firebase.firestore.Timestamp.now();

    const db = firebase.firestore();
    let topQuery = db.collection(collectonName);
    // use teamId if it exists
    if (teamId) {
      topQuery = db.collection('teams').doc(teamId).collection(collectonName);
    } 
    // clientId overides teamId
    if (clientId) {
      topQuery = db.collection('clients').doc(clientId).collection(collectonName);
    }
    let query = topQuery;
    query = query.where('status', '==', status);
    query = query.where('startAt', '<=', todayTimestamp);
    query = query.orderBy('startAt', 'asc');
    query = query.limit(this.limit);

    this.observer = query.onSnapshot(querySnapshot => {
      this.data = [];

      querySnapshot.forEach(
        doc => {
          let item = doc.data();
          if (item.endAt && item.endAt.toDate() <= todayTimestamp.toDate()) {
            item.status = 'completed';
          } else {
            this.data.push( { ...item, id: doc.id });
          }
        }
      );

      this.didFetchData();
    }, err => {
      console.log(`Encountered a announcements model error: ${err}`);
    });

  }

  async didFetchData() {
    this.updateDataSource();
  }

  async updateDataSource() {
    if(this.onUpdate) this.onUpdate(this.data);
  }

  async copyAnnouncementToSubobject({ id, announcement, teamId, clientId }) {
    if (!announcement) return;

    const { headline, status, message, type, startAt, endAt, maxCount } = announcement || {};
    const useTeamId = teamId || this.teamId;
    const useClientId = clientId || this.clientId;
    const db = firebase.firestore();
    let query;
    if(useTeamId) {
      query = db.collection('teams').doc(useTeamId).collection(collectonName);
    }
    if(useClientId) {
      query = db.collection('clients').doc(useClientId).collection(collectonName);
    }

    // Update a the announcement status for clientId or teamId
    if (query) {
      await query.doc(id).set({ 
        headline, message, type, startAt, endAt, maxCount, status,
        count: 0,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  async updateAnnouncementStatus({ id, status, teamId, clientId }) {
    if (!status) return;
    const useTeamId = teamId || this.teamId;
    const useClientId = clientId || this.clientId;
    const statusAt = `${status}At`;
    const db = firebase.firestore();
    let query;
    if(useTeamId) {
      query = db.collection('teams').doc(useTeamId).collection(collectonName);
    }
    if(useClientId) {
      query = db.collection('clients').doc(useClientId).collection(collectonName);
    }

    // Update a the announcement status for clientId or teamId
    if (query) {
      await query.doc(id).update({ 
        status,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        [statusAt]: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  async updateAnnouncementCountForAll({ id }) {
    const db = firebase.firestore();
    const query = db.collection(collectonName);

    // Update a the announcement status for clientId or teamId
    if (query) {
      await query.doc(id).update({
        count: firebase.firestore.FieldValue.increment(1),
      });
    }
  }

  async updateAnnouncementCountForSubobject({ id, teamId, clientId }) {
    const useTeamId = teamId;
    const useClientId = clientId;
    const db = firebase.firestore();
    let query;
    if(useClientId) {
      query = db.collection('clients').doc(useClientId).collection(collectonName);
    }
    else if(useTeamId) {
      query = db.collection('teams').doc(useTeamId).collection(collectonName);
    }

    // Update a the announcement status for clientId or teamId
    if (query) {
      await query.doc(id).update({
        count: firebase.firestore.FieldValue.increment(1),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  async trackAnnouncementView({ id }) {
    await this.updateAnnouncementCountForAll({ id });
  }

  async trackClientAnnouncementView({ id,clientId }) {
    const useClientId = clientId || this.clientId;
    await this.updateAnnouncementCountForSubobject({ id, clientId: useClientId });
  }

  async trackTeamAnnouncementView({ id, teamId }) {
    const useTeamId = teamId || this.teamId;
    await this.updateAnnouncementCountForSubobject({ id, teamId: useTeamId });
  }

}
