import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useTransferDetails(transferId, status) {
    const [transfer, setTransfer] = useState(null);

    useEffect(() => {
        // Subscribe to the progress collection in Firestore
        if (!transferId) {
            setTransfer(null);
            return;
        }

        const db = firebase.firestore();
        const ref = db.collection('transfers').doc(transferId);

        const unsubscribe = ref.onSnapshot((snapshot) => {
            const transferData = snapshot.data();
            const { status: transferStatus } = transferData;
            if (status && status !== transferStatus) {
                return;
            }
            setTransfer(transferData);
        });

        // Clean up the subscription on component unmount
        return () => {
            unsubscribe();
        };
    },
        [transferId]
    );

    return transfer;
}
