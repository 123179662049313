import React, { useState, useEffect } from 'react';
import {
  CheckCircleFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
  UserSwitchOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import {
  Tag,
  Space,
  Divider,
  List,
  Tabs,
} from 'antd';

import MoneyTitle from '../customer/MoneyTitle';
import { useSessionStorage } from '../../models/useSessionStorage';


/*** 
 * @constant type : 'tap' | 'transfer' - The type of the activity
 * @constant amount : number - The amount of the activity
 * @constant netAmount : number - The net amount of the activity
 * @constant payoutAmountCents : number - The payout amount of the activity
 * @constant direction : 'in' | 'out' - The direction of the activity
 * @constant clientType : 'team' | 'individual' - The type of the client
 * @constant activityType : 'Tip | 'Pay' - The type of the activity
 * @constant subType : 'transfer-in' | 'transfer-out' - The sub type of the activity
***/

const MoneySummaryRow = ({ summary, currency, hidden, header }) => {
  const { paidIn, paidOut, members, pool } = summary;

  const inAmount = paidIn ? paidIn.amount : 0;
  const outAmount = paidOut ? paidOut.amount : 0;
  const membersAmount = members ? members.amount : 0;
  const poolAmount = pool ? pool.amount : 0;

  const hiddenMember = membersAmount === 0;
  const hiddenOut = outAmount === 0;
  const hiddenPool = poolAmount === 0;
  const hiddenIn = inAmount === 0;

  const inCount = paidIn ? paidIn.count : 0;
  const outCount = paidOut ? paidOut.count : 0;
  const membersCount = members ? members.count : 0;
  const poolCount = pool ? pool.count : 0;

  const dataSource = [
    { id: 'ti', hidden: hiddenPool, color: ['blue', 'green'], icon: <GroupOutlined />, amount: poolAmount, count: poolCount, title: ['Team', 'Tip'] },
    { id: 'tt', hidden: hiddenIn, color: ['orange', 'green'], icon: <UserSwitchOutlined />, amount: inAmount, count: inCount, title: ['Member', 'Paid in'] },
    { id: 'tp', hidden: hiddenOut, color: ['orange', 'red'], icon: <UsergroupDeleteOutlined />, amount: -outAmount, count: outCount, title: ['Member', 'Paid out'] },
    { id: 'mi', hidden: hiddenMember, color: ['orange', 'green'], icon: <UserOutlined />, amount: membersAmount, count: membersCount, title: ['Member', 'Tip'] },
  ];


  return <>
    {!hidden &&
      <div className={header ? 'card' : 'no-card'}>
        {header &&
          <div className='card-header'>{header}</div>
        }
        <List
          itemLayout="horizontal"
          pagination={false}
          size="small"
          loading={!summary}
          dataSource={dataSource}
          renderItem={item => (
            <List.Item
              key={`msr-${item.id}-${currency}`}
              actions={[
                <MoneyTitle icon={item.icon} amount={item.amount} currency={currency} digits={2} />,
              ]}
              hidden={item.hidden}
            >
              <List.Item.Meta
                title={
                  <>
                    <Tag>{item.count}x</Tag>
                    <Tag color={item.color[0]}>{item.title[0]}</Tag>
                    <Tag color={item.color[1]}>{item.title[1]}</Tag>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    }
  </>;
};

const MoneySummaryHeader = ({ hidden, onClick, isExpanded }) => {
  return !hidden && (
    <div className='card-header'>
      <div className='float-left'>
        <strong>Summary</strong>
      </div>
      <div className='float-right'>
        {onClick && !isExpanded &&
          <button className='btn' onClick={onClick}>
            <ArrowDownOutlined />
          </button>
        }
        {onClick && isExpanded &&
          <button className='btn' onClick={onClick}>
            <ArrowUpOutlined />
          </button>
        }
      </div>
    </div>
  );
}

export const MovementSummaryView = ({ byCurrency, byWeeks, byDays }) => {
  const [hideLines, setHideLines] = useSessionStorage('teams-hide-summary', false);

  const hidden = byCurrency && Object.keys(byCurrency).length === 0;
  const isHeaderPerRow = byCurrency && Object.keys(byCurrency).length > 1;

  const isUseTabs = byCurrency && (byWeeks && byWeeks.length > 0 || byDays && byDays.length > 0);

  const onClickLineToggle = () => {
    setHideLines(!hideLines);
  }

  return <>
    {byCurrency === null &&
      <div className='card my-3' key='msv-loading'>
        <div>Loading...</div>
      </div>
    }
    {!hidden &&
      <div className='card my-3' key='msv-rows'>
        <MoneySummaryHeader hidden={isHeaderPerRow} onClick={onClickLineToggle} isExpanded={!hideLines} />
        {!isUseTabs && !hideLines && byCurrency &&
          <List
            itemLayout="horizontal"
            pagination={false}
            size='small'
            loading={!byCurrency}
            dataSource={byCurrency ? Object.keys(byCurrency) : []}
            renderItem={ccy => (
              <MoneySummaryRow
                key={`sum-row-${ccy}`} summary={byCurrency[ccy]} currency={ccy}
                hidden={hidden}
                header={isHeaderPerRow ? `Summary (${ccy})` : null}
              />
            )}
          />
        }
        {isUseTabs &&
          <Tabs defaultActiveKey="totals" type="line">

            {!hideLines && byCurrency &&
              <Tabs.TabPane tab="Totals" key="totals">
                <List
                  itemLayout="horizontal"
                  pagination={false}
                  size='small'
                  loading={!byCurrency}
                  dataSource={byCurrency ? Object.keys(byCurrency) : []}
                  renderItem={ccy => (
                    <MoneySummaryRow
                      key={`sum-row-${ccy}`} summary={byCurrency[ccy]} currency={ccy}
                      hidden={hidden}
                      header={isHeaderPerRow ? `Summary (${ccy})` : null}
                    />
                  )}
                />
              </Tabs.TabPane>
            }
            {!hideLines && byWeeks && byWeeks.length > 0 &&
              <Tabs.TabPane tab="Times" key="times">
                <List
                  itemLayout="horizontal"
                  pagination={false}
                  size='small'
                  loading={!byWeeks}
                  dataSource={byWeeks ? Object.keys(byWeeks) : []}
                  renderItem={ccy => (
                    <MoneySummaryRow
                      key={`sum-row-${ccy}`} summary={byWeeks[ccy]} currency={ccy}
                      hidden={hidden}
                      header={isHeaderPerRow ? `Summary (${ccy})` : null}
                    />
                  )}
                />
              </Tabs.TabPane>
            }
            {!hideLines && byDays && byDays.length > 0 &&
              <Tabs.TabPane tab="Times" key="times">
                <List
                  itemLayout="horizontal"
                  pagination={false}
                  size='small'
                  loading={!byDays}
                  dataSource={byDays ? Object.keys(byDays) : []}
                  renderItem={ccy => (
                    <MoneySummaryRow
                      key={`sum-row-${ccy}`} summary={byDays[ccy]} currency={ccy}
                      hidden={hidden}
                      header={isHeaderPerRow ? `Summary (${ccy})` : null}
                    />
                  )}
                />
              </Tabs.TabPane>
            }
          </Tabs>
        }
      </div>
    }
  </>;
};
