import React, { useState, useEffect, useContext } from 'react';
import '../../App.scss';
import 'antd/dist/antd.css';
import {
  QrcodeOutlined,
  SettingOutlined,
  UserAddOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Card,
  Typography,
  Divider,
  Skeleton,
  Row, Col,
  Image,
  Alert,
} from 'antd';
import SkeletonAvatar from 'antd/lib/skeleton/Avatar';
import SkeletonButton from 'antd/lib/skeleton/Button';
import SkeletonImage from 'antd/lib/skeleton/Image';

import { RestrictedTeamPermissions } from '../../utils/Constants';
import { UserContext } from '../../providers/UserProvider';

import { TeamMembersAvatars } from './TeamDashboardComponents';
import { TeamMembersGrid, TeamProfileTeamMembersAction , ActionButtonCol , TeamProfileBalancesCard, TeamLeaveTeamCard } from './TeamProfileComponents';
import { TeamProfileQRCodeView } from './TeamProfileQRCodeView';
import { TeamMemberInviteModal } from './TeamMemberInviteModal';
import { TeamProfileRecentActivityList } from './TeamProfileRecentActivityList';
import { useClientDetails } from './useClientDetails';


const { Paragraph, Text } = Typography;

function getRoleTitleForMember({ team, member }) {
  const isOwner = (team.teamOwnerClientId === member.clientId);
  const isManager = member.permissions && member.permissions.isTeamAdmin;
  const role = isOwner ? 'You are the owner'
    : (isManager ? 'You are a manager' : `You are a ${member.roles[0]}`);
  return role;
}

const TeamPofileTeamMembersActions = ({ teamId, permissions, isNewTeamSettings }) => {
  const isTeamAdmin = permissions ? permissions.isTeamAdmin : false;

  return <Row gutter={[{ xs: 4, sm: 6, md: 10, lg: 32 }, { xs: 2, sm: 3, md: 6, lg: 8 }]}>
    {/* {(isTeamAdmin || canAddMembers || canSeeMembers || canPayMembers) &&
      <ActionButtonCol>
        <TeamProfileTeamMembersAction
          route={`./${teamId}/members`}
          text={'Manage Members'}
          icon={<TeamOutlined />}
          style={{ width: '100%' }} />
      </ActionButtonCol>
    } */}
    {/* {(isTeamAdmin || canPayMembers) &&
      <ActionButtonCol>
        <TeamProfileTeamMembersAction
          route={`./${teamId}/payouts`}
          text={'Payouts'}
          icon={<EuroCircleOutlined />}
          style={{ width: '100%' }} />
      </ActionButtonCol>
    } */}
    {(isTeamAdmin) &&
      <ActionButtonCol>
        <TeamProfileTeamMembersAction
          route={`./${teamId}/tags`}
          text={'View All QRs'}
          icon={<QrcodeOutlined />}
          style={{ width: '100%' }} />
      </ActionButtonCol>
    }
    {/* {(isTeamAdmin || canSeeAllTxns) &&
      <ActionButtonCol>
        <TeamProfileTeamMembersAction
          route={`./${teamId}/member-tips`}
          text={'Member Tips'}
          icon={<UnorderedListOutlined />}
          style={{ width: '100%' }} />
      </ActionButtonCol>
    } */}
    {(isTeamAdmin) &&
      <ActionButtonCol>
        <TeamProfileTeamMembersAction
          route={isNewTeamSettings ? `./${teamId}/newsettings` : `./${teamId}/settings`}
          text={'Settings'}
          icon={<SettingOutlined />}
          style={{ width: '100%' }} />
      </ActionButtonCol>
    }
  </Row>;
};

const TeamInviteButton = ({ isAdmin, onClick, team, displayPermissions, style }) => {
  const text = 'Invite team members';
  const color = 'green';
  const ActionIcon = <
    UserAddOutlined color={color} 
    style={{ 
      fontSize: '200%', 
      width: '40px',
      borderRadius: '40px', 
      border: '1px solid green', 
      padding: '2px',
      margin: '0px 0px 0px 0px'
    }}
  />;
  const innerStyle = { 
    marginLeft: '20px', 
    marginTop: '4px', 
    height: '48px',
    color, 
    textAlign: 'left', 
    borderRadius: '4px',
    padding: '0px 10px 0px 10px',
    ...style,
  };

  return ( isAdmin || displayPermissions.canAddMembers ) && (
      <Button
        onClick={() => (onClick())}
        icon={ActionIcon}
        style={innerStyle}
      >
        <Space direction='vertical' align='center' style={{paddingLeft: '12px'}}>
          <Text strong ellipsis style={{ paddingBottom: '4px'}}> {text} </Text>
        </Space>
      </Button>
  );

}

const TeamMembersSummary = ({ isAdmin, team }) => {
  const teamNumberOfDeletedMembers = team && team.teamNumberOfDeletedMembers ? team.teamNumberOfDeletedMembers : 0;
  const teamTotalNumberOfMembers = teamNumberOfDeletedMembers > 0 ? team.teamNumberOfMembers + teamNumberOfDeletedMembers : team.teamNumberOfMembers;

  return (
    <Space direction='horizontal' align='center' style={{ width: '100%' }}>
      {`${teamTotalNumberOfMembers} ${teamTotalNumberOfMembers === 1 ? "member" : "members"} `}
      {isAdmin && `(${team.teamNumberOfDeletedMembers} deleted, `}
      {isAdmin && `${team.teamNumberOfMembers} active)`}
    </Space>
  );
}

const TeamProfileScreenBody = ({
  locStrings,
  teamId,
  team,
  clientId,
  teamMembers,
  isAdmin,
}) => {
  const context = useContext(UserContext);
  const user = context.user;
  const client = useClientDetails(clientId);

  const { isNewTeamSettings } = client || {};

  const [isQRVisible, setIsQRVisible] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [isInviteVisible, setIsInviteVisible] = useState(false);
  const [isTopLogoError, setIsTopLogoError] = useState(false);

  const override = false;
  const yourself = teamMembers && teamMembers.find(m => m.clientId === clientId);
  const role = yourself && team && getRoleTitleForMember({ member: yourself, team });
  const teamCardTitle = role;

  const restrictedPermissions = {
    ...RestrictedTeamPermissions,
  };
  const displayPermissions = (yourself && yourself.permissions) ? yourself.permissions : restrictedPermissions;
  const limitSettings = ['owner', 'manager'];
  const canAccessSettings = limitSettings.includes(yourself && yourself.roles && yourself.roles.length > 0 ? yourself.roles[0] : '');

  const imageBlockStyle = { maxWidth: '240px', maxHeight: '240px', width: '100%', borderRadius: '5%' };
  const holdingBlockStyle = { width: '240px', height: '240px', borderRadius: '5%' };


  const teamNumberOfDeletedMembers = team && team.teamNumberOfDeletedMembers ? team.teamNumberOfDeletedMembers : 0;
  const teamTotalNumberOfMembers = teamNumberOfDeletedMembers > 0 ? team.teamNumberOfMembers + teamNumberOfDeletedMembers : team.teamNumberOfMembers;

  return (team && teamMembers) ? (
    <>
      {isInviteVisible &&
        <TeamMemberInviteModal
          visible={true}
          teamId={teamId}
          teamName={team.teamName}
          clientId={clientId}
          clientName={yourself.displayName}
          teamMemberInviteUuid={team.inviteUuid ? team.inviteUuid : null}
          teamMemberInviteCreatedBy={team.inviteCreatedBy ? team.inviteCreatedBy : null}
          teamMemberInviteCreatedAt={team.inviteCreatedAt ? team.inviteCreatedAt : null}
          teamMemberInviteExpiresAt={team.inviteExpiresAt ? team.inviteExpiresAt : null}
          onClickInviteModalOk={() => setIsInviteVisible(false)}
          onClickModalCancel={() => setIsInviteVisible(false)}
        />
      }

      <Card
        loading={override}
        size='default'
      >

        {teamMembers && team &&
          <div style={{ width: '100%', textAlign: 'center', marginBottom: '24px' }} >
              {!isTopLogoError && team.topLogo ?
                <Image
                  src={team.topLogo}
                  placeholder={<SkeletonImage active style={holdingBlockStyle} />}
                  alt={team.teamName}
                  style={imageBlockStyle}
                  preview={false}
                  onError={() => setIsTopLogoError(true)}
                />
                :
                <SkeletonImage
                  style={holdingBlockStyle}
                />
              }
            <div style={{ marginTop: '-50px' }}>
              <TeamMembersAvatars
                locStrings={locStrings}
                membersList={teamMembers}
                divider={false}
                team={team}
                size='large' 
              />
            </div>

          <Space direction='vertical' align='center' style={{ width: '100%' }}>
            { team && displayPermissions &&
              <TeamInviteButton
                onClick={() => (setIsInviteVisible(true))}
                team={team}
                isAdmin={isAdmin}
                displayPermissions={displayPermissions}
                style={{ margin: '20px' }}
              />
            }
            { team && teamMembers && teamMembers.length > 1 &&
              <Space direction='horizontal'>
                <></>
                <div style={{ marginTop: '4px', width: '100%', textAlign: 'center' }}>
                  <TeamLeaveTeamCard
                    locStrings={locStrings}
                    clientId={clientId}
                    team={team}
                    permissions={displayPermissions}
                    isAdmin={isAdmin}
                  />
                </div>
                <></>
              </Space>
            }
          </Space>
        </div>
        }

        { team.teamDescription &&  
          <Paragraph 
            ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
            style={{ textAlign: 'center' }}
          >
            {team.teamDescription}
          </Paragraph>
        }

        <div style={{ textAlign: 'center', marginTop: '4px', marginBottom: '4px' }} >
          <TeamProfileQRCodeView
            tagId={team.defaultTagId}
            visible={isQRVisible}
            onChange={(v) => (setIsQRVisible(v))}
          />
        </div>

        {(displayPermissions && displayPermissions.canSeeTeamBalance || canAccessSettings) &&
          <div style={{ marginTop: '8px', width: '100%' }}>
            <TeamProfileBalancesCard
              locStrings={locStrings}
              condition={isBalanceVisible}
              teamClient={team}
              teamClientId={team.teamClientId}
              teamId={team.teamId}
              permissions={displayPermissions}
            />
          </div>
        }

        <Space direction='vertical' style={{ width: '100%' }}>
          <TeamPofileTeamMembersActions teamId={teamId} permissions={displayPermissions} isNewTeamSettings={isNewTeamSettings} />
        </Space>

        {(displayPermissions && displayPermissions.canSeeMembers || canAccessSettings) &&
          <div style={{ width: '100%' }}>
            <Divider
              orientation='left'
              style={{ width: '100%' }}
            >
              <TeamMembersSummary 
                team={team}
                isAdmin={isAdmin}
                displayPermissions={displayPermissions}
              />
            </Divider>

            <TeamMembersGrid
              condition={true}
              locStrings={locStrings}
              members={teamMembers}
              teamId={teamId}
              team={team}
              clientId={clientId}
              displayPermissions={displayPermissions}
              isInviteVisible={isInviteVisible}
              teamTapBehaviour={team.teamTapBehaviour}
              onChange={() => (true)}
              isAdmin={isAdmin}
            />

            <TeamInviteButton 
                onClick={() => (setIsInviteVisible(true))}
                team={team}
                isAdmin={isAdmin}
                displayPermissions={displayPermissions}
            />

          </div>
        }

        {clientId && team && teamId && displayPermissions &&
          <>
            <Divider orientation='left'>Recent Activity</Divider>
            <TeamProfileRecentActivityList
              teamId={teamId}
              clientId={clientId}
              displayPermissions={displayPermissions}
              locStrings={locStrings}
              team={team}
              isAdmin={isAdmin}
            />
          </>
        }

      </Card>

    </>
  ) : <LoadingCard loading={true} qr={isQRVisible} />;

};




const LoadingCard = ({ loading, qr }) => {
  return loading ? (
    <>
      <Card
        title={<Skeleton.Button size={'small'} active />}
      >
        <Skeleton active avatar />
        <Divider />
        <Space direction='vertical'>
          {qr &&
            <SkeletonImage active style={{ width: '200px', height: '200px' }} />
          }
          <SkeletonButton active style={{ width: '200px', height: '44px' }} />
          <Divider />
          <SkeletonButton shape='round' active size='large' />
          <SkeletonAvatar active />
          <SkeletonAvatar active />
          <SkeletonAvatar active />
          <SkeletonAvatar active />
          <SkeletonAvatar active />
        </Space>
      </Card>

    </>
  ) : null;

};



export default TeamProfileScreenBody;
