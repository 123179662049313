import React, { useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
} from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import {
  DefaultTeamPermissions, AdminTeamPermissions, FormItemLayout, TailFormItemLayout,
} from '../../utils/Constants';

import {
  tailFormItemLayout,
  TeamEditNameFormItem,
  TeamEditDescriptionFormItem,
} from './EditTeamForm';

export const EditTeamNameSettingsForm = ({ onUpdated, team, teamId, ...rest }) => {
  const [form] = Form.useForm();
  
  const onFinish = (values) => {
    const capturedValues = { ...values };
    if (onUpdated) {
      onUpdated(capturedValues);
    }
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamNameSettingsForm"
      onFinish={onFinish}
      initialValues={{ ...team }}
      scrollToFirstError
    >
      <Input.Group style={{ textAlign: 'left' }}>
        <TeamEditNameFormItem team={team}/>
        <TeamEditDescriptionFormItem team={team} />
      </Input.Group>
      <Form.Item
        {...tailFormItemLayout}
      >
      <Button
          type="primary"
          htmlType="submit"
          style={{ width: 'auto', maxWidth: '325px' }}
        >
        {"Save Changes"}
      </Button>
      </Form.Item>
    </Form>
  );
}

export const EditTeamNameSettingsUpdate = ({ teamId, team, onLoading }) => {
    const teamDetailsModel = new StrikeTeamDetailsModel({
        teamId,
    });
    const [loading, setLoading] = useState(false);

    const handleUpdate = async ({ teamName, teamDescription }) => {
        onLoading && onLoading({ message: `Saving name ...` });
        setLoading(true);
        await teamDetailsModel.updateTeamName({ teamName, teamDescription });
        setLoading(false);
        onLoading && onLoading({ message: null })
    }

    return (
        <div style={{ marginTop: '12px', marginLeft: '12px'  }}>
          <EditTeamNameSettingsForm
            team={team}
            onUpdated={handleUpdate}
            teamId={teamId}
          />
        </div>
    )
}
