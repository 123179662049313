import { useState, useEffect } from 'react';

import ClientAllTransactionsGroupedModel from './ClientAllTransactionsGroupedModel';

export function useClientTransactions(clientId, dateRange) {
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        const onUpdate = async (data) => {
            setTransactions(data);
        };

        const model = new ClientAllTransactionsGroupedModel({
            clientId,
            dateRange,
            callback: onUpdate,
        });

        if (dateRange) {
            model.start();
        } else {
            setTransactions([]);
        }

        return function cleanup() {
            model.stop();
            setTransactions(null);
        };

    },
        [clientId, dateRange]
    );

    return transactions;
}
