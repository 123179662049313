import React, { useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Checkbox,
} from 'antd';

import StrikeTeamDetailsModel from '../../models/StrikeTeamDetailsModel';
import {
  DefaultTeamPermissions, AdminTeamPermissions, FormItemLayout, TailFormItemLayout,
} from '../../utils/Constants';

import {
  tailFormItemLayout, checkboxesLayout, headerLayout,
  TeamEditMemberDefaultPermissionsFormItem,
  TeamEditTeamTransparencyFormItem,
} from './EditTeamForm';

export const EditTeamMemberForm = ({ callback, team, teamMember, edit, ...rest }) => {
  const [form] = Form.useForm();
  const [isAdminAccessEnabled, setAdminAccessEnabled] = useState(false);
  const [isTeamOwner, setTeamOwner] = useState(false);

  const onFinish = (values) => {
    if (callback) {
      callback(values);
    }
  };

  //set initial permission values for team creation scenario
  if (!teamMember.permissions) {
    teamMember.permissions = { ...DefaultTeamPermissions };
  }

  let isteamOwner = () => {
    return !!(team && teamMember && (team.teamOwnerClientId === teamMember.clientId));
  }

  let teamAdminChange = e => {
    if (e.target.checked) {
      setAdminAccessEnabled(true);
      let formValue = form.getFieldsValue();
      formValue.permissions = { ...AdminTeamPermissions }
      form.setFieldsValue({ ...formValue });
    } else {
      setAdminAccessEnabled(false);
    }
  };

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamMemberForm"
      onFinish={onFinish}
      initialValues={teamMember}
      scrollToFirstError
    >
      <Row> <Col {...headerLayout} style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}>Member Permissions</Col></Row>

      <Input.Group style={{ textAlign: 'left' }} >
        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'isTeamAdmin']}
          valuePropName="checked"
          onChange={teamAdminChange}
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isteamOwner()}>Team Manager</Checkbox>
        </Form.Item>
        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canAddMembers']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can invite members</Checkbox>
        </Form.Item>
        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canPayMembers']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can payout to members</Checkbox>
        </Form.Item>

        <Row> <Col {...headerLayout} style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}>Team Transparency</Col></Row>

        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canSeeMembers']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can see other members</Checkbox>
        </Form.Item>

        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canSeePayouts']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can see others payouts</Checkbox>
        </Form.Item>

        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canSeeAllTxns']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can see all transactions</Checkbox>
        </Form.Item>

        <Form.Item
          {...checkboxesLayout}
          name={['permissions', 'canSeeTeamBalance']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={isAdminAccessEnabled || isteamOwner()}>Can see team balance</Checkbox>
        </Form.Item>

      </Input.Group>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" disabled={isteamOwner()}>
          {(edit) ? "Save Changes" : "Create Your Team"}
        </Button>
      </Form.Item>
    </Form>
  );
};


export const EditTeamMemberSettingsForm = ({ onUpdated, team, teamId, ...rest }) => {
  const [form] = Form.useForm();
  
  const onFinish = (values) => {
    const capturedValues = { ...values };
    if (capturedValues.defaultPermissions) {
      capturedValues.defaultPermissions.canAddMembers = values.defaultPermissions.canAddMembers || false;
      capturedValues.defaultPermissions.canPayMembers = values.defaultPermissions.canPayMembers || false;
      capturedValues.defaultPermissions.isTeamAdmin = values.defaultPermissions.isTeamAdmin || false;
      capturedValues.defaultPermissions.canSeeMembers = values.defaultPermissions.canSeeMembers || false;
      capturedValues.defaultPermissions.canSeePayouts = values.defaultPermissions.canSeePayouts || false;
      capturedValues.defaultPermissions.canSeeAllTxns = values.defaultPermissions.canSeeAllTxns || false;
      capturedValues.defaultPermissions.canSeeTeamBalance = values.defaultPermissions.canSeeTeamBalance || false;
    }

    if (onUpdated) {
      onUpdated(capturedValues);
    }
  };

  //set initial permission values for team creation scenario
  if (!team || (JSON.stringify(team) === '{}')) {
    team.defaultPermissions = { ...DefaultTeamPermissions };
  }

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamMemberSettingsForm"
      onFinish={onFinish}
      initialValues={{...team }}
      scrollToFirstError
    >
      <Input.Group style={{ textAlign: 'left' }}>
        <TeamEditMemberDefaultPermissionsFormItem
          team={team}
        />
        <TeamEditTeamTransparencyFormItem
          team={team}
        />
      </Input.Group>
      <Form.Item
        {...tailFormItemLayout}
      >
      <Button
          type="primary"
          htmlType="submit"
          style={{ width: 'auto', maxWidth: '325px' }}
        >
        {"Save Changes"}
      </Button>
      </Form.Item>
    </Form>
  );
}

export const TeamDefaultMemberSettingsUpdate = ({ teamId, team, onLoading }) => {
    const teamDetailsModel = new StrikeTeamDetailsModel({
        teamId,
    });
    const [loading, setLoading] = useState(false);

    const handleUpdate = async (team) => {
        onLoading && onLoading({ message: `Saving member permissions...` });
        setLoading(true);
        await teamDetailsModel.updateDefaultPermissions(team);
        setLoading(false);
        onLoading && onLoading({ message: null })
    }

    return (
        <>
          <EditTeamMemberSettingsForm
            team={team}
            onUpdated={handleUpdate}
            teamId={teamId}
          />
        </>
    )
}
