import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Select,
  Row,
  Col,
  Button,
  Radio,
  Checkbox,
} from 'antd';
import { TwitterPicker } from 'react-color';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { DefaultTeamPermissions, AdminTeamPermissions, FormItemLayout, TailFormItemLayout } from '../../utils/Constants';
import TrackerService from '../../utils/TrackerService';
import { supportedCurrencies } from '../../utils/CurrencyUtils';

const { Option } = Select;

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 4
    }
  }
};

export const checkboxesLayout = {
  wrapperCol: {
    xs: {
      offset: 1
    },
    sm: {
      offset: 5
    },
    md: {
      offset: 6
    },
    lg: {
      offset: 7
    },
    xl: {
      offset: 8
    },
    xxl: {
      offset: 9
    }
  }
};

export const headerLayout = {
  xs: {
    offset: 0
  },
  sm: {
    offset: 4
  },
  md: {
    offset: 5
  },
  lg: {
    offset: 8
  },
  xl: {
    offset: 9
  },
  xxl: {
    offset: 10
  }
}

const fmtRgb = ({ r, g, b }) => `rgb(${r}, ${g}, ${b})`;

const noTippingBehavioursForCurrencies = ['USD'];
const basicTippingBehaviour = 'members';
function shouldShowTippingBehaviour(currency) {
  return !noTippingBehavioursForCurrencies.includes(currency);
}


export const TeamEditNameFormItem = ({ team, edit, isLoggedInMemberAdmin }) => {
  return (
    <Form.Item
      name="teamName"
      label={
        <span>
          Team Name&nbsp;
          <Tooltip title="Your team's name">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      style={{ width: '100%', textAlign: 'left' }}
      rules={[
        {
          required: true,
          message: 'Please input a team name!',
          whitespace: true
        },
      ]}
    >
      <Input maxLength={40} style={{ maxWidth: '325px', width: '100%' }} disabled={!isLoggedInMemberAdmin && edit} />
    </Form.Item>
  );
}

export const TeamEditDescriptionFormItem = ({ team, edit, isLoggedInMemberAdmin }) => {
  return (
    <Form.Item
      name="teamDescription"
      label={
        <span>
          Team Description&nbsp;
          <Tooltip title="Describe your team here">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
    >
      <Input.TextArea
        maxLength={'500'}
        style={{ maxWidth: '325px', width: '100%', textAlign: 'left', float: 'left' }}
        disabled={!isLoggedInMemberAdmin && edit}
      />
    </Form.Item>
  );
}

export const TeamEditColorFormItem = ({ team, edit, isLoggedInMemberAdmin, rgbColor, onColorChange }) => {
  const handleColorChange = ({ rgb }) => {
    onColorChange && onColorChange({ rgb });
  }

  return (
    <Form.Item
      name="rgbColor"
      label={
        <span>
          Team Color&nbsp;
          <Tooltip title="Choose a color for your team to customise your team's page">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      getValueFromEvent={({ rgb }) => fmtRgb(rgb)}
    >
      <TwitterPicker
        triangle="hide"
        colors={['#FF0098', '#27AE60', ...TwitterPicker.defaultProps.colors, '#FFFFFF', '#000000',]}
        color={rgbColor}
        onChangeComplete={handleColorChange}
      />
    </Form.Item>
  );
}

export const TeamEditCurrencyFormItem = ({ team, edit, isLoggedInMemberAdmin, onCurrencyChange }) => {
  const teamCCYDefault = team.defaultCurrency || null;

  let currencies = {
    ...supportedCurrencies,
  };
  if (teamCCYDefault) {
    currencies = { [teamCCYDefault]: supportedCurrencies[teamCCYDefault] };
  }

  const handleCurrencyChange = (value) => {
    onCurrencyChange && onCurrencyChange(value);
  }

  return (
    <Form.Item

      name="defaultCurrency"
      label={
        <span>
          Team's currency&nbsp;
          <Tooltip title="Default currency to be used for team payments. Can be changed for individual team tags during tag claim process">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      }
      rules={[
        {
          required: true,
          message: 'Please select a currrency!'
        },
      ]}
    >
      <Select
        allowClear={false}
        placeholder="Select team's default currency"
        style={{ maxWidth: '325px', float: 'left', textAlign: 'left' }}
        disabled={!isLoggedInMemberAdmin && edit}
        onChange={handleCurrencyChange}
      >
        {Object.keys(currencies).map(
          (i) => (
            <Option key={i} value={i}>{currencies[i]}</Option>
          ))
        }
      </Select>
    </Form.Item>
  );
}

export const TeamEditMemberDefaultPermissionsFormItem = ({ team, disabled }) => {
  const { teamTapBehaviour } = team || {};    // 'members', 'team', 'both'
  const isEditTeamAdmin = true;
  const isEditTeamAddMembers = true;
  const isEditTeamPayMembers = teamTapBehaviour !== 'members';

  return (
    <>
      <Row>
        <Col
          {...headerLayout}
          style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}
        >
          Default Member Permissions
        </Col>
      </Row>

      {isEditTeamAdmin &&
        <Form.Item
          {...checkboxesLayout}
          name={['defaultPermissions', 'isTeamAdmin']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
        >
          <Checkbox disabled={disabled} >Team Manager</Checkbox>
        </Form.Item>
      }
      {isEditTeamAddMembers &&
        <Form.Item
          {...checkboxesLayout}
          name={['defaultPermissions', 'canAddMembers']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
          disabled={!isEditTeamAddMembers}
        >
          <Checkbox disabled={disabled} >Can invite members</Checkbox>
        </Form.Item>
      }
      {isEditTeamPayMembers &&
        <Form.Item
          {...checkboxesLayout}
          name={['defaultPermissions', 'canPayMembers']}
          valuePropName="checked"
          style={{ marginBottom: '8px' }}
          disabled={!isEditTeamPayMembers}
        >
          <Checkbox disabled={disabled} >Can payout to members</Checkbox>
        </Form.Item>
      }
    </>
  );
}

export const TeamEditTeamTransparencyFormItem = ({ team, disabled }) => {
  const { teamTapBehaviour } = team || {};    // 'members', 'team', 'both'
  const isEditSeeOtherMembers = true;
  const isEditSeeAllTxns = true;
  const isEditSeeOtherPayouts = teamTapBehaviour !== 'members';
  const isEditSeeCurrentBalance = teamTapBehaviour !== 'members';

  return (
    <>
      <Input.Group style={{ textAlign: 'left' }} >
        <Row>
          <Col
            {...headerLayout} style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}
          >
            Team Transparency
          </Col>
        </Row>

        {isEditSeeOtherMembers &&
          <Form.Item
            {...checkboxesLayout}
            name={['defaultPermissions', 'canSeeMembers']}
            valuePropName="checked"
            style={{ marginBottom: '8px' }}
          >
            <Checkbox disabled={disabled} >Can see other members</Checkbox>
          </Form.Item>
        }
        {isEditSeeCurrentBalance &&
          <Form.Item
            {...checkboxesLayout}
            name={['defaultPermissions', 'canSeeTeamBalance']}
            valuePropName="checked"
            style={{ marginBottom: '8px' }}
          >
            <Checkbox disabled={disabled} >Can see team balance</Checkbox>
          </Form.Item>
        }
        {isEditSeeOtherPayouts &&
          <Form.Item
            {...checkboxesLayout}
            name={['defaultPermissions', 'canSeePayouts']}
            valuePropName="checked"
            style={{ marginBottom: '8px' }}
          >
            <Checkbox disabled={disabled} >Can see others payouts</Checkbox>
          </Form.Item>
        }
        {isEditSeeAllTxns &&
          <Form.Item
            {...checkboxesLayout}
            name={['defaultPermissions', 'canSeeAllTxns']}
            valuePropName="checked"
            style={{ marginBottom: '8px' }}
          >
            <Checkbox disabled={disabled} >Can see all transactions</Checkbox>
          </Form.Item>
        }
      </Input.Group>
    </>
  );
}

export const TeamEditTapBehaviourFormItem = ({ selectedCurrency, team, edit, isLoggedInMemberAdmin }) => {

  return (<>
    {shouldShowTippingBehaviour(selectedCurrency) &&
      <Col>
        <Row> <Col {...headerLayout} style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}>Tipping behaviour</Col></Row>
        <Form.Item
          {...checkboxesLayout}
          name={'teamTapBehaviour'}
          style={{ marginBottom: '8px' }}
        >
          <Radio.Group>
            <Radio value="members">Members only</Radio>
            {shouldShowTippingBehaviour(selectedCurrency) && <Radio value="team">Team only</Radio>}
            {shouldShowTippingBehaviour(selectedCurrency) && <Radio value="both">Members &amp; Team</Radio>}
          </Radio.Group>
        </Form.Item>
      </Col>
    }
  </>);
}

export const TeamEditReviewDetailsFormItem = ({ team, edit, isLoggedInMemberAdmin }) => {

  return(
    <>
      <>
        <Row>
          <Col {...headerLayout}
            style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '5px' }}>
            Add Review Link
          </Col>
        </Row>
        <Row
          style={{
            fontSize: '14px', fontWeight: 'normal', display: 'inline-block', marginBottom: '5px',
            textAlign: 'start', width: '100%', marginLeft: 'auto', marginRight: 'auto'
          }}>

          <Col {...headerLayout}>
            Add a link to a review site (like Google Reviews).
            This is displayed on the payment success screen.
          </Col>
        </Row>
      </>
      <Form.Item
        name={["reviewDetails", "success", "url"]}
        label={
          <span>
            Button link&nbsp;
            <Tooltip title="Enter a web page link">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ width: '100%', textAlign: 'left', marginBottom: '5px' }}
        rules={[
          {
            required: false,
            message: 'Enter your url',
            whitespace: true
          },
          {
            validator: (_, value) =>
              ((/^$|^(https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(\/[a-zA-Z0-9-._~:\/?#[@\]!$&'()*+,;=]*)?)?$|^$/.test(value || ''))) ?
                Promise.resolve() :
                Promise.reject('Please enter a valid url'),
          },
        ]}
      >
        <Input
          maxLength={140} style={{ maxWidth: '425px' }} disabled={!isLoggedInMemberAdmin && edit}
          placeholder={'https://www.example.com'}
        />
      </Form.Item>

      <Form.Item
        name={["reviewDetails", "success", "title"]}
        label={
          <span>
            Call to action&nbsp;
            <Tooltip title="Add some text with the button">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ width: '100%', textAlign: 'left', marginBottom: '5px' }}
        rules={[
          {
            required: false,
            message: 'Enter a short call to action message',
          },
          {
            validator: (_, value) =>
              (/^.{0,30}$/.test(value)) ?
                Promise.resolve() :
                Promise.reject('Please enter a shorter message'),
          },
        ]}
      >
        <Input
          maxLength={30} style={{ maxWidth: '425px' }} disabled={!isLoggedInMemberAdmin && edit}
          placeholder={'Want to leave a review? (default message)'}
        />
      </Form.Item>

      <Form.Item
        name={["reviewDetails", "success", "button"]}
        label={
          <span>
            Button label&nbsp;
            <Tooltip title="Add a button label">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        style={{ width: '100%', textAlign: 'left' }}
        rules={[
          {
            required: false,
            message: 'Enter a short and simple button label',
            whitespace: false
          },
          {
            validator: (_, value) =>
              (/(^(?=.{1,12}$)(\b\w+\b)(\s\b\w+\b)?$)|^$/.test(value)) ?
                Promise.resolve() :
                Promise.reject('Please enter a simple button label'),
          },
        ]}
      >
        <Input
          maxLength={12} style={{ maxWidth: '425px' }} disabled={!isLoggedInMemberAdmin && edit}
          placeholder={'Review (default button label)'}
        />
      </Form.Item>
    </>

  );
}

export const EditTeamForm = ({ callback, team, teamId, edit, teamMemberLoggedIn, isLoggedInMemberAdmin, ...rest }) => {
  const [form] = Form.useForm();
  const [isAdminAccessEnabled, setAdminAccessEnabled] = useState(false);
  const [rgbColor, setRgbColor] = useState(team.rgbColor ?? fmtRgb({ r: 255, g: 0, b: 152, a: 1 }));
  const teamCCYDefault = team.defaultCurrency || null;
  const [selectedCurrency, setSelectedCurrency] = useState(teamCCYDefault);

  let currencies = {
    ...supportedCurrencies,
  };
  if (teamCCYDefault) {
    currencies = { [teamCCYDefault]: supportedCurrencies[teamCCYDefault] };
  }

  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;

  const tracker = new TrackerService({
    isTest,
  });

  const onFinish = (values) => {
    const capturedValues = { ...values };
    if (capturedValues.defaultPermissions) {
      capturedValues.defaultPermissions.canAddMembers = values.defaultPermissions.canAddMembers || false;
      capturedValues.defaultPermissions.canPayMembers = values.defaultPermissions.canPayMembers || false;
      capturedValues.defaultPermissions.isTeamAdmin = values.defaultPermissions.isTeamAdmin || false;
      capturedValues.defaultPermissions.canSeeMembers = values.defaultPermissions.canSeeMembers || false;
      capturedValues.defaultPermissions.canSeePayouts = values.defaultPermissions.canSeePayouts || false;
      capturedValues.defaultPermissions.canSeeAllTxns = values.defaultPermissions.canSeeAllTxns || false;
      capturedValues.defaultPermissions.canSeeTeamBalance = values.defaultPermissions.canSeeTeamBalance || false;
    }
    const successReviewDetails = capturedValues.reviewDetails ? capturedValues.reviewDetails.success : null;
    if (successReviewDetails) {
      capturedValues.reviewDetails.success = {
        url: successReviewDetails.url || null,
        title: successReviewDetails.title || null,
        button: successReviewDetails.button || null,
      };
    }

    // MARK : Force members only for USD.
    if (!shouldShowTippingBehaviour(capturedValues.defaultCurrency)) {
      capturedValues.teamTapBehaviour = basicTippingBehaviour;
    }

    if (callback) {
      callback(capturedValues);
    }

    const data = {
      teamId,
      teamName: values.teamName,
      teamDescription: values.teamDescription,
      teamDefaultCurrency: values.defaultCurrency,
      teamDefaultPermissions: values.defaultPermissions,
      teamClientId: team.clientId,
      teamOwnerClientId: team.teamOwnerClientId,
      teamOwnerDisplayName: team.teamOwnerDisplayName,
      teamTapBehaviour: values.teamTapBehaviour,
      teamClientId: team.teamClientId,
      rgbColor: values.rgbColor,
      successReviewUrl: successReviewDetails ? successReviewDetails.url : null,
    }

    tracker.mixpanelTrack('change_team_settings', data);

    if (!isTest) {
      tracker.googleTrack('change_team_settings', data);
    }
  };

  //set initial permission values for team creation scenario
  if (!team || (JSON.stringify(team) === '{}')) {
    team.defaultPermissions = { ...DefaultTeamPermissions };
  }

  const handleColorChange = ({ rgb }) => {
      setRgbColor(rgb);
  }

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
  }

  return (
    <Form
      {...FormItemLayout}
      form={form}
      name="teamForm"
      onFinish={onFinish}
      initialValues={{...team, rgbColor: rgbColor }}
      scrollToFirstError
    >

      <TeamEditNameFormItem team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin} />
      <TeamEditDescriptionFormItem team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin} />
      <TeamEditColorFormItem 
        team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
        rgbColor={rgbColor} onColorChange={handleColorChange} 
      />

      <Input.Group style={{ textAlign: 'left' }}>
        <TeamEditTapBehaviourFormItem
          team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
          selectedCurrency={selectedCurrency}
        />
      </Input.Group>

      <>
      <Row>
        <Col {...headerLayout}
          style={{ fontSize: '17px', fontWeight: 'bold', display: 'inline-block', marginBottom: '10px' }}>
            Set Team's Default Currency
        </Col>
      </Row>

      <TeamEditCurrencyFormItem 
        team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
        onCurrencyChange={handleCurrencyChange}
      />
      </>

      {(isLoggedInMemberAdmin || !edit) && <>
        <Input.Group style={{ textAlign: 'left' }}>
          <TeamEditMemberDefaultPermissionsFormItem
            team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
          />
          <TeamEditTeamTransparencyFormItem
            team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
          />
        </Input.Group>
      </>
      }

      {(isLoggedInMemberAdmin || !edit) && 
        <Input.Group style={{ textAlign: 'left' }}>
          <TeamEditReviewDetailsFormItem
            team={team} edit={edit} isLoggedInMemberAdmin={isLoggedInMemberAdmin}
          />
        </Input.Group>
      }
      <Form.Item
        {...tailFormItemLayout}
      >
        <Button
            type="primary"
            htmlType="submit"
            disabled={!isLoggedInMemberAdmin && edit}
            style={{ maxWidth: '325px' }}
          >
          {(edit) ? "Save Changes" : "Create Your Team"}
        </Button>
      </Form.Item>
    </Form>
  );
}
